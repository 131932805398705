import React, { useContext, useEffect } from 'react';
import { RouterContext, RouterDispatchContext } from '../context/routing';
import { LoadingPage } from '../components/shared';

const Router: React.FC = ({ children }) => {
  const { init, routingMap } = useContext(RouterContext);
  const setRouter: $TSFixMe = useContext(RouterDispatchContext);

  useEffect(() => {
    setRouter({
      routingMap,
      roles: ['authenticated'],
      init: true,
    });
  }, [routingMap, setRouter]);

  if (!init) return <LoadingPage />;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default Router;
