import { DeleteOutlined } from '@ant-design/icons';
import { T } from '@transifex/react';
import { Button, Col, Empty, message, Row, Spin } from 'src/antd';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { useGetPropertyContainers } from '../../../../../api/property/property-containers';
import OrderOverview from '../../../../shared/OrderOverview';
import { TContainer, useSmsServiceStore } from '../store';
import { notificationModeLabelMap } from './consts';
import { getCookie } from '../../../../../utils/cookies';
import {
  smsCodeCookie,
  emailCodeCookie,
} from '../../../../../consts/cookie-consts';
// eslint-disable-next-line import/no-cycle
import { routingMap } from '../../../../../routing/routing-map';

const SummaryIn = () => {
  const values = useSmsServiceStore(
    (store) => store.data.newSelectedContainers
  );
  const { propertyId: id } = useSmsServiceStore((store) => store.context);
  const mode = useSmsServiceStore((store) => store.mode);

  const { data, isLoading } = useGetPropertyContainers(id, {
    includePrices: false,
    includePreferredDates: false,
  });

  const items = useMemo(() => {
    if (!data?.data?.length) return [];

    const selectedData: Array<TContainer & { containerData: $TSFixMe }> =
      values.map((d) => {
        const containerData = data?.data?.find(
          (e: $TSFixMe) => e.container.id === d.id
        )?.container;
        return { ...d, containerData };
      });

    return (
      selectedData.map(({ containerData, daysBefore }) => ({
        icon: <DeleteOutlined />,
        header: <T _str="Container {id}" id={containerData.container_id} />,
        headerSuffix: containerData?.waste_fraction?.name,
        description: (
          <T
            _str="{label} reminder {interval} before pickup"
            label={notificationModeLabelMap[mode]}
            interval={
              <>
                {daysBefore}{' '}
                {daysBefore > 1 ? <T _str="days" /> : <T _str="day" />}
              </>
            }
          />
        ),
      })) || []
    );
  }, [data, values, mode]);

  if (isLoading)
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Spin />
      </div>
    );

  if (!items.length)
    return <Empty description={<T _str="No containers selected" />} />;

  return (
    <OrderOverview
      header=""
      items={items}
      className={OrderOverview.classes.fillHeight}
    />
  );
};

export const Summary = () => {
  const history = useHistory();
  const mode = useSmsServiceStore((store) => store.mode);

  const isNarrow = useMediaQuery('(max-width: 700px)');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = async () => {
    if (
      (mode === 'sms' && !getCookie(smsCodeCookie)) ||
      (mode === 'email' && !getCookie(emailCodeCookie))
    ) {
      message.error(
        <T _str="Your session has expired, please verify again." />
      );

      useSmsServiceStore.getState().reset();

      return null;
    }

    setIsSubmitting(true);
    await useSmsServiceStore.getState().handleConfirm();
    setIsSubmitting(false);

    return null;
  };

  const onCancel = () => {
    if (getCookie(smsCodeCookie)) {
      history.push(routingMap.app.selfService._);
    } else {
      useSmsServiceStore.getState().reset();
    }
  };

  return (
    <div
      className="wh-vertical-scroller-container"
      style={{
        rowGap: '16px',
        height: `calc(100vh - ${isNarrow ? 283 : 312}px)`,
      }}
    >
      <div
        className="wh-vertical-scroller-container-scroll"
        style={{ overflow: 'auto' }}
      >
        <SummaryIn />
      </div>
      <Row gutter={[8, 8]} justify="end">
        <Col xs={isNarrow ? 24 : undefined}>
          <Button
            style={{ width: '100%' }}
            disabled={isSubmitting}
            onClick={onCancel}
          >
            <T _str="Cancel" />
          </Button>
        </Col>
        <Col xs={isNarrow ? 24 : undefined}>
          <Button
            type="primary"
            style={{ width: '100%' }}
            onClick={handleConfirm}
          >
            <T _str="Confirm" />
          </Button>
        </Col>
      </Row>
    </div>
  );
};
