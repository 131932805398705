import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { createPrefixer } from '../../utils';

const p = createPrefixer('wh-Layout-responsive-layout3');

const classes = {
  wrapper: p`wrapper`,
  header: p`header`,
  breadcrumb: p`breadcrumb`,
  description: p`description`,
  mainContent: p`mainContent`,
  heightContainer: p`heightContainer`,
};

export type Layout3Props = {
  header: ReactNode;
  breadcrumb: ReactNode;
  description?: ReactNode;
  mainContent: ReactNode;
};

const Layout3Div = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  max-height: calc(100vh - 3rem);
  overflow: hidden;
  display: flex;

  .${classes.header} {
    flex: 0 1 auto;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .${classes.breadcrumb} {
    flex: 0 1 auto;
    margin-bottom: 2rem;
  }

  .${classes.description} {
    flex: 0 1 auto;
    margin-bottom: 2rem;
  }

  .${classes.mainContent} {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 6rem;
  }

  .${classes.heightContainer} {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  @media screen and (min-width: 992px) {
    padding: 3rem 4rem 0 4rem;
  }
`;

export const Layout3: React.FC<Layout3Props> = ({
  header,
  breadcrumb,
  description,
  mainContent,
}) => {
  return (
    <Layout3Div className={classes.wrapper}>
      <div className={classes.heightContainer}>
        <div className={classes.header}>{header}</div>
        {breadcrumb && <div className={classes.breadcrumb}>{breadcrumb}</div>}
        {description && (
          <div className={classes.description}>{description}</div>
        )}
        <div className={classes.mainContent}>{mainContent}</div>
      </div>
    </Layout3Div>
  );
};
