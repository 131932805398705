/* eslint-disable @typescript-eslint/naming-convention */
import { Button, Col, Form, Row } from 'src/antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { PropertyContext } from 'src/context/property-context';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { T } from '@transifex/react';
import { useGetPropertyContainers } from 'src/api/property/property-containers';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
import { CssScrollerDiv, StyledSkeletonInput, Typography } from 'src/ui';
// eslint-disable-next-line import/no-cycle
import { useSmsServiceStore, TContainer } from '../store';
import { getCookie } from '../../../../../utils/cookies';
import {
  emailCodeCookie,
  smsCodeCookie,
} from '../../../../../consts/cookie-consts';
// eslint-disable-next-line import/no-cycle
import { routingMap } from '../../../../../routing/routing-map';
import ContainerListItem from './ContainerListItem';

type TFormSchema = {
  containers: Record<string, { checked: boolean; daysBefore: number }>;
};

const FormSelectContianer = () => {
  const { id: propertyId } = useContext(PropertyContext);
  const [form] = Form.useForm<TFormSchema>();
  const history = useHistory();
  const { mode } = useSmsServiceStore.getState();
  const other = useSmsServiceStore.getState().data?.other;
  const [loading, setLoading] = useState(true);

  const { data: dataContainers, isLoading } = useGetPropertyContainers(
    propertyId,
    {
      includePrices: true,
      includePreferredDates: true,
    }
  );

  const isNarrow = useMediaQuery('(max-width: 700px)');

  useEffect(() => {
    const newValue: TFormSchema['containers'] = useSmsServiceStore
      .getState()
      .data?.newSelectedContainers?.reduce(
        (acc, { id, daysBefore }) => ({
          ...acc,
          [id]: { checked: true, daysBefore },
        }),
        {}
      ) as unknown as TFormSchema['containers'];
    form.setFieldValue('containers', newValue || {});
    setLoading(false);
    // only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancel = () => {
    if (
      (mode === 'sms' && getCookie(smsCodeCookie)) ||
      mode === 'email' ||
      getCookie(emailCodeCookie)
    ) {
      history.push(routingMap.app.notifications._);
    } else {
      useSmsServiceStore.getState().reset();
    }
  };

  useEffect(() => {
    if (mode === 'sms' && getCookie(smsCodeCookie)) {
      useSmsServiceStore
        .getState()
        .setVerificationCode(getCookie(smsCodeCookie), {
          ...other,
        });
    }
    if (mode === 'email' && getCookie(emailCodeCookie)) {
      useSmsServiceStore
        .getState()
        .setVerificationCode(getCookie(emailCodeCookie), {
          ...other,
        });
    }
  }, [mode, other]);

  const onSubscribeBulk = (checked: boolean) => {
    const containersData = dataContainers?.data || [];

    form.setFields(
      containersData.map(({ container }: $TSFixMe) => ({
        name: ['containers', container.id],
        value: {
          checked,
          daysBefore: 1,
        },
      }))
    );
  };

  if (isLoading || loading) {
    return (
      <Row gutter={[0, 8]}>
        {isLoading &&
          React.Children.toArray(
            Array.from({ length: 5 }).map(() => (
              <Col xs={24}>
                <StyledSkeletonInput
                  width="100%"
                  active
                  style={{ height: 80, borderRadius: 8 }}
                  size="large"
                />
              </Col>
            ))
          )}
      </Row>
    );
  }

  return (
    <Form
      scrollToFirstError
      form={form}
      onFinish={(values: TFormSchema) => {
        const newPayload = Object.entries(values.containers)
          .filter(([, v]) => v?.checked)
          .map(([id, v]): TContainer => ({ id, daysBefore: v.daysBefore }));
        useSmsServiceStore.getState().updateContainers(newPayload, true);
      }}
      style={{ height: '100%' }}
    >
      <div
        className="wh-vertical-scroller-container"
        style={{
          rowGap: '16px',
          height: `calc(100vh - ${isNarrow ? 283 : 312}px)`,
        }}
      >
        <Row align="middle" gutter={[16, 16]} justify="space-between">
          <Col>
            <Typography weight="semi-bold" variant="subHeader-16">
              <T _str="Current Property" />
            </Typography>
          </Col>
          <Col>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const containers = getFieldValue('containers');

                const allKeysPresent =
                  Object.keys(containers).length ===
                  dataContainers?.data?.length;

                const allSubscribed =
                  allKeysPresent &&
                  (Object.values(containers).reduce(
                    (prevValue, { checked }: $TSFixMe) => prevValue && checked,
                    true
                  ) as boolean);

                const evenOneSubscribed = !!Object.values(containers).find(
                  ({ checked }: $TSFixMe) => checked
                );

                return (
                  <Row gutter={24}>
                    <Col>
                      <Button
                        disabled={isLoading || allSubscribed}
                        style={{ fontSize: 16, padding: 0 }}
                        type="link"
                        icon={<CheckOutlined />}
                        onClick={() => onSubscribeBulk(true)}
                      >
                        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
                        <>
                          <T _str="Subscribe all" />
                        </>
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        disabled={isLoading || !evenOneSubscribed}
                        style={{ fontSize: 16, padding: 0 }}
                        type="link"
                        icon={<CloseOutlined />}
                        onClick={() => onSubscribeBulk(false)}
                      >
                        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
                        <>
                          <T _str="Unsubscribe all" />
                        </>
                      </Button>
                    </Col>
                  </Row>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <div className="wh-vertical-scroller-container-scroll">
          <CssScrollerDiv defaultYScroller offset={16}>
            <Row gutter={[0, 8]}>
              {dataContainers?.data?.map(({ container }: $TSFixMe) => (
                <Col xs={24}>
                  <Form.Item noStyle name={['containers', container.id]}>
                    <ContainerListItem container={container} />
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </CssScrollerDiv>
        </div>
        <Row gutter={[8, 8]} justify="end">
          <Col xs={isNarrow ? 24 : undefined}>
            <Button style={{ width: '100%' }} onClick={onCancel}>
              <T _str="Cancel" />
            </Button>
          </Col>
          <Col xs={isNarrow ? 24 : undefined}>
            <Button style={{ width: '100%' }} type="primary" htmlType="submit">
              <T _str="Next" />
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default FormSelectContianer;
