import React, { ReactNode } from 'react';

type TConditionalSoftRenderProps = {
  items: ReactNode[];
  current: number;
};

export const ConditionalSoftRender = ({
  current,
  items,
}: TConditionalSoftRenderProps) => {
  return (
    <>
      {items.map((node, i) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          style={{
            display: current === i ? 'block' : 'none',
            height: '100%',
          }}
        >
          {node}
        </div>
      ))}
    </>
  );
};
