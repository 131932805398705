/* eslint-disable react/prop-types */
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Col, Divider, Row } from 'src/antd';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
import { T } from '@transifex/react';
import { createPrefixer } from '../../utils';
import { formatPayment } from '../../utils/currency';
import { paymentIntervalsLabelMapPerInterval } from '../../consts/payment-intervals';

const p = createPrefixer('wh-order-overview-');

const classes = {
  wrapper: p`wrapper`,
  header: p`header`,
  titleAndTypeWrapper: p`title-and-type-wrapper`,
  descriptionAndPriceWrapper: p`description-and-price-wrapper`,
  subtotalTextAndTotalWrapper: p`subtotal-text-and-total-wrapper`,
  orderContent: p`order-content`,
  type: p`type`,
  icon: p`icon`,
  description: p`description`,
  wasteFraction: p`waste-fraction`,
  price: p`price`,
  previousPrice: p`previous-price`,
  subtotalText: p`subtotal-text`,
  total: p`total`,
  fillHeight: p`fillHeight`,
};

const OrderOverviewDiv = styled.div`
  &.${classes.fillHeight} {
    height: 100%;
    margin: 0px;
  }

  .${classes.header} {
    font-size: 0.875rem;
    font-weight: 600;
    color: #0f3f6b;
    margin-bottom: 1rem;
  }

  .${classes.orderContent} {
    border: #dee4eb 1.5px solid;
    padding: 1rem;
    border-radius: 8px;
  }

  .${classes.titleAndTypeWrapper} {
    display: flex;
    color: #0f3f6b;
    align-items: center;
    margin-bottom: 0.5rem;
    flex-wrap: wrap;
  }

  .${classes.icon} {
    font-size: 1rem;
    margin-right: 0.5rem;
  }

  .${classes.type} {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
  }

  .${classes.wasteFraction} {
    font-size: 1rem;
    @media screen and (max-width: 460px) {
      width: 100%;
      margin-top: 8px;
    }
  }

  .${classes.descriptionAndPriceWrapper} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .${classes.description} {
    color: #909da9;
    font-size: 1rem;
    font-weight: 400;
  }

  .${classes.price} {
    color: #0f3f6b;
    font-size: 1rem;
    font-weight: 400;
  }

  .${classes.subtotalTextAndTotalWrapper} {
    display: flex;
    justify-content: space-between;
  }

  .${classes.subtotalText} {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 400;
    color: #909da9;
  }

  .${classes.total} {
    font-size: 1rem;
    font-weight: 600;
    color: #0f3f6b;
  }

  .ant-divider {
    border-color: #dee4eb;
    margin: 0.75rem 0 0.75rem 0;
  }

  .priceContainer {
    display: flex;
    flex-direction: row;
  }

  .${classes.previousPrice} {
    margin-right: 1rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    /* identical to box height, or 140% */
    text-align: center;

    text-decoration-line: line-through;

    /* WH token/text/secondary text */

    color: #8c8c8c;
  }

  .wrapper-with-div:first-child {
    .ant-divider {
      display: none;
    }
  }

  .wrapper-with-div {
    width: 100%;
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 1rem;

    .wrapper-with-div {
      width: 100%;
    }

    .${classes.previousPrice} {
      font-size: 1.25rem;
    }

    .${classes.header} {
      font-size: 1.25rem;
      font-weight: 400;
      margin-bottom: 1.5rem;
    }

    .${classes.icon} {
      font-size: 1.125rem;
      margin-right: 0.5rem;
    }

    .${classes.type} {
      font-size: 1.25rem;
    }

    .${classes.wasteFraction} {
      font-size: 1.25rem;
    }

    .${classes.description} {
      font-size: 1.25rem;
    }

    .${classes.price} {
      font-size: 1.25rem;
    }

    .${classes.subtotalText} {
      font-size: 1rem;
    }

    .${classes.total} {
      font-size: 1.25rem;
    }
  }
`;

export type OrderOverviewProps = {
  header: string | ReactNode;
  items: {
    icon?: ReactNode;
    header: string | ReactNode;
    headerSuffix?: string | ReactNode;
    description?: string | ReactNode;
    price?: number;
    previousPrice?: number;
    recurringPrice?: boolean;
  }[];
  subtotalText?: string | ReactNode;
  total?: string | ReactNode;
  className?: string;
  priceShowZero?: boolean;
};

const OrderOverview: React.FC<OrderOverviewProps> & {
  classes: typeof classes;
} = ({ header, items, subtotalText, total, className = '', priceShowZero }) => {
  const isNarrow = useMediaQuery('(max-width: 460px)');

  return (
    <OrderOverviewDiv className={`${classes.wrapper} ${className}`}>
      {header && (
        <div className={classes.header}>
          {typeof header === 'string' ? (
            <T _str="{header}" header={header} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>{header}</>
          )}
        </div>
      )}
      <Row className={classes.orderContent}>
        {items?.map((item) => (
          <div className="wrapper-with-div">
            <Divider />
            <Col span={24} className={classes.titleAndTypeWrapper}>
              {item?.icon && <div className={classes.icon}>{item.icon}</div>}
              <div className={classes.type}>
                {typeof item.header === 'string' ? (
                  <T _str="{type}" type={item.header} />
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>{item.header}</>
                )}
                {item.headerSuffix && !isNarrow && <>&nbsp;-&nbsp;</>}
              </div>
              <div className={classes.wasteFraction}>{item.headerSuffix}</div>
            </Col>
            <Col span={24} className={classes.descriptionAndPriceWrapper}>
              {item.description && (
                <div className={classes.description}>
                  {typeof item.description === 'string' ? (
                    <T _str="{description}" description={item.description} />
                  ) : (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>{item.description}</>
                  )}
                </div>
              )}
              <div className="priceContainer">
                {!!item.previousPrice && (
                  <div className={classes.previousPrice}>
                    {!item.recurringPrice &&
                      formatPayment(item.previousPrice, 'dkk')}
                    {item.recurringPrice && (
                      <>
                        {formatPayment(item.previousPrice, 'dkk')}{' '}
                        {item.recurringPrice && (
                          <>/ {paymentIntervalsLabelMapPerInterval.year}</>
                        )}
                      </>
                    )}
                  </div>
                )}
                {(item.price || priceShowZero) && (
                  <div className={classes.price}>
                    {formatPayment(item.price || 0, 'dkk')}{' '}
                    {item.recurringPrice && (
                      <>/ {paymentIntervalsLabelMapPerInterval.year}</>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </div>
        ))}
        {subtotalText && (
          <div style={{ width: '100%' }}>
            <Divider />
            <Col span={24} className={classes.subtotalTextAndTotalWrapper}>
              <div className={classes.subtotalText}>
                {typeof subtotalText === 'string' ? (
                  <T _str="{subtotalText}:" subtotalText={subtotalText} />
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>{subtotalText}</>
                )}
              </div>
              <div className={classes.total}>
                {typeof total === 'string' ? (
                  <T _str="{total}" total={total} />
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>{total}</>
                )}
              </div>
            </Col>
          </div>
        )}
      </Row>
    </OrderOverviewDiv>
  );
};

OrderOverview.classes = classes;

export default OrderOverview;
