import React, { useCallback, useEffect, useState } from 'react';
import { Button, Popover, Checkbox, Row } from 'antd';
import { InsertRowRightOutlined } from '@ant-design/icons';
import useLocalStorageState from 'use-local-storage-state';
import { TX_DISPLAY_COLUMNS } from 'src/storybook_transifex';

import {
  StyledTree,
  UseTreeFilterAndOrderBaseArgs,
  UseTreeFilterAndOrderReturn,
  arraymove,
} from './shared';

export type { UseTreeFilterAndOrderReturn } from './shared';

export * from './Simple';

export type UseTreeFilterAndOrderArgs<T extends string> =
  UseTreeFilterAndOrderBaseArgs<T> & {
    key: string;
  };

/**
 * Hook: useTreeFilterAndOrder
 * Note:
 * 1. Init.order should include all the columns that you want to show on the table.
 * If any column is not in this list then it will not be rendered.
 */
export const useTreeFilterAndOrder = <T extends string>({
  init,
  key,
  columns,
  popover,
  hideColumns = [],
}: UseTreeFilterAndOrderArgs<T>): UseTreeFilterAndOrderReturn<T> => {
  /* Transifex */
  const [initialized, setInitialized] = useState(false);
  const [checkedCols, setCheckedCols] = useLocalStorageState(
    `useTreeFilterAndOrder.check.${key}`,
    { defaultValue: init.checked }
  );
  const [orderCols, setOrderCols] = useLocalStorageState(
    `useTreeFilterAndOrder.order.${key}`,
    { defaultValue: init.order }
  );

  useEffect(() => {
    // Filter out any keys which are present in the local storage but not in the new init prop passed.
    // This is to invalidate any removed keys.
    const invalidKeys = orderCols.filter(
      (savedKey) => !init.order.includes(savedKey)
    );
    if (invalidKeys.length) {
      const newOrderCols = orderCols.filter((savedKey) =>
        init.order.includes(savedKey)
      );
      const newCheckedCols = checkedCols.filter((savedKey) =>
        init.order.includes(savedKey)
      );
      setOrderCols(newOrderCols);
      setCheckedCols(newCheckedCols);
    }

    // Add any new key from init.order that is not already in orderCols
    const newKeys = init.order.filter(
      (orderKey) => !orderCols.includes(orderKey)
    );
    if (newKeys.length) {
      const newOrderCols = [...orderCols];
      const newCheckedCols = [...checkedCols];
      newKeys.forEach((newKey) => {
        const index = init.order.indexOf(newKey);
        newOrderCols.splice(index, 0, newKey);
        if (init.checked.includes(newKey)) {
          newCheckedCols.splice(index, 0, newKey);
        }
      });
      setOrderCols(newOrderCols);
      setCheckedCols(newCheckedCols);
    }

    setInitialized(true);
    // only run on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeTableColumnsOrder = useCallback(
    // eslint-disable-next-line consistent-return
    (colkey: string, position: number) => {
      let newDropPosition = position;
      if (position < 0) {
        newDropPosition = 0;
      }
      const cList = [...orderCols];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const currentPosition = cList.findIndex((s) => s === colkey)!;
      if (newDropPosition > currentPosition) {
        newDropPosition -= 1;
      }

      if (currentPosition === -1) {
        return null;
      }

      arraymove<string>(cList, currentPosition, newDropPosition);
      setOrderCols(cList.filter(Boolean));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderCols]
  );

  const handleAllColsSelected = (checked: boolean) => {
    if (checked) {
      setCheckedCols(init.order);
    } else {
      setCheckedCols([]);
    }
  };

  return {
    state: initialized
      ? orderCols.map((c) => c).filter((k) => checkedCols.includes(k))
      : [],
    jsx: {
      toggler: (
        <Popover
          {...(popover || {})}
          placement={popover?.placement || 'bottomRight'}
          title={
            initialized && (
              <Checkbox
                onChange={(e) => handleAllColsSelected(e.target.checked)}
                indeterminate={
                  !!checkedCols.length &&
                  checkedCols.length !== init.order.length
                }
                checked={checkedCols.length === init.order.length}
              >
                {TX_DISPLAY_COLUMNS}
              </Checkbox>
            )
          }
          content={
            <Row>
              {initialized && (
                <StyledTree
                  draggable
                  checkable
                  checkedKeys={checkedCols}
                  onCheck={(keys) => setCheckedCols(keys as [])}
                  treeData={orderCols
                    ?.filter((k) => !hideColumns.includes(k))
                    .map((k) => columns[k])}
                  onDrop={({
                    dropPosition,
                    dragNode: { key: dropNodeKey },
                  }) => {
                    handleChangeTableColumnsOrder(
                      dropNodeKey as string,
                      dropPosition
                    );
                  }}
                />
              )}
            </Row>
          }
        >
          <Button
            type="text"
            shape="circle"
            icon={<InsertRowRightOutlined />}
          />
        </Popover>
      ),
    },
  };
};
