import React from 'react';
import { Form, AutoComplete, Button, Space } from 'antd';
import { T } from '@transifex/react';
import { APIURLS, baseURL } from 'src/api/client';
import { overrideApiUrl } from 'src/api/state';
import { asyncModal } from 'src/ui/asyncModalAction';

type TFormSchema = {
  apiUrl: string;
};

type FormChangeApiUrlProps = {
  onClose?: () => void;
};

const apiUrlsOptions = [
  {
    label: <T _str="Production" />,
    value: APIURLS.production,
  },
  {
    label: <T _str="Staging" />,
    value: APIURLS.staging,
  },
  {
    label: <T _str="Development" />,
    value: APIURLS.development,
  },
  {
    label: <T _str="Preivew" />,
    value: APIURLS.preview,
  },
];

const FormChangeApiUrl = ({ onClose }: FormChangeApiUrlProps) => {
  const onSubmit = (values: TFormSchema) => {
    overrideApiUrl(values.apiUrl);
  };

  return (
    <Form
      initialValues={{
        apiUrl: baseURL,
      }}
      onFinish={onSubmit}
      layout="vertical"
    >
      <Form.Item name="apiUrl" label={<T _str="URL" />}>
        <AutoComplete options={apiUrlsOptions} placeholder={<T _str="URL" />} />
      </Form.Item>
      <Form.Item noStyle>
        <Space direction="vertical" style={{ width: '100%' }} align="end">
          <Space>
            <Button onClick={onClose}>
              <T _str="Cancel" />
            </Button>
            <Button type="primary" htmlType="submit">
              <T _str="Submit" />
            </Button>
          </Space>
        </Space>
      </Form.Item>
    </Form>
  );
};

export const openApiChangeModal = () => {
  asyncModal({
    title: <T _str="Change api url" />,
    // eslint-disable-next-line react/no-unstable-nested-components, react/jsx-no-useless-fragment
    children: ({ reject }) => (
      <FormChangeApiUrl onClose={() => reject(asyncModal.CANCEL)} />
    ),
    bodyStyle: {
      padding: 16,
    },
  });
};

export const handleApiChange = (e: React.MouseEvent) => {
  if (e.detail === 3 && !process.env.REACT_APP_PORTAL_PRODUCTION) {
    openApiChangeModal();
  }
};

export default FormChangeApiUrl;
