import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { createPrefixer } from '../../utils';

const p = createPrefixer('wh-Layout-responsive-layout2-');

const classes = {
  wrapper: p`wrapper`,
  header: p`header`,
  breadcrumb: p`breadcrumb`,
  description: p`description`,
  mainContent: p`mainContent`,
  heightContainer: p`heightContainer`,
  sideContent: p`sideContent`,
  row: p`row`,
  sideCol: p`sideCol`,
  mainCol: p`mainCol`,
};

export type Layout2Props = {
  header: ReactNode;
  breadcrumb: ReactNode;
  description?: ReactNode;
  mainContent: ReactNode;
  sideContent: ReactNode;
  mainContentStyles?: CSSProperties;
};

const Layout2Div = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100vw;
  height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .${classes.header} {
    flex: 0 1 auto;
    margin-bottom: 1rem;
  }

  .${classes.breadcrumb} {
    flex: 0 1 auto;
    margin-bottom: 1rem;
  }

  .${classes.description} {
    flex: 0 1 auto;
  }

  .box {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .content-box {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .${classes.mainContent} {
    height: 100%;
    width: 100%;
  }

  .${classes.sideContent} {
    flex: 0 1 auto;
    margin: 1rem 0 0 0;
    min-width: 100%;
    max-width: 100%;
  }

  .ant-form-item .ant-select {
    display: block;
  }

  .${classes.row} .ant-form-item {
    margin: 0 0 1rem 0 !important;
  }

  @media screen and (min-width: 992px) {
    padding: 3rem 4rem 4rem 4rem;
    width: calc(100vw - 17.5rem);
    max-height: 100%;
    min-height: inherit;
    display: flex;
    flex-direction: row;

    .box {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
    }

    .${classes.header} {
      margin-bottom: 3rem;
    }

    .${classes.breadcrumb} {
      margin-bottom: 2rem;
    }

    .${classes.description} {
    }

    .content-box {
      flex-direction: row;
    }

    .${classes.sideContent} {
      display: flex;
      padding: 0px;
      margin: 0 0 0 4rem;
      height: 100%;
      min-width: calc(6 / 24 * 100% - 4rem);
      max-width: calc(6 / 24 * 100% - 4rem);
    }

    .${classes.mainContent} {
      flex-grow: 1;
      width: 100%;
      overflow-y: auto;
    }

    .ant-btn {
      height: 2.5rem;
    }
  }
`;

export const Layout2: React.FC<Layout2Props> = ({
  header,
  breadcrumb,
  description,
  mainContent,
  sideContent,
  mainContentStyles,
}) => {
  return (
    <Layout2Div className={classes.wrapper}>
      <div className="box">
        <div className={classes.header}>{header}</div>
        <div className={classes.breadcrumb}>{breadcrumb}</div>
        <div className={classes.description}>{description}</div>
        <div className="content-box">
          <div className={classes.mainContent} style={mainContentStyles}>
            {mainContent}
          </div>
          <div className={classes.sideContent}>{sideContent}</div>
        </div>
      </div>
    </Layout2Div>
  );
};
