/* eslint-disable no-restricted-syntax */
import React, { ReactNode } from 'react';
import { T } from '@transifex/react';
import { Typography } from 'src/ui/Typography';
import { Col, Row, Space } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { WasteFractionTag } from 'src/ui';
import CollectionCell from './CollectionCell';
import {
  CALENDAR_ROW_GAP,
  COLUMNS,
  COLUMN_WIDTH,
  HEADER_DATE_FORMAT,
  LAST_COLUMN_INDEX,
} from './contants';

const NO_MONTH = 'NO_MONTH';

const StyledPage = styled.div`
  width: 210mm;
  height: 297mm;
  padding: 4mm;

  * {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
`;

type PageProps = {
  total: number;
  current: number;
  propertyAddress: string;
  data: $TSFixMe[];
  footer?: ReactNode;
  calendarListHeight: number;
};

const getUniqueWasteFractions = (data: $TSFixMe) => {
  const uniqueFractions: Record<string, $TSFixMe> = {};

  for (const month of data) {
    for (const date of month.dates) {
      for (const collection of date.collections) {
        const fraction = collection?.container?.waste_fraction;
        if (fraction) uniqueFractions[fraction.id] = fraction;
      }
    }
  }

  return Object.values(uniqueFractions);
};

const Page = ({
  total,
  current,
  propertyAddress,
  data,
  footer,
  calendarListHeight,
}: PageProps) => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const fillArr = new Array(COLUMNS - data?.length).fill({
    month: NO_MONTH,
    dates: [],
  });
  const dataArr = data.concat(fillArr);
  const uniqueWastefractions = getUniqueWasteFractions(dataArr);

  return (
    <StyledPage className="calendar-page">
      <Row gutter={[0, CALENDAR_ROW_GAP]}>
        <Col span={24}>
          <Typography variant="subHeader-20" weight="semi-bold">
            <T
              _str="{year} Collection Calendar"
              year={moment(data[0].dates[0].date).format('YYYY')}
            />
          </Typography>
        </Col>
        <Col span={24}>
          <Typography variant="subHeader-16">
            <T _str="Address: {address}" address={propertyAddress} />
          </Typography>
        </Col>
        <Col span={24}>
          <Row style={{ minHeight: 24 }} gutter={12}>
            {uniqueWastefractions.map((wasteFraction) => (
              <Col>
                <Space>
                  <WasteFractionTag
                    wasteFraction={wasteFraction}
                    iconOnly
                    style={{ margin: 0 }}
                  />
                  <Typography weight="semi-bold" variant="body-12">
                    {wasteFraction?.name}
                  </Typography>
                </Space>
              </Col>
            ))}
          </Row>
        </Col>
        <Col xs={24}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'row',
                border: '0.5px solid #111D2C',
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                overflow: 'hidden',
              }}
            >
              {dataArr.map(({ month }: $TSFixMe, i) => (
                <div
                  style={{
                    display: 'flex',
                    height: 28,
                    width: COLUMN_WIDTH,
                    backgroundColor: '#111D2C',
                    color: 'white',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {month === NO_MONTH || month === dataArr[i - 1]?.month
                    ? undefined
                    : moment().month(month).format(HEADER_DATE_FORMAT)}
                </div>
              ))}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'row',
                border: '1px solid rgba(38, 38, 38, 0.15)',
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
              }}
            >
              {dataArr.map((d: $TSFixMe, i: number) => (
                <div
                  style={{
                    height: calendarListHeight,
                    width:
                      i === LAST_COLUMN_INDEX ? COLUMN_WIDTH - 1 : COLUMN_WIDTH,
                    borderRight:
                      i === LAST_COLUMN_INDEX
                        ? 'unset'
                        : '1px solid rgba(38, 38, 38, 0.15)',
                  }}
                >
                  {d.dates.map(({ date, collections }: $TSFixMe) => (
                    <CollectionCell date={date} collections={collections} />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </Col>
        {footer && <Col xs={24}>{footer}</Col>}
        <Col xs={24}>
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Typography>
              <T _str="Printed at {date}" date={moment().format('lll')} />
            </Typography>
            <Typography>
              <T
                _str="Page {pageNumbering}"
                pageNumbering={`${current}/${total}`}
              />
            </Typography>
          </Space>
        </Col>
      </Row>
    </StyledPage>
  );
};

export default Page;
