import React, { ReactNode, FC } from 'react';
import { T } from '@transifex/react';
import {
  CalendarOutlined,
  DeleteOutlined,
  DatabaseOutlined,
  HomeOutlined,
} from '@ant-design/icons';

export type TCrmServiceServiceTypeChoices =
  | 'extra_emptying'
  | 'cleaning'
  | 'repair_container'
  | 'bulk_waste_pickup'
  | 'add_container'
  | 'change_container_pickup_setting'
  | 'change_container_type'
  | 'remove_container'
  | 'missing_collection'
  // these two won't be used in portal but just adding here
  | 'new_property'
  | 'contact_sync';

export const crmServiceServiceTypeChoicesLabelMap: Record<
  TCrmServiceServiceTypeChoices,
  ReactNode
> = {
  add_container: <T _str="Add Container" />,
  bulk_waste_pickup: <T _str="Bulk Waste Pickup" />,
  change_container_pickup_setting: <T _str="Change Container Pickup Setting" />,
  change_container_type: <T _str="Change Container Type" />,
  cleaning: <T _str="Cleaning" />,
  extra_emptying: <T _str="Extra Emptyting" />,
  remove_container: <T _str="Remove Container" />,
  repair_container: <T _str="Repair Container" />,
  contact_sync: <T _str="Contact sync" />,
  missing_collection: <T _str="Missing collection" />,
  new_property: <T _str="New property" />,
};

const crmServiceServiceTypeChoicesIconMap: Record<
  TCrmServiceServiceTypeChoices,
  [FC, Record<string, unknown>]
> = {
  add_container: [DeleteOutlined, {}],
  bulk_waste_pickup: [
    DatabaseOutlined,
    { style: { transform: 'rotate(90deg)' } },
  ],
  change_container_pickup_setting: [DeleteOutlined, {}],
  change_container_type: [DeleteOutlined, {}],
  cleaning: [DeleteOutlined, {}],
  extra_emptying: [CalendarOutlined, {}],
  remove_container: [DeleteOutlined, {}],
  repair_container: [DeleteOutlined, {}],
  missing_collection: [DeleteOutlined, {}],
  contact_sync: [DeleteOutlined, {}],
  new_property: [DeleteOutlined, {}],
};

export const getTicketTypeIcon = (
  ticketType: $TSFixMe
): [FC, Record<string, unknown>] => {
  const { service, is_container_level: isContainerLevel } = ticketType;

  if (service?.service_type)
    return crmServiceServiceTypeChoicesIconMap[
      service.service_type as TCrmServiceServiceTypeChoices
    ];

  if (isContainerLevel) return [DeleteOutlined, {}];

  return [HomeOutlined, {}];
};
