import { WarningFilled } from '@ant-design/icons';
import { T } from '@transifex/react';
import { Popover, Space } from 'antd';
import React from 'react';
import { APIURLS, baseURL } from 'src/api/client';
import { Typography } from 'src/ui';

const ApiUrl = () => {
  const isProductionApi = baseURL === APIURLS.production;

  return (
    <Space align="center">
      {!isProductionApi && (
        <Popover
          placement="bottomLeft"
          content={
            <Typography variant="subHeader-16">
              <T _str="You are using api url:" />{' '}
              <Typography code>{baseURL}</Typography>
            </Typography>
          }
        >
          <Typography type="warning" variant="subHeader-20">
            <WarningFilled />
          </Typography>
        </Popover>
      )}
    </Space>
  );
};

export default ApiUrl;
