type TRecurringIntervalType = 'YEARLY' | 'MONTHLY';

type TConvertPricesToYearly = {
  recurringInterval: TRecurringIntervalType;
  amount: number;
  quantity: number;
};

export const convertFromRecurringStringToNumeric: Record<
  TRecurringIntervalType,
  number
> = {
  MONTHLY: 1,
  YEARLY: 12,
};

export const convertPriceToYearly = ({
  recurringInterval,
  amount,
  quantity,
}: TConvertPricesToYearly) => {
  if (recurringInterval !== 'YEARLY') {
    return (
      (12 / convertFromRecurringStringToNumeric[recurringInterval]) *
      ((amount / 100) * quantity)
    );
  }

  return (amount / 100) * quantity;
};
