import React, { CSSProperties } from 'react';

import ReactPhoneInput, {
  PhoneInputProps as ReactPhoneInputProps,
} from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import styled from 'styled-components';

const StyledPhone = styled.div`
  &&& {
    .react-tel-input .form-control {
      width: 100%;
      height: 32px;
      border-radius: 2px;
    }
  }
`;

export type TInputPhoneProps = ReactPhoneInputProps & {
  className?: string;
  style?: CSSProperties;
  onChange?: (val: string) => void;
};

export const InputPhone = ({
  onChange,
  value,
  className,
  style,
  ...rest
}: TInputPhoneProps) => {
  return (
    <StyledPhone className={className} style={style}>
      <ReactPhoneInput
        {...rest}
        value={value}
        onChange={(phone) => {
          onChange?.(phone ? `+${phone}` : ``);
        }}
      />
    </StyledPhone>
  );
};
