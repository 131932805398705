import { createGlobalStyle } from 'styled-components';
import { CssScroller } from './CssScroller';
import { GetDatePickerBasePropsGlobalStyles } from './DatePicker/BasePicker';

export const StorybookLegacyGlobalStyles = createGlobalStyle`
  ${GetDatePickerBasePropsGlobalStyles}
  .wh-vertical-scroller-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    &> .wh-vertical-scroller-container-scroll {
      flex: 1;
      min-height: 0;
    }
  }

  .wh-spin-100 {
    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;
      }
    }
  }

  .wh-ant-fill-view-table {
    .ant-table-wrapper {
      height: 100%;

      .ant-spin-nested-loading {
        height: 100%;

        .ant-spin-container {
          height: 100%;
          display: flex;
          flex-flow: column nowrap;

          .ant-table {
            flex: auto;
            overflow: hidden;

            .ant-table-container {
              height: 100%;
              display: flex;
              flex-flow: column nowrap;

              .ant-table-header {
                flex: none;
              }

              .ant-table-body {
                flex: auto;
                ${CssScroller}
              }
            }
          }

          .ant-table-pagination {
            flex: none;
          }
        }
      }
    }

    /* Removing box shadows */
    .ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container::before {
      box-shadow: none !important;
    }

    .ant-table-cell-fix-right-first::after, 
    .ant-table-cell-fix-right-last::after {
    border-right: 1px solid #f0f0f0;
    }

    .ant-table-ping-right .ant-table-cell-fix-right-first::after, 
    .ant-table-ping-right .ant-table-cell-fix-right-last::after {
      box-shadow: none !important;
    }

    .ant-table-cell-fix-left-first::after, 
    .ant-table-cell-fix-left-last::after {
      border-left: 1px solid #f0f0f0;
    }

    .ant-table-ping-left .ant-table-cell-fix-left-first::after, 
    .ant-table-ping-left .ant-table-cell-fix-left-last::after {
      box-shadow: none !important;
    }

  }

  .wh-select-hide-selected-option {
    &&& {
      .ant-select-item-option-selected {
        display: none;
      }
    }
  }


  .wh-antd-form {
    &.disabled {
       .ant-picker-input > input[disabled] {
          color: #111D2C;
        }
        
        .ant-checkbox-disabled + span {
          color: black;
        }

        .projectIdCol {
          display: none;
        }
        
        .selectAllDaysCheckboxCol {
          display: none;
        }
        
        .ant-checkbox-group {
          margin-left: 0 !important;
          cursor: default !important;
        }
        
        .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
          cursor: default !important;
        }
        
        .ant-checkbox-disabled {
          cursor: default !important;
        } 
        
        .ant-checkbox-disabled + span {
          cursor: default !important;
        }
        
        .ant-radio-button-wrapper-disabled {
          cursor: default !important;
        }
        
        .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
          cursor: default !important;
        }
        
        .ant-picker-range {
          border-color: #fafafa !important;
          background: #fafafa !important;
          color: #111D2C;
          cursor: default !important;
        }
        
        .ant-picker-input > input[disabled] {
          cursor: default !important;
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
          background-color: inherit;
        }
        
        .ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
          cursor: default !important;
        }
        
        .ant-radio-button-wrapper-disabled, .ant-radio-button-wrapper-disabled::before {
          display: none;
        }
        
        .button-col-24 {
          display: none;
        }

        .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
          border-color: #1890ff !important;
        }

        .ant-checkbox-disabled .ant-checkbox-inner {
          border-color: #fff !important;
          background: #fff;
        }

        .ant-picker-disabled {
          border-color: #fafafa !important;
          background: #fafafa !important;
          color: #111D2C;
        }

        .ant-select-disabled.ant-select:not(.ant-select-customize-input)
          .ant-select-selector {
          background: #fafafa;
          border-color: #fafafa;
          color: #111D2C;
        }

        .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
          background: #fafafa;
          border: 1px solid #d9d9d9;
          color: #111D2C;
          border-radius: 2px;
        }

        .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
          background: #fafafa;
          /* Daybreak Blue / blue-6 */

          border: 1px solid #fafafa;
          box-sizing: border-box;
          border-radius: 2px;
          display: inherit;

          color: #111D2C;
        }
        
        .ant-input-group-addon {
          border: 0px;
          background: #f0f0f0;
        }
        
        .ant-select-selection-placeholder {
          font-size: 0
        }
        
        .ant-input-group.ant-input-group-compact > *:first-child, .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
          display: none
        }
        
        .ant-input-group.ant-input-group-compact > *:last-child, .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
          width: 100% !important;
        }
        .mapboxgl-ctrl-top-right .mapboxgl-ctrl-geocoder.mapboxgl-ctrl input {
          display: none;
        }
        
        .mapboxgl-ctrl-geocoder--icon-search {
          display: none
        }
        
        .mapboxgl-marker {
          pointer-events: none
        }
        
        .ant-select-single .ant-select-selector .ant-select-selection-item {
          user-select: text;
        }
        
        .ant-picker ant-picker-range ant-picker-disabled {
        
        }
        
        .ant-picker-input ant-picker-input-active {
          cursor: default !important;
        }
        
        .ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
          cursor: default !important;
        }
        
        .ant-radio-button-wrapper-disabled {
          cursor: default !important;
        }
        
        .ant-checkbox-disabled {
          cursor: default !important;
        }
        
        .ant-switch-loading, .ant-switch-disabled {
          cursor: default !important;
        }
        
        .ant-switch-loading *, .ant-switch-disabled * {
          cursor: default !important;  
        }
        
        .ant-picker-input > input[disabled] {
          cursor: default !important;    
        }
        
        
        /* SELECTS */ 
        .ant-select {
          border-color: #fafafa !important;
          background: #fafafa !important;
          color: #111D2C;
          cursor: default !important;
        }
        
        .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
          cursor: default;
        }
        
        .ant-input {
          cursor: default !important;
        }
        
        .ant-select .ant-select-arrow {
          color: transparent;
        }
        
        .ant-select-selector {
          border-color: #fafafa !important;
          background: #fafafa !important;
          color: #111D2C;
        }
        
        /* INPUTS */
        .ant-input {
          cursor: default !important;
          border-color: #fafafa !important;
          background: #fafafa !important;
          color: #111D2C;
        }
      
      .ant-input-number-input {
        cursor: default !important;
        border-color: #fafafa !important;
        background: #fafafa !important;
        color: #111D2C;
      }
      .ant-input-number-group {
        cursor: default !important;
        border-color: #fafafa !important;
        background: #fafafa !important;
        color: #111D2C;
      }
      .ant-input-number {
        cursor: default !important;
        border-color: #fafafa !important;
        background: #fafafa !important;
        color: #111D2C;
      }
      .ant-input-number-group-addon{
        cursor: default !important;
        border-color: #fafafa !important;
        background: #fafafa !important;
        color: #111D2C;
      }
      
        /* PHONE INPUT */
        .react-tel-input {
          input {
            cursor: default !important;
            border-color: #fafafa !important;
            background: #fafafa !important;
            color: #111D2C;
          }
          .flag-dropdown {
            cursor: default !important;
            border-color: #fafafa !important;
            background: #fafafa !important;
            color: #111D2C;
          }
        }
    }
    
    .wh-styled-simple-dropdown {
      .ant-dropdown-trigger > .anticon.anticon-down,
      .ant-dropdown-link > .anticon.anticon-down,
      .ant-dropdown-button > .anticon.anticon-down {
        font-size: 14px;
        line-height: 0;
        margin-left: 4px;
      }
      .ant-btn-link {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

`;
