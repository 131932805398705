import React from 'react';
import { Tag as AntdTag, TagProps as AntdTagProps } from 'antd';
import styled from 'styled-components';
import { TagColorType, TagVariantType } from './types';
import {
  generateBoldHoverStyles,
  generateBoldStyles,
  generateMinimalHoverStyles,
  generateMinimalStyles,
  generateSubtleHoverStyles,
  generateSubtleStyles,
} from './utils';

const TagVariantStyleMap = {
  bold: generateBoldStyles,
  subtle: generateSubtleStyles,
  minimal: generateMinimalStyles,
};

const TagVariantHoverStyleMap = {
  bold: generateBoldHoverStyles,
  subtle: generateSubtleHoverStyles,
  minimal: generateMinimalHoverStyles,
};

export interface TagProps extends Omit<AntdTagProps, 'color'> {
  variant?: TagVariantType;
  color?: TagColorType;
  withHover?: boolean;
}

const StyledTag = styled(AntdTag)<{
  $variant: TagVariantType;
  $color: TagColorType;
  $withHover?: boolean;
}>(
  ({ $variant, $color, $withHover }) => `
    padding: 2px 4px;
    border-radius: 4px;
    box-shadow: none;
    transition-duration: 0.2s;
    transition-property: background-color, color, border-color;
    font-size: 12px;
    line-height: 16px;
    ${TagVariantStyleMap[$variant]($color)}
    ${$withHover ? TagVariantHoverStyleMap[$variant]($color) : ''}
    .anticon + span,
    img + span {
      margin-left: 4px;
    }
  `
);

export function Tag({
  variant = 'subtle',
  color = 'gray',
  withHover = true,
  ...props
}: TagProps) {
  return (
    <StyledTag
      {...props}
      $variant={variant}
      $color={color}
      $withHover={withHover}
    />
  );
}
