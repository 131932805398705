import React, { FC } from 'react';
import * as icons from '@ant-design/icons';

import {
  WhIconAntdBatteryFilled,
  WhIconAntdCardboardFilled,
  WhIconAntdFoodAndDrinkFilled,
  WhIconAntdFoodFilled,
  WhIconAntdGlassFilled,
  WhIconAntdHardMetalFilled,
  WhIconAntdHardPlasticFilled,
  WhIconAntdPaperFilled,
  WhIconAntdPlasticFilled,
  WhIconAntdResidualFilled,
  WhIconAntdSoftPlasticFilled,
  WhIconAntdBookFilled,
  WhIconAntdBicycleFilled,
  WhIconAntdHazardousFilled,
  WhIconAntdFlatGlassFilled,
  WhIconAntdReuseFilled,
  WhIconAntdGardenWasteFilled,
  WhIconAntdSoilFilled,
  WhIconAntdCoffeeGroundFilled,
  WhIconAntdCompostFilled,
  WhIconAntdNaturalStoneFilled,
  WhIconAntdPaperForShreddingFilled,
  WhIconAntdRepairFilled,
  WhIconAntdShoesFilled,
  WhIconAntdClothingFilled,
  WhIconAntdStumpsAndRootsFilled,
  TWhAntdIconProps,
} from 'src/ui/wh-icons-antd';

const WasteHeroIconNames = [
  'Plastic',
  'Cardboard',
  'HardPlastic',
  'Metal',
  'Battery',
  'Food',
  'FoodAndDrink',
  'Residual',
  'Glass',
  'Paper',
  'SoftPlastic',
  'Book',
  'Bicycle',
  'Hazardous',
  'FlatGlass',
  'Reuse',
  'GardenWaste',
  'Soil',
  'CoffeeGround',
  'Compost',
  'NaturalStone',
  'PaperForShredding',
  'Repair',
  'Shoes',
  'Clothing',
  'StumpsAndRoots',
] as const;

type TWasteHeroIconChoices = (typeof WasteHeroIconNames)[number];

export const wasteHeroIconMap: Record<
  TWasteHeroIconChoices,
  FC<TWhAntdIconProps>
> = {
  Plastic: WhIconAntdPlasticFilled,
  Cardboard: WhIconAntdCardboardFilled,
  HardPlastic: WhIconAntdHardPlasticFilled,
  Metal: WhIconAntdHardMetalFilled,
  Battery: WhIconAntdBatteryFilled,
  Food: WhIconAntdFoodFilled,
  FoodAndDrink: WhIconAntdFoodAndDrinkFilled,
  Residual: WhIconAntdResidualFilled,
  Glass: WhIconAntdGlassFilled,
  Paper: WhIconAntdPaperFilled,
  SoftPlastic: WhIconAntdSoftPlasticFilled,
  Book: WhIconAntdBookFilled,
  Bicycle: WhIconAntdBicycleFilled,
  Hazardous: WhIconAntdHazardousFilled,
  FlatGlass: WhIconAntdFlatGlassFilled,
  Reuse: WhIconAntdReuseFilled,
  GardenWaste: WhIconAntdGardenWasteFilled,
  Soil: WhIconAntdSoilFilled,
  CoffeeGround: WhIconAntdCoffeeGroundFilled,
  Compost: WhIconAntdCompostFilled,
  NaturalStone: WhIconAntdNaturalStoneFilled,
  PaperForShredding: WhIconAntdPaperForShreddingFilled,
  Repair: WhIconAntdRepairFilled,
  Shoes: WhIconAntdShoesFilled,
  Clothing: WhIconAntdClothingFilled,
  StumpsAndRoots: WhIconAntdStumpsAndRootsFilled,
};

export default function Icon({
  identifier,
  ...rest
}: { identifier: string } & TWhAntdIconProps) {
  const I = wasteHeroIconMap[identifier as TWasteHeroIconChoices];

  if (I) return <I {...rest} />;

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    React.createElement(icons[identifier], rest) || <div />
  );
}
