// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useUser } from 'src/app/User.store';
import React, { createContext, FC, ReactNode } from 'react';

export const CitizenContext = createContext<$TSFixMe>();
export const CitizenDispatchContext = createContext(undefined);

export type CitizenContextProps = {
  children: ReactNode;
};

export const CitizenProvider: FC<CitizenContextProps> = ({ children }) => {
  const { data } = useUser();

  return (
    <CitizenContext.Provider value={data}>{children}</CitizenContext.Provider>
  );
};
