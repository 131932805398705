import React from 'react';
import styled from 'styled-components';
import { T } from '@transifex/react';

const ContainerProductDetailWrapper = styled.div``;

export type ContainerProductDetailProps = {
  container?: {
    wasteFraction: { name: string };
    containerType: { name: string };
    pickupSetting: { name: string };
  };
  containerProduct?: {
    wasteFraction: { name: string };
    containerType: { name: string };
    pickupSetting: { name: string };
  };
};

export const ContainerProductDetail = ({
  container,
  containerProduct,
}: ContainerProductDetailProps) => {
  return (
    <ContainerProductDetailWrapper>
      {container && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1rem',
          }}
        >
          <T _str="Current" />
          <div style={{ fontSize: '1rem', fontWeight: 400 }}>
            {container?.wasteFraction?.name}
          </div>
          <div style={{ fontSize: '1rem', fontWeight: 400 }}>
            {container?.containerType?.name}
          </div>
          <div style={{ fontSize: '1rem', fontWeight: 400 }}>
            {container?.pickupSetting?.name}
          </div>
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {container ? <T _str="Changes to" /> : <T _str="New container" />}
        <div style={{ fontSize: '1rem', fontWeight: 400 }}>
          {containerProduct?.wasteFraction?.name}
        </div>
        <div style={{ fontSize: '1rem', fontWeight: 400 }}>
          {containerProduct?.containerType?.name}
        </div>
        <div style={{ fontSize: '1rem', fontWeight: 400 }}>
          {containerProduct?.pickupSetting?.name}
        </div>
      </div>
    </ContainerProductDetailWrapper>
  );
};
