import { useCompany } from 'src/app/Company.store';
import React, { FC } from 'react';
import styled from 'styled-components';
import { createPrefixer } from 'src/utils';
import ApiUrl from 'src/components/layout/header/ApiUrl';
import { Space } from 'antd';

import bg from 'src/svgs/LayoutBg.svg';
import { LanguagePicker } from 'src/components/I18n';
import { LoginContainer } from './LoginContainer';
import { Faq, NavigationButton } from './HowToUseIt';

const p = createPrefixer('wh-login-page-');

const classes = {
  languagePicker: p`languagePicker`,
  wrapper: p`wrapper`,
  beforeClick: p`before-click`,
  text1: p`text1`,
  text2: p`text2`,
  faqContent: p`faq-content`,
  faqWrapper: p`faq-wrapper`,
  img: p`img`,
  whiteLabelPicture: p`white-label-picture`,
};

interface ImageProps {
  imagePath: string;
}

const ImageContainer = styled.div<ImageProps>`
  width: 100vw;
  max-width: 100%;

  height: 60%;
  clip-path: ellipse(115% 40% at 50% 0%);
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat !important;
  background-blend-mode: multiply, normal;

  @media screen and (min-width: 992px) {
    width: 100vw;
    max-width: 100%;

    height: 60%;
    clip-path: ellipse(58% 62% at 50% 8%);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  ${({ imagePath }) =>
    imagePath &&
    `
      background: 
      linear-gradient(0deg, rgba(15, 63, 107, 0.6), 
      rgba(15, 63, 107, 0.6)), 
      url(${imagePath}); 
  `};
`;

const PageContainer = styled.div`
  min-height: ${() => (window.usingAsEmbedded ? 100 : 170)}%;
  height: ${() => (window.usingAsEmbedded ? 100 : 170)}%;
  width: 100vw;
  max-width: 100%;

  .${classes.beforeClick} {
    width: 100%;
    position: absolute;
    bottom: 40%;

    @media screen and (max-width: 992px) {
      bottom: 10%;
    }

    @media screen and (max-width: 400px) {
      bottom: unset;
      top: 45rem;
    }
  }

  .${classes.text1} {
    font-size: 0.875rem;
    font-weight: 600;
  }

  .${classes.text2} {
    font-size: 0.875rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.link100};
  }

  .${classes.languagePicker} {
    z-index: 1000;
    width: 6rem;
    position: absolute;
    display: flex;
    top: 4rem;
    right: 3rem;

    @media screen and (max-width: 992px) {
      right: calc(50% - 3rem);
    }

    @media screen and (max-width: 400px) {
      top: 2rem;
    }
  }

  .${classes.faqWrapper} {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .${classes.faqContent} {
    width: 80%;
    justify-content: center;
  }

  .ant-space-item {
    width: 100%;
  }

  .ant-select-selection-search-input {
    height: 100% !important;
  }

  @media screen and (min-width: 992px) {
    .${classes.beforeClick} {
      bottom: 13%;
    }

    .${classes.faqContent} {
      width: 40%;
    }
  }
`;

export const LayoutLogin: FC = ({ children }) => {
  const { data: companyData } = useCompany();

  return (
    <PageContainer className={classes.wrapper}>
      <div className={classes.languagePicker}>
        <Space>
          <ApiUrl />
          <LanguagePicker style={{ width: '100%' }} />
        </Space>
      </div>
      <ImageContainer imagePath={companyData?.login_logo || bg} />
      <div style={{ width: '100%' }}>
        <LoginContainer logo={companyData?.logo}>{children}</LoginContainer>
      </div>
      {!window.usingAsEmbedded && (
        <div className={classes.beforeClick}>
          <NavigationButton />
        </div>
      )}
      <div className={classes.faqWrapper}>
        <div className={classes.faqContent}>
          <Faq />
        </div>
      </div>
    </PageContainer>
  );
};
