import React, { useContext } from 'react';
import { T } from '@transifex/react';
import { HomeOutlined } from '@ant-design/icons';
import { Button } from 'src/antd';
// eslint-disable-next-line import/no-cycle
import { OrderCompleteView } from '../../../../shared';
// eslint-disable-next-line import/no-cycle
import { RouterContext } from '../../../../../context/routing';
// eslint-disable-next-line import/no-cycle
import { useRouting } from '../../../../../routing/child-router-factory';

export const Success = () => {
  const { routingMap } = useContext(RouterContext);

  const history = useRouting.useHistory();
  return (
    <div>
      <OrderCompleteView
        orderCompleteSubtext={
          <T _str="You have successfully set up the SMS service " />
        }
        orderCompleteTitle={<T _str="Setting completed" />}
        secondaryButtons={[
          <Button
            icon={<HomeOutlined />}
            type="primary"
            onClick={() => history.push(routingMap.app.selfService._)}
          >
            <span>
              <T _str="Back to the home page" />
            </span>
          </Button>,
        ]}
      />
    </div>
  );
};
