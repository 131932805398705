import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import { Typography, WHTooltip } from 'src/ui';
import { TagProps, TooltipProps, Tag } from 'src/antd';

import Icon from './Icon';

export type TWasteFractionTagProps = {
  wasteFraction: {
    name: string;
    color?: string;
    style?: TagProps['variant'];
    icon?: string;
    icon_preset?: string;
  };
  iconOnly?: boolean;
  iconStyles?: CSSProperties;
} & Pick<TagProps, 'className' | 'style' | 'color' | 'closable' | 'onClose'> & {
    toolTipProps?: Pick<TooltipProps, 'placement'>;
  };

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .anticon + span,
  img + span {
    margin-left: 0;
  }
`;

const StyledLetter = styled(Typography)<{ $iconOnly?: boolean }>`
  width: ${({ $iconOnly }) => ($iconOnly ? '12px' : '36px')};
  height: ${({ $iconOnly }) => ($iconOnly ? '12px' : '36px')};
  color: inherit;
  font-size: ${({ $iconOnly }) => ($iconOnly ? '12px' : '36px')};
  line-height: 1;
  text-align: center;
`;

const StyledName = styled(Typography)`
  font-size: inherit;
  line-height: inherit;
  color: inherit;
`;

export function WasteFractionTag({
  wasteFraction,
  iconOnly,
  iconStyles,
  toolTipProps,
  ...props
}: TWasteFractionTagProps) {
  return (
    <WHTooltip title={wasteFraction.name} {...toolTipProps}>
      <Tag
        color={wasteFraction?.color}
        variant={wasteFraction?.style}
        {...props}
        style={
          iconOnly
            ? { padding: 3, ...props.style }
            : {
                width: 80,
                height: 80,
                padding: '8px 4px',
                ...props.style,
              }
        }
      >
        <Wrapper>
          {!!wasteFraction?.icon_preset && (
            <Icon
              identifier={wasteFraction.icon_preset}
              style={{ fontSize: iconOnly ? 12 : 36, ...iconStyles }}
            />
          )}
          {!!wasteFraction?.icon && !wasteFraction?.icon_preset && (
            <img
              src={wasteFraction.icon}
              width={iconOnly ? 12 : 36}
              height={iconOnly ? 12 : 36}
              alt={wasteFraction?.name}
            />
          )}
          {!wasteFraction?.icon_preset && !wasteFraction?.icon && (
            <StyledLetter
              variant="unset"
              weight="semi-bold"
              $iconOnly={iconOnly}
            >
              {wasteFraction.name.slice(0, 1)}
            </StyledLetter>
          )}
          {!iconOnly && (
            <StyledName
              variant="unset"
              weight="medium"
              ellipsis={{ tooltip: false }}
            >
              {wasteFraction?.name}
            </StyledName>
          )}
        </Wrapper>
      </Tag>
    </WHTooltip>
  );
}
