import React, { ReactNode } from 'react';
import { T } from '@transifex/react';

import {
  CarryOutFilled,
  CheckCircleFilled,
  DashOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { TagProps } from 'src/antd';
import { success } from 'src/theme/colors';

export type TCollectionStatus =
  | 'PENDING'
  | 'SCHEDULED'
  | 'COMPLETE'
  | 'COMPLETE_WITH_TICKET'
  | 'COMPLETE_NOT_EMPTIED'
  | 'MISSED';

export const collectionStatusLabelMap: Record<TCollectionStatus, ReactNode> = {
  PENDING: <T _str="Pending" />,
  SCHEDULED: <T _str="Scheduled" />,
  COMPLETE: <T _str="Emptied" />,
  COMPLETE_WITH_TICKET: <T _str="Emptied" />,
  COMPLETE_NOT_EMPTIED: <T _str="Not Emptied" />,
  MISSED: <T _str="Missed" />,
};

export const collectionStatusIconMap: Record<TCollectionStatus, ReactNode> = {
  PENDING: <DashOutlined style={{ color: '#8C8C8C', fontSize: 21 }} />,
  SCHEDULED: <CarryOutFilled style={{ color: '#8C8C8C', fontSize: 21 }} />,
  COMPLETE: <CheckCircleFilled style={{ color: success, fontSize: 21 }} />,
  COMPLETE_WITH_TICKET: (
    <CheckCircleFilled style={{ color: '#FFC53D', fontSize: 21 }} />
  ),
  COMPLETE_NOT_EMPTIED: (
    <ExclamationCircleFilled style={{ color: '#FF4D4F', fontSize: 21 }} />
  ),
  MISSED: undefined,
};

export const collectionStatusApiConstMap: Record<number, TCollectionStatus> = {
  1: 'PENDING',
  2: 'SCHEDULED',
  3: 'COMPLETE',
  4: 'COMPLETE_NOT_EMPTIED',
  5: 'MISSED',
  6: 'COMPLETE_WITH_TICKET',
};

export const collectionStatusColorMap: Record<
  TCollectionStatus,
  TagProps['color']
> = {
  PENDING: 'default',
  SCHEDULED: '#595959',
  COMPLETE: 'green',
  COMPLETE_WITH_TICKET: 'yellow',
  COMPLETE_NOT_EMPTIED: 'red',
  MISSED: 'red',
};
