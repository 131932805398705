import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'src/antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { T } from '@transifex/react';
import { Typography, WasteFractionTag } from 'src/ui';
import useViewTicketsModal from 'src/components/main/routes/collections/exact/components/useViewTicketsModal';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
import {
  collectionStatusColorMap,
  collectionStatusIconMap,
  collectionStatusLabelMap,
  TCollectionStatus,
} from 'src/api/consts/CollectionStatus';
import { apiHelperCollections } from 'src/api/helpers/collection';

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;

  width: 100%;
  height: auto;

  /* Gray / gray-3 */

  background: #f5f5f5;
  border-radius: 5px;

  margin-bottom: 1rem;

  .collection-date-wrapper {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #0f3f6b;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .icon {
    display: flex;
    margin-right: 1rem;
  }

  .collection-day {
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;

    color: #0f3f6b;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .collection-date-wrapper {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    /* secondary/navy blue/navy blue 500 */

    color: #0f3f6b;
  }

  .icon {
    color: #0f3f6b;
    font-size: 1.5rem;
  }

  @media screen and (min-width: 992px) {
    padding: 1.5rem;

    .text-wrapper {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    .mobile-content {
      display: none;
    }
  }
`;

type CollectionContainer = {
  waste_fraction: { id: string; name: string; icon_preset: string };
  container_type: { id: string; name: string };
  pickup_setting: { id: string; name: string };
  container_id: string;
  ticket_id: string;
  created_tickets: $TSFixMe;
};

type CollectionProps = {
  date: string;
  containers: $TSFixMe[];
};

type ExpandableContentProps = {
  onViewTickets?: (tickets: $TSFixMe, status?: TCollectionStatus) => void;
  containers: CollectionContainer[];
};

const ExpandableContentWrapper = styled.div`
  cursor: default;
  background: ${(props) => props.theme.colors.white100};
  padding: 1rem;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 1.5rem;

  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;

  .expandable-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 8px;

    @media screen and (max-width: 992px) {
      align-items: center;
    }
  }

  .container-information-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .button-text {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .text-view {
    display: none;
  }

  .text-ticket {
    text-transform: capitalize;
  }

  .wastefraction-name-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 16px;

    @media screen and (max-width: 992px) {
      align-items: center;
    }
  }

  .container-waste-fraction {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;

    margin-right: 0.5rem;

    color: #0f3f6b;
  }

  .container-information {
    font-style: normal;
    font-weight: 200;
    font-size: 1rem;
    line-height: 1.5rem;

    color: grey;
  }

  .divider {
    width: 100%;
    margin: 1rem 0;
    border-bottom: 1px solid #d9d9d9;
  }

  .divider:last-of-type {
    display: none;
  }

  @media screen and (min-width: 992px) {
    margin-top: 2rem;

    .ticket-information-content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .text-view {
      display: inherit;
    }

    .text-ticket {
      text-transform: lowercase;
    }
  }
`;

const ExpandableContent = ({
  containers,
  onViewTickets,
}: ExpandableContentProps) => {
  const isNarrow = useMediaQuery('(max-width: 580px)');

  return containers.length > 0 ? (
    <ExpandableContentWrapper key={123}>
      {containers?.map((node) => {
        const status = apiHelperCollections.getStatus(node);
        return (
          <>
            <div className="expandable-content">
              <div className="container-information-content-wrapper">
                <div className="wastefraction-name-row">
                  <WasteFractionTag
                    wasteFraction={node?.waste_fraction}
                    style={{ margin: 0 }}
                  />
                  <div>
                    <div className="container-waste-fraction">
                      {node?.waste_fraction?.name}
                    </div>
                    <div className="container-information">
                      <div>
                        <T
                          _str="Container type: {containerType}"
                          containerType={node?.container_type}
                        />
                      </div>
                      <div>
                        <T
                          _str="Pickup setting: {pickupSetting}"
                          pickupSetting={node?.pickup_setting}
                        />
                      </div>
                    </div>
                    {!!node?.created_tickets?.length && !isNarrow && (
                      <div>
                        <Button
                          style={{ marginTop: '1rem' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            onViewTickets?.(node?.created_tickets, status);
                          }}
                          type="default"
                        >
                          <T _str="View detail" />
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {status && (
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  {collectionStatusIconMap[status]}
                  {!isNarrow && (
                    <Typography
                      style={{
                        color: collectionStatusColorMap[status],
                        filter: 'brightness(60%)',
                      }}
                      variant="subHeader-16"
                    >
                      {collectionStatusLabelMap[status]}
                    </Typography>
                  )}
                </div>
              )}
            </div>
            {!!node?.created_tickets?.length && isNarrow && (
              <div style={{ marginTop: '1rem', width: '100%' }}>
                <Button
                  style={{ width: '100%' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onViewTickets?.(node?.created_tickets, status);
                  }}
                  type="default"
                >
                  <T _str="View detail" />
                </Button>
              </div>
            )}
            <div className="divider" />
          </>
        );
      })}
    </ExpandableContentWrapper>
  ) : null;
};

type ExpanderProps = {
  containerCount: number;
  expanded: boolean;
  statusCount: Record<TCollectionStatus, number>;
};

const ExpanderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: end;

  .icon {
    font-size: 1rem;
    color: #1a4d7b;
    margin-right: 0rem;
  }

  .container-count-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-left: 16px;
    margin-right: 16px;
    color: ${(props) => props.theme.colors.dark100};

    @media screen and (max-width: 992px) {
      gap: 8px;
    }
  }

  @media screen and (min-width: 992px) {
    .icon {
      font-size: 1.5rem;
    }
  }
`;

const Expander = ({ statusCount, expanded, containerCount }: ExpanderProps) => {
  const hasZeroStatusCount = !Object.values(statusCount).reduce(
    (prev, current) => prev + current,
    0
  );

  return (
    <ExpanderWrapper>
      <div className="container-count-wrapper">
        {Object.entries(statusCount).map(
          ([collectionStatus, count]) =>
            !!collectionStatus &&
            !!count && (
              <>
                {collectionStatusIconMap[collectionStatus as TCollectionStatus]}
                <Typography
                  style={{
                    userSelect: 'none',
                    color:
                      collectionStatusColorMap[
                        collectionStatus as TCollectionStatus
                      ],
                    filter: 'brightness(60%)',
                  }}
                  variant="subHeader-16"
                >
                  {count}
                </Typography>
              </>
            )
        )}
        {hasZeroStatusCount && (
          <Typography variant="subHeader-16">
            {containerCount > 1 ? (
              <T _str="{count} containers" count={containerCount} />
            ) : (
              <T _str="{count} containers" count={containerCount} />
            )}
          </Typography>
        )}
      </div>
      {expanded && (
        <div className="icon">
          <CaretUpOutlined />
        </div>
      )}
      {!expanded && (
        <div className="icon">
          <CaretDownOutlined />
        </div>
      )}
    </ExpanderWrapper>
  );
};

export const CollectionDisplay = ({ date, containers }: CollectionProps) => {
  const [expanded, setExpanded] = useState(true);

  const {
    jsx: { modal: jsxModal },
    utils: { openModal, setState: setModalState },
  } = useViewTicketsModal();

  const statusCount = containers.reduce(
    (prevObj, currentContainer) => {
      const status = apiHelperCollections.getStatus(currentContainer);
      if (status) {
        return {
          ...prevObj,
          [status]: prevObj[status] + 1,
        };
      }
      return prevObj;
    },
    {
      COMPLETE_WITH_TICKET: 0,
      COMPLETE: 0,
      COMPLETE_NOT_EMPTIED: 0,
    } as Record<Partial<TCollectionStatus>, number>
  );

  return (
    <>
      <Wrapper onClick={() => setExpanded(!expanded)}>
        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <div className="collection-date-wrapper">
              <div className="collection-date">{date}</div>
            </div>
          </Col>
          <Col span={12}>
            {containers.length > 0 && (
              <Expander
                containerCount={containers.length}
                expanded={expanded}
                statusCount={statusCount}
              />
            )}
          </Col>
        </Row>
        {expanded && (
          <ExpandableContent
            onViewTickets={(tickets, status) => {
              setModalState({ tickets, collectionStatus: status });
              openModal();
            }}
            containers={containers?.map((e) => ({
              ticket_id: e.ticket_id,
              container_id: e.container?.container_id,
              pickup_setting: e.container?.pickup_setting.name,
              waste_fraction: e.container?.waste_fraction,
              container_type: e.container?.container_type.name,
              created_tickets: e?.pickup_order?.created_tickets,
              completed_at: e?.pickup_order?.completed_at,
              ...e,
            }))}
          />
        )}
      </Wrapper>
      {jsxModal}
    </>
  );
};
