import React from 'react';
import { createAntdIcon } from '../createIcon';

export const WhIconAntdSoftPlasticFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.0653 3.75096C10.0653 3.97712 9.84538 4.1622 9.57657 4.1622H6.36475C6.09588 4.1622 5.87595 3.97712 5.87595 3.75096C5.87595 3.5248 6.09588 3.33977 6.36475 3.33977H9.57657C9.84538 3.33977 10.0653 3.5248 10.0653 3.75096ZM12.1782 9.83972C12.1394 9.52601 12.1006 9.20858 12.1498 8.89594C12.1893 8.64524 12.2848 8.40223 12.3094 8.15014C12.341 7.82755 12.2553 7.50584 12.167 7.19126C12.1034 6.96455 12.0381 6.72628 12.1082 6.501C12.1457 6.38062 12.2199 6.27162 12.2733 6.15589C12.3506 5.98859 12.8426 4.54235 12.8601 2.98444C12.8624 2.77978 12.9089 2.59563 12.8737 2.39323C11.9018 2.15828 8.83433 2.20309 7.65688 2.20309C3.20182 2.20309 3.08939 2.01303 3.08692 2L3.29 5.41723C3.40856 6.34968 3.59187 7.30902 4.18117 8.09626C4.23673 8.1705 4.29804 8.24957 4.29492 8.33798C4.2924 8.40734 4.25025 8.4702 4.2121 8.53122C3.85385 9.10437 3.76473 9.74572 3.68214 10.3935C3.62291 10.858 3.56368 11.3224 3.50446 11.7869C3.45585 12.1681 3.45716 12.4227 3.40856 12.8038C3.40456 12.9455 3.2438 13.0679 3.14199 13.1788C3.04023 13.2898 3.0087 13.5394 3 13.7426C3.21008 13.8099 4.56866 13.7821 5.71819 13.748C6.45085 13.7263 7.11022 13.6524 7.29238 13.6337C9.11207 13.4466 11.051 13.5411 12.8817 13.6109C12.7665 13.2951 12.6596 12.9339 12.6189 12.6047C12.5048 11.683 12.2922 10.7614 12.1782 9.83972Z" />
    </svg>
  );
});
