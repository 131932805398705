import React, { PropsWithChildren } from 'react';
import { Form, FormItemProps } from 'antd';

import { array } from 'src/utils/array';
import { VALIDATION_MESSAGE } from 'src/utils/validation';

import { TTicketCustomField } from '../types';

type BaseCustomFieldProps = PropsWithChildren<
  Pick<
    TTicketCustomField,
    'label' | 'display_help_text' | 'help_text' | 'required'
  >
> & {
  fieldName: FormItemProps['name'];
};

const BaseCustomField = ({
  fieldName,
  label,
  required,
  help_text,
  children,
  display_help_text,
}: BaseCustomFieldProps) => (
  <Form.Item
    label={label}
    name={fieldName}
    // 'extra' is used instead of helper cause
    // 'helper' overrides validation message
    extra={display_help_text && help_text}
    rules={array(
      required && { required: true, message: VALIDATION_MESSAGE.REQUIRED }
    )}
  >
    {children}
  </Form.Item>
);

export default BaseCustomField;
