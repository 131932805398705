/* eslint-disable react/prop-types */
import { LeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { T } from '@transifex/react';
import { darken } from 'polished';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
import { createPrefixer } from '../../../utils';

const p = createPrefixer('wh-UI-Breadcrumb-simple-');

const classes = {
  wrapper: p`wrapper`,
  backIcon: p`back-icon`,
  title: p`title`,
  informationIcon: p`help-icon`,
};

export type BreadcrumbProps = {
  title: string | ReactNode;
  onBack?: () => void;
  tooltip?: boolean;
};

type WrapperProps = {
  $onBack: boolean;
};

const BreadcrumbDiv = styled.div<WrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;

  .${classes.informationIcon} {
    font-size: 1.063rem;
    display: flex;
    align-items: center;
  }

  .${classes.backIcon} {
    display: none;
    font-size: 1.313rem;
    margin-right: 1rem;
    color: #2f76b8;
  }

  .${classes.title} {
    font-weight: 600;
    font-size: 1.5rem;
    color: ${(props) => darken(0.2, props.theme.colors.primaryColor)};
  }

  ${({ $onBack }) =>
    $onBack &&
    `
      cursor: default;
    `}

  @media screen and (min-width: 992px) {
    align-items: center;
    justify-content: start;

    .${classes.informationIcon} {
      font-size: 1.313rem;
    }

    .${classes.backIcon} {
      display: inherit;
      font-size: 1.313rem;
      margin-right: 1rem;
      color: ${(props) => props.theme.colors.dark100};
    }

    .${classes.title} {
      margin-right: 1rem;
    }

    ${({ $onBack, theme }) =>
      $onBack &&
      `
      cursor: pointer;
      .${classes.title} {
        font-size: 1.25rem !important;
        color: ${theme.colors.dark100} !important;
      }
    `}
  }
`;

export const BreadCrumb: FC<BreadcrumbProps> = ({ title, onBack, tooltip }) => {
  const isMobile = useMediaQuery('(max-width: 996px)');
  return (
    <BreadcrumbDiv
      className={classes.wrapper}
      $onBack={!!onBack}
      onClick={!isMobile ? onBack : () => null}
    >
      {onBack && (
        <div className={classes.backIcon}>
          <LeftOutlined />
        </div>
      )}
      <div className={classes.title}>
        {typeof title === 'string' ? (
          <T _str="{title}" title={title} />
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>{title}</>
        )}
      </div>
      {tooltip && (
        <div className={classes.informationIcon}>
          <QuestionCircleOutlined
            style={{ color: '#909DA9', fontSize: 'inherit' }}
          />
        </div>
      )}
    </BreadcrumbDiv>
  );
};
