import styled from 'styled-components';

export const Styles = styled.div`
  .ant-form-item {
    margin: 0;

    &.no-height {
      .ant-form-item-control-input {
        min-height: 0px;
      }
    }
  }
  overflow: hidden;
`;

export const StepsStyles = styled.div`
  display: none;
  @media screen and (min-width: 992px) {
    display: inherit;
  }
`;

export const PreferredDateStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .ant-form-item-label {
    display: block;
    flex: none !important;
  }

  .ant-form-item-control {
    flex: none !important;
    display: block;
  }

  .ant-picker {
    width: 100%;
  }
  @media screen and (min-width: 992px) {
    flex-direction: row;

    .ant-picker {
      width: 15rem;
    }
  }
`;
