import React from 'react';
import { createAntdIcon } from '../createIcon';

export const WhIconAntdHazardousFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.27169 10.4539L7.72919 10.4531L7.45653 6.10634H8.54345L8.27169 10.4539ZM7.99999 12.0751C7.69985 12.0751 7.45653 11.8318 7.45653 11.5317C7.45653 11.2316 7.69985 10.9882 7.99999 10.9882C8.30013 10.9882 8.54345 11.2316 8.54345 11.5317C8.54345 11.8318 8.30013 12.0751 7.99999 12.0751ZM14.3941 12.8593L8.32058 2.74629C8.25502 2.63797 8.136 2.5656 7.99999 2.5656C7.85604 2.5656 7.73116 2.6467 7.66854 2.76562L1.6042 12.8618C1.56218 12.9282 1.54056 13.0087 1.54812 13.0946C1.56532 13.2902 1.74079 13.4344 1.93725 13.4344H14.0623C14.2596 13.4344 14.4358 13.2891 14.452 13.0925C14.4592 13.0062 14.4368 12.9256 14.3941 12.8593Z" />
    </svg>
  );
});
