import React, {
  useCallback,
  useEffect,
  useMemo,
  useContext,
  ReactNode,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { T } from '@transifex/react';
import { Form, Steps, Col, message, Button, FormInstance } from 'src/antd';
import { RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';

import { apiClient } from 'src/api/client';

// eslint-disable-next-line import/no-cycle
import { SimpleTicket } from './components/SimpleContainerTicket';
// eslint-disable-next-line import/no-cycle
import { FormChangeContainerType } from './components/ChangeContainerType';
// eslint-disable-next-line import/no-cycle
import { FormChangePickupSetting } from './components/ChangePickupSetting';
// eslint-disable-next-line import/no-cycle
import { FormAddContainer } from './components/AddContainer';
// eslint-disable-next-line import/no-cycle
import { FormSimplePropertyTicket } from './components/SimplePropertyTicket';
// eslint-disable-next-line import/no-cycle
import { FormBulkWastePickup } from './components/BulkWastePickup';
// eslint-disable-next-line import/no-cycle
import { useRouting } from '../../../../../../routing/child-router-factory';

// eslint-disable-next-line import/no-cycle
import { ConditionalSoftRender } from './elements';
// eslint-disable-next-line import/no-cycle
import { FormContactInfo } from './components/ContactInfo';

import { TFormSchema } from './schema';
import { StepsStyles, Styles } from './styles';

import { mutate as apiMutate, PatchPropertyUser } from './api';
import {
  CitizenContext,
  CitizenDispatchContext,
} from '../../../../../../context/citizen-context';
import { PropertyContext } from '../../../../../../context/property-context';
import { Layout2 } from '../../../../../layout';
import { HeaderWithBack } from '../../../../../layout/header';
import { BreadCrumb } from '../../../../../layout/breadcrumb';
// eslint-disable-next-line import/no-cycle
import { OrderComplete } from './components/OrderComplete';
import { StepProgression } from '../../../../../shared';
import { array } from '../utils';
import { TExtendTicketTypeFormHandlers, TFormHandler } from './types';
import { FormItemTicketCustomFormDropdown } from './components/Dropdown';
// eslint-disable-next-line import/no-cycle
import { useGetToken } from '../../../../../../api/config';
import { prefillWithValues, prepareCustomValues } from './utils';
import { TTicketCustomFieldConnection } from './components/CustomFieldsFormSection/types';

type FieldError = {
  name: string[];
};

const serviceStepsMap: Partial<
  Record<TExtendTicketTypeFormHandlers, TFormHandler>
> = {
  extra_emptying: SimpleTicket,
  change_container_type: FormChangeContainerType,
  change_container_pickup_setting: FormChangePickupSetting,
  remove_container: SimpleTicket,
  cleaning: SimpleTicket,
  repair_container: SimpleTicket, // we will use managedTicket mutation though.
  add_container: FormAddContainer,
  bulk_waste_pickup: FormBulkWastePickup,
  simple_ticket_container_level: SimpleTicket,
  simple_ticket_property_level: FormSimplePropertyTicket,
  missing_collection: SimpleTicket,
  contact_sync: SimpleTicket,
  new_property: SimpleTicket,
};

const supportedFormHandlers = Object.keys(
  serviceStepsMap
) as unknown as TExtendTicketTypeFormHandlers[];

const StepsStyling = styled.div`
  .ant-steps-item-title {
    margin-bottom: 2rem;
  }
`;
const StepsRender = ({
  getFieldValue,
  setFieldsValue,
  errorField,
  toStep,
}: Pick<FormInstance, 'getFieldValue' | 'setFieldsValue'> & {
  errorField: FieldError;
  toStep: (s: number) => void;
}) => {
  useEffect(() => {
    switch (errorField?.name?.[0] as string) {
      case 'simple':
        if (getFieldValue(['ui', 'step']) !== 0) {
          setFieldsValue({ ui: { step: 0 } });
        }
        break;
      default:
        break;
    }
  }, [errorField, getFieldValue, setFieldsValue]);

  const current = getFieldValue(['ui', 'step']);

  return (
    <StepsStyling>
      <Steps
        onChange={(v) => setFieldsValue({ ui: { step: v } })}
        direction="vertical"
        current={current}
        status={current < 3 ? 'process' : 'finish'}
      >
        {[
          {
            title: <T _str="Details" />,
            scopes: ['simple', 'manageContainers', 'bulkWastePickup'],
          },
          { title: <T _str="Order summary" />, scopes: [] },
          { title: <T _str="Contact information" />, scopes: ['contactInfo'] },
          { title: <T _str="Complete" />, scopes: [] },
        ].map(({ title, scopes }, i, arr) => (
          <Steps.Step
            onStepClick={(s) => {
              if (!errorField) {
                toStep(s);
              }
            }}
            title={title}
            status={
              scopes?.includes(errorField?.name?.[0] as string)
                ? 'error'
                : undefined
            }
            disabled={!!errorField || i === arr.length - 1 || current === 3}
          />
        ))}
      </Steps>
    </StepsStyling>
  );
};

const ViewCreateTicket = () => {
  const { email, name, phone_number: phoneNumber } = useContext(CitizenContext);
  const token = useGetToken();
  const setCitizen = useContext(CitizenDispatchContext);
  const { id: propertyId, ticketTypesMap } = useContext(PropertyContext);
  const { routingMap } = useRouting();
  const history = useHistory();
  const [form] = Form.useForm<TFormSchema>();
  const { ticketTypeId } = useParams<{
    ticketTypeId: string;
  }>();
  const ticketType: Record<string, $TSFixMe> | undefined = useMemo(
    () => ticketTypesMap?.[ticketTypeId],
    [ticketTypeId, ticketTypesMap]
  );

  useQuery(
    ['TicketCustomFields', ticketTypeId],
    () =>
      apiClient.get<TTicketCustomFieldConnection[]>(
        `property/${ticketTypeId}/custom_fields`
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => {
        form.setFieldsValue({
          customFields: prefillWithValues(data),
        });
      },
    }
  );

  const serviceType = useMemo<TExtendTicketTypeFormHandlers>(
    () =>
      array<TExtendTicketTypeFormHandlers>(
        ticketType?.service?.service_type,
        ticketType?.is_container_level && 'simple_ticket_container_level',
        'simple_ticket_property_level'
      )[0],
    [ticketType?.is_container_level, ticketType?.service?.service_type]
  );
  const step = Form.useWatch(['ui', 'step'], form);
  const toStep = useCallback(
    (s: number) => {
      const contactFormFields = form
        .getFieldsError()
        .filter((err) => err.name.includes('contactInfo'))
        ?.map((e) => e.name);

      const otherFields = form
        .getFieldsError()
        .filter((err) => !err.name.includes('contactInfo'))
        ?.map((e) => e.name);

      form
        .validateFields(step === 2 ? contactFormFields : otherFields)
        .then(() => {
          form.setFieldsValue({
            ui: { step: s },
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form]
  );

  const { isLoading: isSubmitting, mutate } = useMutation(apiMutate, {
    onError: () => {},
    onSuccess: () => {
      toStep(3);
    },
  });

  /* Todo: Add the error and loading state to the form loading state and error handler. */
  const mutateCitizen = useMutation(
    (params: $TSFixMe) =>
      PatchPropertyUser(
        params.name,
        params.phoneNumber,
        params.email,
        params.language,
        params.token
      ),
    {
      onSuccess: (data) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setCitizen(data.data);
      },
    }
  );

  useEffect(() => {
    if (!supportedFormHandlers.includes(serviceType)) {
      message.error(<T _str="Ticket type not supported" />);
      history.push(routingMap.app.selfService._, serviceType);
    }
  }, [history, routingMap.app.selfService._, serviceType]);

  useEffect(() => {
    /* Setting initial values from the routing state. */
    const { checked } = (history.location.state as { checked: string[] }) || {};
    if (checked?.length)
      switch (serviceType) {
        case 'extra_emptying':
        case 'cleaning':
        case 'remove_container':
        case 'repair_container':
        case 'simple_ticket_container_level':
          form.setFieldsValue({
            simple: {
              items: checked?.reduce(
                (acc, id) => ({ ...acc, [id]: { checked: true } }),
                {}
              ),
            },
          });
          break;
        case 'add_container':
        case 'change_container_pickup_setting':
        case 'change_container_type':
          form.setFieldsValue({
            manageContainers: {
              items: checked?.reduce(
                (acc, id) => ({ ...acc, [id]: { checked: true } }),
                {}
              ),
            },
          });
          break;
        case 'bulk_waste_pickup':
          form.setFieldsValue({
            bulkWastePickup: {
              items: checked?.reduce(
                (acc, id) => ({ ...acc, [id]: { checked: true } }),
                {}
              ),
            },
          });
          break;
        default:
          break;
      }
    // on initial mount only.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Detail = useMemo(
    () => serviceStepsMap[serviceType]?.Form,
    [serviceType]
  );
  const Summary = useMemo(
    () => serviceStepsMap[serviceType]?.Summary,
    [serviceType]
  );

  const dropdownEnabled = (
    [
      'extra_emptying',
      'cleaning',
      'repair_container',
      'simple_ticket_container_level',
    ] as TExtendTicketTypeFormHandlers[]
  ).includes(serviceType);

  const items = useMemo(
    () => [
      Detail && (
        <Detail.Content
          formType={serviceType}
          addonEnd={
            <FormItemTicketCustomFormDropdown
              hiddenOverwrite={!dropdownEnabled}
              ticketType={ticketType}
            />
          }
        />
      ),
      Summary && <Summary />,
      <FormContactInfo.Content />,
      <OrderComplete />,
    ],
    [Detail, Summary, dropdownEnabled, serviceType, ticketType]
  );

  const onFinish = () => {
    const {
      simple,
      contactInfo,
      bulkWastePickup,
      manageContainers,
      simpleProperty,
      dropdownValue,
      customFields,
    } = form.getFieldsValue();

    mutateCitizen.mutate({
      name: contactInfo?.name,
      phoneNumber: contactInfo?.phoneNumber,
      email: contactInfo?.email,
      language: 'da',
      token,
    });

    switch (serviceType) {
      case 'extra_emptying':
      case 'cleaning':
      case 'repair_container':
      case 'simple_ticket_container_level':
        /* handle simple mutation here. */
        mutate({
          mode: 'containers',
          token,
          payload: {
            ticket_type_id: ticketType?.id,
            property_id: propertyId,
            containers: Object.keys(simple?.items || {})
              .map((containerId) => ({
                ...simple?.items?.[containerId],
                id: containerId,
              }))
              .filter((n) => n.checked)
              .map(({ preferredDate, id }) => ({
                container_id: id,
                note: simple?.note,
                preferred_date: preferredDate
                  ? moment(preferredDate).format('YYYY-MM-DD')
                  : undefined,
                dropdown_value: dropdownValue,
              })),
            custom_values: prepareCustomValues(customFields),
          },
        });
        break;
      case 'bulk_waste_pickup':
        mutate({
          mode: 'bulk-waste-pickup',
          token,
          payload: {
            property_id: propertyId,
            ticket_type_id: ticketType?.id,
            dropdown_value: dropdownValue,
            preferred_date: bulkWastePickup?.preferredDate
              ? moment(bulkWastePickup?.preferredDate).format('YYYY-MM-DD')
              : undefined,
            items: Object.keys(bulkWastePickup?.items || {})
              .map((containerId) => ({
                ...bulkWastePickup?.items?.[containerId],
                id: containerId,
              }))
              .filter((n) => n.checked)
              .map(
                ({
                  id,
                  estimatedVolume,
                  estrimatedWeight,
                  note,
                  quantity,
                }) => ({
                  waste_fraction_id: id,
                  note,
                  quantity,
                  estimated_weight: estrimatedWeight || null,
                  estimated_volume: estimatedVolume || null,
                })
              ),
            custom_values: prepareCustomValues(customFields),
          },
        });
        break;
      case 'change_container_type':
      case 'change_container_pickup_setting':
      case 'add_container':
      case 'remove_container':
        mutate({
          mode: 'manage-containers',
          token,
          payload: {
            property_id: propertyId,
            ticket_type_id: ticketType?.id,
            containers_to_add:
              manageContainers?.addContainer && serviceType === 'add_container'
                ? [
                    {
                      pickup_setting_id:
                        manageContainers?.addContainer?.pickupSetting,
                      waste_fraction_id:
                        manageContainers?.addContainer?.wasteFraction,
                      container_type_id:
                        manageContainers?.addContainer?.containerType,
                      preferred_date: manageContainers?.addContainer
                        ?.preferredDate
                        ? moment(
                            manageContainers?.addContainer?.preferredDate
                          ).format('YYYY-MM-DD')
                        : undefined,
                      note: manageContainers?.note,
                    },
                  ]
                : [],
            containers_to_remove:
              serviceType === 'remove_container' // going to use simple form
                ? Object.keys(simple?.items || {})
                    .map((containerId) => ({
                      ...simple?.items?.[containerId],
                      id: containerId,
                    }))
                    .filter((n) => n.checked)
                    .map(({ id, preferredDate }) => ({
                      container_id: id,
                      preferred_date: preferredDate
                        ? moment(preferredDate).format('YYYY-MM-DD')
                        : undefined,
                      note: simple?.note,
                    }))
                : [],
            containers_to_edit: Object.keys(manageContainers?.items || {})
              .map((containerId) => ({
                ...manageContainers?.items?.[containerId],
                id: containerId,
              }))
              .filter((n) => n.checked)
              .map(({ id, pickupSetting, containerType, preferredDate }) => ({
                container_id: id,
                pickup_setting_id: pickupSetting,
                container_type_id: containerType,
                preferred_date: preferredDate
                  ? moment(preferredDate).format('YYYY-MM-DD')
                  : undefined,
                note: manageContainers?.note,
              })),
            custom_values: prepareCustomValues(customFields),
          },
        });
        break;
      case 'simple_ticket_property_level':
        mutate({
          mode: 'property',
          token,
          payload: {
            property_id: propertyId,
            ticket_type_id: ticketType?.id,
            note: simpleProperty?.note || undefined,
            preferred_date: simpleProperty?.preferredDate
              ? moment(simpleProperty?.preferredDate).format('YYYY-MM-DD')
              : undefined,
            dropdown_value: dropdownValue,
            custom_values: prepareCustomValues(customFields),
          },
        });
        break;
      default:
        break;
    }
  };

  const hasError = useCallback(() => {
    if (step === 2) {
      return form
        .getFieldsError()
        .filter((err) => err.name.includes('contactInfo'))
        ?.filter((err) => err.errors.length)?.[0];
    }
    return form
      .getFieldsError()
      .filter((err) => !err.name.includes('contactInfo'))
      ?.filter((err) => err.errors.length)?.[0];
  }, [form, step]);

  const stepBreadCrumb: Record<number, ReactNode | undefined> = useMemo(
    () => ({
      0: Detail && Detail.BreadCrumbTitle,
      1: <T _str="Order summary" />,
      2: FormContactInfo.BreadCrumbTitle,
      3: undefined,
    }),
    [Detail]
  );

  return (
    <Form
      requiredMark="optional"
      onFinish={onFinish}
      form={form}
      layout="vertical"
      initialValues={{
        ui: { step: 0 },
        contactInfo: { name, phoneNumber, email },
      }}
    >
      <Layout2
        sideContent={
          <Col span={24}>
            <Form.Item hidden name={['ui', 'step']} />
            <StepsStyles>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  return (
                    <StepsRender
                      getFieldValue={getFieldValue}
                      setFieldsValue={setFieldsValue}
                      errorField={hasError() as FieldError}
                      toStep={toStep}
                    />
                  );
                }}
              </Form.Item>
            </StepsStyles>
            {step < 2 && (
              <Form.Item shouldUpdate noStyle>
                {() => (
                  <Button
                    type="primary"
                    onClick={() => toStep(step + 1)}
                    disabled={!!hasError()}
                    block
                    icon={<RightOutlined />}
                    size="large"
                  >
                    <span>
                      <T _str="Next" />
                    </span>
                  </Button>
                )}
              </Form.Item>
            )}
            {step === 2 && (
              <Button
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
                disabled={!!hasError()}
                block
                icon={<RightOutlined />}
                size="large"
              >
                <span>
                  {ticketType?.portalContactInformationNextButtonLabel || (
                    <T _str="Confirm order" />
                  )}
                </span>
              </Button>
            )}
          </Col>
        }
        breadcrumb={
          stepBreadCrumb[step] && (
            <BreadCrumb
              title={stepBreadCrumb[step]}
              onBack={() => (step > 0 ? toStep(step - 1) : history.goBack())}
            />
          )
        }
        header={
          <HeaderWithBack
            title={ticketType?.name}
            onBack={() => history.goBack()}
            stepProgression={
              <StepProgression currentStep={step + 1} totalSteps={4} />
            }
          />
        }
        mainContent={
          <Styles>
            <Form.Item noStyle shouldUpdate>
              <ConditionalSoftRender current={step} items={items} />
            </Form.Item>
          </Styles>
        }
      />
    </Form>
  );
};

export default ViewCreateTicket;
