import { MutateFunction } from 'react-query';
import axios from 'axios';
import { message } from 'src/antd';
import React from 'react';
import { T } from '@transifex/react';
import { APIError } from '../consts/error';

type TVerificationType = 'sms' | 'email';

type TVariables = {
  token: string;
  verification_type: TVerificationType;
  phone_number?: string;
  email?: string;
  code: string;
};

export const mutateVertifyCode: MutateFunction<
  unknown,
  unknown,
  TVariables
> = ({ token, verification_type, email, phone_number, code }) =>
  axios
    .post(
      `notifications/verify-code`,
      {
        verification_type,
        email,
        phone_number,
        code,
      },
      {
        headers: {
          'X-API-Key': token,
        },
      }
    )
    .then((d) => {
      if (d.data?.success && d.data.verification_id) return d;
      message.error(<T _str="Invalid code" />);
      throw new APIError('invalid_code', { ERROR_MESSAGE_HANDLED: true });
    });
