import React, { ReactNode } from 'react';
import { Typography } from 'src/ui/Typography';
import styled from 'styled-components';
import { Button } from 'src/antd';
import { T } from '@transifex/react';
import { createPrefixer } from '../../utils';

const p = createPrefixer('wh-modular-select-');

const classes = {
  wrapper: p`wrapper`,
  icon: p`icon`,
  button: p`button`,
  wasteFraction: p`waste-fraction`,
  initContent: p`init-content`,
  centerContent: p`center-content`,
  endContent: p`end-content`,
  buttonContent: p`button-content`,
  collapseContent: p`collapse-content`,
};

export type ModularSelectProps = {
  title: {
    icon?: ReactNode;
    type: string | ReactNode;
    wasteFraction?: string | ReactNode;
  };
  centerContent?: ReactNode | string;
  endContent?: ReactNode | string;
  collapseContent?: ReactNode;
  checkbox: ReactNode;
  checked: boolean;
};

type WrapperProps = {
  $selected: boolean;
  $hasCollapseContent: boolean;
  $hasCenterContent: boolean;
  $hasEndContent: boolean;
};

const ModularSelectDiv = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0rem;

  .${classes.button} {
    width: 100%;
    height: unset !important;
    padding: 1rem;
    border: white 0 none;
    box-shadow: white 0 0;
    background: #f5f5f5;
    border-radius: 8px;
    min-height: 5.5rem;
  }

  .ant-checkbox {
    height: 1rem;
    margin-right: 1rem;
  }

  .${classes.buttonContent} {
    display: flex;
    flex-direction: column;
  }

  .${classes.icon} {
    display: none;
    margin: 0 1rem 0 0;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #0f3f6b;
    font-weight: 600;
  }

  .${classes.initContent} {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .${classes.endContent} {
    display: none;
    justify-content: start;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${(props) => props.theme.colors.dark100};
    font-weight: 400;
    margin-top: 1rem;

    .ant-form-item {
      width: 100%;
    }

    .ant-picker {
      width: 100%;
    }

    .ant-select {
      width: 100% !important;
      text-align: start;
    }
  }

  .${classes.collapseContent} {
    display: none;
    text-align: start;
    padding: 1rem;
    border-radius: 0 0 8px 8px;
  }

  ${({ $hasCenterContent, theme }) =>
    $hasCenterContent &&
    `
      .${classes.centerContent} {
      display: flex;
      justify-content: start;
      width: 100%;
      font-size: 1rem;
      line-height: 1.5rem;
      color: ${theme.colors.dark100};
      font-weight: 400;
      margin-top: 1rem;
    }
  `};

  ${({ $selected }) =>
    $selected &&
    `
      .${classes.button} {
        background: #edf6ff;
      }
      
      .${classes.endContent} {
        display: none;
      }
  `};

  ${({ $selected, $hasCollapseContent }) =>
    $selected &&
    $hasCollapseContent &&
    `
      .${classes.button} {
        background: #edf6ff;
        border-radius: 8px 8px 0 0;
      }
      
      .${classes.collapseContent} {
        display: inherit;
        background: #edf6ff;
        border-radius: 0 0 8px 8px;
      }
  `};

  ${({ $hasEndContent, $hasCenterContent }) =>
    !!$hasEndContent &&
    !$hasCenterContent &&
    `
      .${classes.endContent} {
      display: inherit;
    }
  `};

  .ant-form-item {
    margin-bottom: 0;
  }

  @media screen and (min-width: 992px) {
    .${classes.button} {
      padding: 1.5rem;
    }

    .${classes.buttonContent} {
      display: flex;
      flex-direction: row;
    }

    .${classes.initContent} {
      min-width: calc(8 / 24 * 100%);
      max-width: calc(8 / 24 * 100%);
    }

    .${classes.centerContent} {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      min-width: calc(8 / 24 * 100%);
      max-width: calc(8 / 24 * 100%);
    }

    .${classes.endContent} {
      display: flex;
      justify-content: end;
      align-items: center;
      margin-top: 0;
      min-width: calc(8 / 24 * 100%);
      max-width: calc(8 / 24 * 100%);

      .ant-picker {
        width: 15rem !important;
        text-align: start;
      }

      .ant-form-item-control-input {
        align-items: end;
      }
      .ant-form-item-control-input-content {
        display: flex;
        flex: fit-content;
        justify-content: end;
      }

      .ant-form-item .ant-select {
        min-width: 10rem;
        max-width: 10rem;
      }

      .ant-form-item {
        width: 10rem;
      }
    }

    .${classes.collapseContent} {
      font-size: 1rem;
      margin-top: 0.125rem;
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    }

    .${classes.collapseContent} .ant-input {
      height: 2.625rem;
    }

    .${classes.icon} {
      display: inherit;
    }

    ${({ $selected }) =>
      $selected &&
      `
      .${classes.button} {
        background: #edf6ff;
        padding: 1.5rem 1.5rem 1.5rem 1.5rem
      }
      
      .${classes.endContent} {
        display: inherit;
      }
  `};

    ${({ $hasCenterContent }) =>
      !$hasCenterContent &&
      `
      .${classes.initContent} {
        min-width: calc(16 / 24 * 100%) !important;
        max-width: calc(16 / 24 * 100%) !important;
      }
      .${classes.centerContent} {
        min-width: calc(0 / 24 * 100%) !important;
        max-width: calc(0 / 24 * 100%) !important;
      }
  `};
  }
`;

export const ModularSelect: React.FC<ModularSelectProps> = ({
  title,
  centerContent,
  endContent,
  collapseContent,
  checkbox,
  checked,
}) => {
  return (
    <ModularSelectDiv
      className={classes.wrapper}
      $selected={checked}
      $hasCollapseContent={!!collapseContent}
      $hasCenterContent={!!centerContent}
      $hasEndContent={!!endContent}
    >
      <Button className={classes.button}>
        <div className={classes.buttonContent}>
          <div className={classes.initContent}>
            {checkbox && <div>{checkbox}</div>}
            {title.icon && <div className={classes.icon}>{title.icon}</div>}
            <Typography
              ellipsis={{ tooltip: title.type }}
              variant="subHeader-20"
              weight="semi-bold"
              style={{ color: '#0f3f6b' }}
            >
              {title.type}
            </Typography>
          </div>
          <div className={classes.centerContent}>
            {centerContent && typeof centerContent === 'string' ? (
              <T _str="{centerContent}" centerContent={centerContent} />
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>{centerContent}</>
            )}
          </div>
          <div className={classes.endContent}>
            {endContent && typeof endContent === 'string' ? (
              <T _str="{endContent}" endContent={endContent} />
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>{endContent}</>
            )}
          </div>
        </div>
      </Button>
      <div className={classes.collapseContent}>{collapseContent}</div>
    </ModularSelectDiv>
  );
};
