export const pipeFnAndObject = <T extends string>(
  val: T,
  ...args: Array<Record<string, string> | ((val: string) => string)>
): string =>
  args.reduce((acc, arg) => {
    if (typeof arg === 'function') return arg(acc) || acc;
    return arg[acc] || acc;
  }, val as $TSFixMe);

export const convertObjToFun =
  <T extends string, V>(object: Record<T, V>): ((val: T) => V) =>
  (val) =>
    object[val];

export const toCssUnit = (val: string | number, unit = 'px'): string => {
  if (typeof val === 'number') return `${val}${unit}`;
  return val;
};
