import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { useGetPropertyContainers } from '../../api/property/property-containers';
import { PropertyContext } from '../../context/property-context';
import FormItemSelectableList, {
  TFormItemSelectableListSchema,
  TFormItemSelectableListProps,
} from '../../ui-legacy/FormItemSelectableList';

type TData = {
  id: string;
  data: $TSFixMe;
};

export type TSelectableContainersListProps = Pick<
  TFormItemSelectableListProps<TData>,
  'preName' | 'renderItem' | 'canBeEmpty' | 'footer'
> & {
  isChangeContainerType?: boolean;
  isPickupSchemeChange?: boolean;
};

export type { TFormItemSelectableListSchema };

const SelectableContainersList = ({
  isChangeContainerType,
  isPickupSchemeChange,
  ...p
}: TSelectableContainersListProps) => {
  const { id } = useContext(PropertyContext);
  const { ticketTypeId } = useParams<{ ticketTypeId: string }>();

  const { data, isLoading } = useGetPropertyContainers(id, {
    includePrices: true,
    includePreferredDates: true,
    pricesForTicketType: ticketTypeId,
  });

  const formatData = (fetchedData: $TSFixMe) => {
    if (isChangeContainerType) {
      return fetchedData?.data?.filter(
        (e: {
          prices: {
            change_container_type: { container_type: { id: string } }[];
          };
          container: { container_type: { id: string } };
        }) =>
          !!e?.prices?.change_container_type?.filter(
            (v) => v.container_type?.id !== e.container.container_type.id
          )?.length
      );
    }
    if (isPickupSchemeChange) {
      return fetchedData?.data?.filter(
        (e: {
          prices: {
            change_pickup_setting: { pickup_setting: { id: string } }[];
          };
          container: { pickup_setting: { id: string } };
        }) =>
          !!e?.prices?.change_pickup_setting?.filter(
            (v) => v.pickup_setting?.id !== e.container.pickup_setting.id
          )?.length
      );
    }

    return fetchedData?.data;
  };

  const formattedData = !ticketTypeId
    ? formatData(data)
    : formatData(data)?.filter((el: $TSFixMe) => el.ticket_type_price !== null);

  return (
    <FormItemSelectableList
      dataSource={formattedData?.map((node: $TSFixMe) => ({
        id: node.container.id,
        data: node,
      }))}
      loading={isLoading}
      {...p}
    />
  );
};

export default SelectableContainersList;
