// eslint-disable-next-line import/no-cycle
import { createRouteMap as createRouteMapMain } from '../components/main';

// eslint-disable-next-line import/no-cycle
import { pathJoin } from '../routing/utils';

export const createRoutesMap = (prefix: string) => ({
  login: pathJoin(prefix)(`login`).chain,
  'error-test': pathJoin(prefix)(`error-test`).chain,
  app: pathJoin(prefix).chainMap(
    createRouteMapMain(pathJoin(prefix).value.slice(1))
  ),
  calendar: pathJoin(prefix)(`calendar`).chain,
});
