import { WhDatePicker } from 'src/ui/DatePicker/WhDatePicker';

import React, { useContext, useMemo } from 'react';
import { Form, Space } from 'src/antd';
import moment from 'moment';
import { T } from '@transifex/react';
import { useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
import { WasteFractionTag } from 'src/ui';
// eslint-disable-next-line import/no-cycle
import SelectableContainersList from '../../../../../../../../new-components/SelectableContainersList';
import OrderOverview from '../../../../../../../shared/OrderOverview';
import { PropertyContext } from '../../../../../../../../context/property-context';
// eslint-disable-next-line import/no-cycle
import { useGetPropertyContainers } from '../../../../../../../../api/property/property-containers';
import { PreferredDateStyles } from '../../styles';
import { TCrmServiceServiceTypeChoices } from '../../../../../../../../api/consts/CrmServiceServiceTypeChoices';
import { PrefferedDate } from '../../elements/Summary';
import { formatPayment } from '../../../../../../../../utils/currency';
import { StopPropagationDiv } from '../../../../../../../shared/StopPropagationDiv';
import { TFormHandler } from '../../types';
import { VALIDATION_MESSAGE } from '../../../../../../../../utils/validation';
import { createMarkUp } from '../../../../../../../../utils/createMarkup';
// eslint-disable-next-line import/no-cycle
import { useTicketAvailableDates } from '../useTicketAvailableDates';
import { DATE_FORMAT } from '../consts';
import CustomFieldsFormSection from '../CustomFieldsFormSection';

const BreadCrumbTitle = <T _str="Select containers" />;

const Content: TFormHandler['Form']['Content'] = ({
  formType: serviceType,
  addonEnd,
}) => {
  const { ticketTypes, id: propertyId } = useContext(PropertyContext);
  const { ticketTypeId } = useParams<{ ticketTypeId: string }>();

  const ticketType: $TSFixMe = ticketTypes?.filter(
    (e: { id: string }) => e.id === ticketTypeId
  );

  const availableDates = useTicketAvailableDates({
    propertyId,
    ticketTypeId,
  });

  const {
    portalDateHidden,
    portalDateLabel,
    portalDateHelpText,
    portalDateRequired,
    portalNoteHidden,
    portalNoteLabel,
    portalNoteHelpText,
    portalNoteRequired,
    portalPriceShowZero,
    portalDescription,

    // eslint-disable-next-line no-unsafe-optional-chaining
  } = ticketType?.[0];

  const isMobile = useMediaQuery('(max-width: 996px)');
  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
      {
        <div
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={createMarkUp(portalDescription)}
        />
      }
      {addonEnd}
      <Form.Item
        label={portalNoteLabel || <T _str="Note" />}
        name={['simple', 'note']}
        tooltip={portalNoteHelpText || undefined}
        rules={[
          {
            required: portalNoteRequired,
            message: VALIDATION_MESSAGE.REQUIRED,
          },
        ]}
        hidden={portalNoteHidden}
      >
        <TextArea />
      </Form.Item>
      <SelectableContainersList
        preName={['simple', 'items']}
        renderItem={(
          {
            id,
            data: {
              container: {
                waste_fraction: wasteFraction,
                container_type: { name: containerType },
              },
              ticket_type_price: { service_price },
            },
          },
          checked
        ) => {
          const servicePrice =
            service_price / 100 || portalPriceShowZero ? 0 : null;

          return {
            title: !isMobile ? (
              <>
                <WasteFractionTag wasteFraction={wasteFraction} />
                {wasteFraction.name}
                {' - '}
                {containerType}
              </>
            ) : (
              <div style={{ textAlign: 'start' }}>
                <WasteFractionTag wasteFraction={wasteFraction} iconOnly />
                {wasteFraction.name}
                <br />
                {containerType}
              </div>
            ),
            addonEnd: serviceType &&
              (!!servicePrice || portalPriceShowZero) && (
                <Space>
                  <T
                    _str="{price} / one time"
                    price={formatPayment(servicePrice || 0, 'dkk')}
                  />
                </Space>
              ),
            addonContent: !portalDateHidden ? (
              <StopPropagationDiv>
                <PreferredDateStyles>
                  <Form.Item
                    label={portalDateLabel || <T _str="Preferred date" />}
                    name={['simple', 'items', id, 'preferredDate']}
                    tooltip={portalDateHelpText || undefined}
                    rules={[
                      {
                        required: portalDateRequired && checked,
                        message: VALIDATION_MESSAGE.REQUIRED,
                      },
                    ]}
                    hidden={portalDateHidden}
                  >
                    <WhDatePicker
                      format={DATE_FORMAT}
                      inputReadOnly
                      disabled={!checked}
                      disabledDate={(date) =>
                        !(availableDates.get(id) || [])?.includes(
                          date.format('YYYY-MM-DD')
                        )
                      }
                    />
                  </Form.Item>
                </PreferredDateStyles>
              </StopPropagationDiv>
            ) : undefined,
          };
        }}
      />
      <CustomFieldsFormSection />
    </Space>
  );
};

const Summary = () => {
  const { id: propertyId, ticketTypesMap } = useContext(PropertyContext);
  const { ticketTypeId } = useParams<{
    ticketTypeId: string;
  }>();
  const { data } = useGetPropertyContainers(propertyId, {
    includePrices: true,
    pricesForTicketType: ticketTypeId,
  });
  const ticketType = useMemo(
    () => ticketTypesMap?.[ticketTypeId],
    [ticketTypeId, ticketTypesMap]
  );
  const serviceType = useMemo(
    () => ticketType?.service?.service_type || ``,
    [ticketType?.service?.service_type]
  ) as TCrmServiceServiceTypeChoices;

  const returnContainerById = (id: string) => {
    return data?.data?.filter(
      (container: $TSFixMe) => container?.container?.id === id
    )?.[0];
  };

  const values = Form.useWatch(['simple']);

  const items = Object.keys(values?.items || {})
    .map((containerId) => ({
      ...values?.items?.[containerId],
      id: containerId,
    }))
    .filter((n) => n.checked)
    .map(({ preferredDate, id }: $TSFixMe) => ({
      containerId: id,
      preferredDate: preferredDate
        ? moment(preferredDate).format('YYYY-MM-DD')
        : undefined,
    }));

  const { portalPriceShowZero, portalDateLabel, portalDateHidden } = ticketType;

  // #todo get wastefraction on container
  const summaryItems = items?.map((item: $TSFixMe) => {
    const container = returnContainerById(item?.containerId);

    const getPriceFromServiceType = () =>
      container?.ticket_type_price?.service_price || 0;

    return serviceType === 'remove_container'
      ? {
          header: container?.container?.waste_fraction?.name,
          headerSuffix: <T _str=" (removed)" />,
        }
      : {
          header: container?.container?.waste_fraction?.name,
          description: (
            <>
              <PrefferedDate
                value={item.preferredDate}
                dateText={portalDateLabel}
                dateHidden={portalDateHidden}
              />
              {portalDateHidden && getPriceFromServiceType() > 0 ? (
                <T _str="Price:" />
              ) : undefined}
            </>
          ),
          price:
            getPriceFromServiceType() > 0
              ? // eslint-disable-next-line no-unsafe-optional-chaining
                getPriceFromServiceType() / 100
              : 0,
        };
  });

  const reducer = (currentValue: $TSFixMe, previousValue: $TSFixMe) =>
    previousValue + currentValue;

  const totalPrice =
    summaryItems.length > 0
      ? summaryItems?.map((item) => item.price).reduce(reducer, 0)
      : 0;

  return (
    <OrderOverview
      priceShowZero={portalPriceShowZero}
      header=""
      items={summaryItems}
      subtotalText={
        // eslint-disable-next-line no-nested-ternary
        serviceType === 'remove_container' ? undefined : totalPrice > 0 ||
          portalPriceShowZero ? (
          <T _str="Amount due:" />
        ) : undefined
      }
      // #TODO add price when available
      total={
        serviceType === 'remove_container'
          ? undefined
          : formatPayment(totalPrice, 'dkk')
      }
    />
  );
};

export const SimpleTicket = { Form: { BreadCrumbTitle, Content }, Summary };
