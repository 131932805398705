import React from 'react';
import { Select } from 'antd';

import { CustomFieldInputProps } from '../types';
import BaseCustomField from './BaseCustomField';

const formatOptions = (options: CustomFieldInputProps['options'] = []) =>
  options?.map(({ id, meta_value }) => ({
    label: meta_value,
    value: id,
  }));

const SelectCustomField = ({
  options,
  input_type,
  placeholder,
  ...config
}: CustomFieldInputProps) => {
  const isMultiple = input_type === 'MULTI_SELECT';
  return (
    <BaseCustomField {...config}>
      <Select
        options={formatOptions(options)}
        mode={isMultiple ? 'multiple' : undefined}
        placeholder={placeholder}
      />
    </BaseCustomField>
  );
};

export default SelectCustomField;
