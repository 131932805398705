import { useQuery, MutateFunction } from 'react-query';
import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { useGetToken } from '../../config';

type TVariables = {
  token: string;
  id: string;
  includeBulkWastePrices?: boolean;
  includeAddContainerPrices?: boolean;
  includePreferredDates?: boolean;
  priceForTicketTypeId?: string;
};

export const call: MutateFunction<unknown, unknown, TVariables> = ({
  id,
  token,
  includeBulkWastePrices,
  includeAddContainerPrices,
  includePreferredDates,
  priceForTicketTypeId,
}) =>
  axios.get(`property/${id}/ticket-prices`, {
    headers: {
      'X-API-Key': token,
    },
    params: {
      include_bulk_waste_prices: includeBulkWastePrices,
      include_add_container_prices: includeAddContainerPrices,
      include_preferred_dates: includePreferredDates,
      price_for_ticket_type_id: priceForTicketTypeId,
    },
  });

const K_GET_PROPERTY_TICKET_PRICES = 'K_GET_PROPERTY_TICKET_PRICES';

export const useGetPropertyTicketPrices = (
  propertyId: string,
  params?: Partial<
    Pick<
      TVariables,
      | 'includeBulkWastePrices'
      | 'includeAddContainerPrices'
      | 'includePreferredDates'
      | 'priceForTicketTypeId'
    >
  >
) => {
  const token = useGetToken();
  return useQuery<$TSFixMe>(
    [K_GET_PROPERTY_TICKET_PRICES, propertyId, { ...params }],
    () => call({ token, id: propertyId, ...params })
  );
};
