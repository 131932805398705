import { Col, Row, Form, Checkbox } from 'src/antd';
import React, { ReactNode, useCallback, useEffect } from 'react';
import { StyledSkeletonInput } from 'src/ui';

import {
  SelectableItem,
  TSelectableItemProps,
} from '../components/main/routes/self-service/routes/create/elements/SelectableItem';
import { VALIDATION_MESSAGE } from '../utils/validation';
import { TableKit } from './table-kit';

export type TFormItemSelectableListSchema = Record<
  string,
  { checked: boolean }
>;

export type TFormItemSelectableListProps<TData> = {
  preName: Array<string>;
  renderItem?: (
    data: TData,
    checked: boolean
  ) => Omit<TSelectableItemProps, 'checked' | 'addonPre'>;
  dataSource?: TData[];
  loading?: boolean;
  /** default to false */
  canBeEmpty?: boolean;
  footer?: ReactNode;
};

const FormItemSelectableListErrorWatch = ({
  preName,
}: Pick<TFormItemSelectableListProps<$TSFixMe>, 'preName'>) => {
  const values = Form.useWatch([]);
  const form = Form.useFormInstance();

  const hasItemsSelected = useCallback((v) => {
    return Object.keys(v || {}).some((k) => v[k]?.checked);
  }, []);

  useEffect(() => {
    if (
      !hasItemsSelected(form.getFieldValue(preName)) &&
      form.isFieldTouched(preName)
    ) {
      form.setFields([
        {
          name: preName,
          errors: [
            VALIDATION_MESSAGE.ARRAY_ONE_ITEM_REQUIRED as unknown as string,
          ],
        },
      ]);
    } else {
      form.setFields([
        {
          name: preName,
          errors: [],
        },
      ]);
    }
  }, [form, hasItemsSelected, preName, values]);

  return (
    <Form.Item
      className="no-height"
      name={preName}
      rules={[
        {
          validator: (_, v = {}) =>
            hasItemsSelected(v) ? Promise.resolve() : Promise.reject(),
          message: VALIDATION_MESSAGE.ARRAY_ONE_ITEM_REQUIRED,
        },
      ]}
    >
      {({ getFieldError }) => (
        <Form.ErrorList helpStatus="error" errors={getFieldError(preName)} />
      )}
    </Form.Item>
  );
};

const FormItemSelectableList = <TData extends { id: string } = { id: string }>({
  preName,
  renderItem,
  dataSource,
  loading,
  canBeEmpty,
  footer,
}: TFormItemSelectableListProps<TData>) => {
  return (
    <div className="wh-vertical-scroller-container" style={{ rowGap: 16 }}>
      <div
        className="wh-vertical-scroller-container-scroll"
        style={{ overflow: 'auto' }}
      >
        {!canBeEmpty && <FormItemSelectableListErrorWatch preName={preName} />}
        <Row gutter={[0, 16]}>
          {!loading &&
            React.Children.toArray(
              dataSource?.map((item) => (
                <Form.Item
                  noStyle
                  dependencies={[[...preName, item.id, 'checked']]}
                  shouldUpdate
                >
                  {({ getFieldValue, setFields }) => {
                    const checked = getFieldValue([
                      ...preName,
                      item.id,
                      'checked',
                    ]);

                    const setChecked = () =>
                      setFields([
                        {
                          name: [...preName, item.id, 'checked'],
                          value: !checked,
                        },
                      ]);

                    return (
                      <Col xs={24}>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <div onClick={() => setChecked()}>
                          <SelectableItem
                            checked={checked}
                            addonPre={
                              <Form.Item
                                noStyle
                                name={[...preName, item.id, 'checked']}
                                valuePropName="checked"
                              >
                                <Checkbox />
                              </Form.Item>
                            }
                            {...renderItem?.(item, checked)}
                          />
                        </div>
                      </Col>
                    );
                  }}
                </Form.Item>
              ))
            )}
          {loading &&
            React.Children.toArray(
              Array.from({ length: 5 }).map(() => (
                <Col xs={24}>
                  <StyledSkeletonInput
                    width="100%"
                    active
                    style={{ height: 80, borderRadius: 8 }}
                    size="large"
                  />
                </Col>
              ))
            )}
          {!loading && !dataSource?.length && <TableKit.NoData />}
        </Row>
      </div>
      {footer}
    </div>
  );
};

export default FormItemSelectableList;
