import React, { useContext, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import { useTreeFilterAndOrder } from 'src/ui';

import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
import { T } from '@transifex/react';
import { Layout1 } from '../../../../layout';
import { Header } from '../../../../layout/header';
import { BreadCrumb } from '../../../../layout/breadcrumb';
// eslint-disable-next-line import/no-cycle
import { OpenTicketsOnPropertyAlert } from '../../../../shared/OpenTicketsOnPropertyAlert';
// eslint-disable-next-line import/no-cycle
import { ApiWrapper } from './api-wrapper';
// eslint-disable-next-line import/no-cycle
import { routingMap } from '../../../../../routing/routing-map';
// eslint-disable-next-line import/no-cycle
import { useGetToken } from '../../../../../api/config';
import { PropertyContext } from '../../../../../context/property-context';
import { GetTickets } from '../../../../shared/api/ticket';
// eslint-disable-next-line import/no-cycle
import { columns, Key } from './ui';

const Homepage = () => {
  const token = useGetToken();
  const [tickets, setTickets] = useState([]);
  const { id: propertyId } = useContext(PropertyContext);

  useQuery(
    ['get-container-tickets', propertyId],
    () =>
      GetTickets({
        property_id: propertyId,
        token,
        only_open: true,
        property_level_only: true,
      }),
    {
      onSuccess: (data) => {
        setTickets(data.data);
      },
    }
  );

  const { state: autoState, jsx } = useTreeFilterAndOrder<Key>({
    columns,
    hideColumns: ['wasteFraction'],
    init: {
      checked: [
        'containerType',
        'pickupSetting',
        'containerId',
        'weekDay',
        'nextEmptying',
        'price',
      ],
      order: [
        'containerType',
        'pickupSetting',
        'containerId',
        'weekDay',
        'nextEmptying',
        'price',
      ],
    },
    key: 'CRM.container.table',
  });

  const isMobile = useMediaQuery('(max-width: 996px)');

  return (
    <Layout1
      breadcrumb={
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <BreadCrumb title={<T _str="Containers" />} />
          {isMobile && jsx.toggler}
        </div>
      }
      header={
        <Header icon={<DeleteOutlined />} title={<T _str="Containers" />} />
      }
      mainContent={
        <div>
          {tickets.length > 0 && (
            <OpenTicketsOnPropertyAlert
              style={{ marginBottom: '1rem' }}
              linkTo={routingMap.app.containers._}
              tickets={tickets}
            />
          )}
          <ApiWrapper jsx={jsx} autoState={autoState} />
        </div>
      }
    />
  );
};

export default Homepage;
