import React from 'react';
import { createAntdIcon } from '../createIcon';

export const WhIconAntdFoodAndDrinkFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.47465 5.53656C9.47465 5.48133 9.42988 5.43656 9.37465 5.43656H3.82681C3.77158 5.43656 3.72681 5.48133 3.72681 5.53656V14.3097C3.72681 14.365 3.77158 14.4097 3.82681 14.4097H9.37465C9.42988 14.4097 9.47465 14.365 9.47465 14.3097V5.53656Z" />
      <path d="M10.7139 1.69027C10.7139 1.63504 10.6691 1.59027 10.6139 1.59027H5.05285C4.99763 1.59027 4.95285 1.63504 4.95285 1.69027V2.62733C4.95285 2.68256 4.99763 2.72733 5.05285 2.72733H10.6139C10.6691 2.72733 10.7139 2.68256 10.7139 2.62733V1.69027Z" />
      <path d="M9.47463 5.14483C9.47463 5.14113 9.47762 5.13814 9.48132 5.13814H9.48367C9.48705 5.13814 9.49015 5.13625 9.4917 5.13325L10.5155 3.15789C10.55 3.09132 10.5017 3.01187 10.4268 3.01187H5.00109C4.96487 3.01187 4.93148 3.03146 4.91381 3.06307L3.82937 5.00273C3.7921 5.06938 3.84028 5.15153 3.91665 5.15153H9.46793C9.47163 5.15153 9.47463 5.14853 9.47463 5.14483Z" />
      <path d="M10.1591 4.58497C10.1108 4.67835 10.2208 4.77352 10.3062 4.71218L11.8637 3.59383C11.93 3.54619 11.9143 3.4434 11.8368 3.41769L11.002 3.14084C10.9553 3.12537 10.9043 3.14611 10.8817 3.18977L10.1591 4.58497Z" />
      <path d="M9.90276 14.0776C9.90276 14.1592 9.99513 14.2065 10.0613 14.1587L12.2316 12.593C12.2577 12.5742 12.2731 12.544 12.2731 12.5119V3.87291C12.2731 3.79147 12.181 3.74418 12.1148 3.79168L9.94445 5.34978C9.91828 5.36857 9.90276 5.3988 9.90276 5.43101V14.0776Z" />
    </svg>
  );
});
