import React, { useContext } from 'react';
// eslint-disable-next-line import/no-cycle
import { useGetPropertyTicketPrices } from '../../../../../../../../api/property/ticket-prices';
import { PropertyContext } from '../../../../../../../../context/property-context';

import FormItemSelectableList, {
  TFormItemSelectableListSchema,
  TFormItemSelectableListProps,
} from '../../../../../../../../ui-legacy/FormItemSelectableList';

type TData = {
  id: string;
  data: $TSFixMe;
};

export type TSelectWasteFractionsListProps = Pick<
  TFormItemSelectableListProps<TData>,
  'preName' | 'renderItem'
>;

export type { TFormItemSelectableListSchema };

const SelectWasteFractionsList = ({
  preName,
  renderItem,
}: TSelectWasteFractionsListProps) => {
  const { id } = useContext(PropertyContext);
  const { data, isLoading } = useGetPropertyTicketPrices(id, {
    includeBulkWastePrices: true,
    includePreferredDates: true,
  });

  return (
    <FormItemSelectableList
      preName={preName}
      dataSource={data?.data?.bulk_waste_prices?.map((node: $TSFixMe) => ({
        id: node.waste_fraction.id,
        data: node,
      }))}
      renderItem={renderItem}
      loading={isLoading}
    />
  );
};

export default SelectWasteFractionsList;
