import React from 'react';
import 'antd/dist/antd.css';
import './index.css';
import { createRoot } from 'react-dom/client';
import { App } from './app';
import { RouterProvider } from './context/routing';
import 'moment/locale/da';

import { ErrorBoundary } from './sentry';
import './transifex';

if (window.WH_SS_INIT_ROUTE) {
  window.usingAsEmbedded = true;
}
const root = createRoot(
  document.getElementById(window.WH_SS_ROOT_ELEMENT_ID || 'root')!
);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <RouterProvider>
        <App />
      </RouterProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
