/* eslint-disable */
import React from 'react';
import { T } from '@transifex/react';
import {
  SmileOutlined,
  CalendarOutlined,
  DeleteOutlined,
  NotificationOutlined,
} from '@ant-design/icons';

import { RoutingMap } from '../../../routing/utils';

export const createNavData: (map: RoutingMap) => any[] = (routingMap) => [
  {
    key: 'self-service',
    title: <T _str="Self-service" />,
    link: routingMap.app.selfService._,
    icon: SmileOutlined,
  },
  {
    key: 'containers',
    title: <T _str="Containers" />,
    link: routingMap.app.containers._,
    icon: DeleteOutlined,
  },
  {
    key: 'collections',
    title: <T _str="Collections" />,
    link: routingMap.app.collections._,
    icon: CalendarOutlined,
  },
  {
    key: 'notifications',
    title: <T _str="Notifications" />,
    link: routingMap.app.notifications._,
    icon: NotificationOutlined,
  },
];
