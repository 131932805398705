import styled, { css } from 'styled-components';

export type Variants = `default` | `danger` | `warning`;

type StyledModalComponentProps = {
  $variant?: Variants;
  $isFullScreen?: boolean;
  $isWithFooter?: boolean;
  $styles?: string;
};

const bgMap: Record<Variants, string> = {
  default: `#111d2c`,
  danger: `#FF4D4F`,
  warning: '#FFA940',
};
const fontColorMap: Record<Variants, string> = {
  default: `white`,
  danger: `white`,
  warning: '#612500',
};

const StyledModal = css<StyledModalComponentProps>`
  .ant-modal-header {
    background: ${({ $variant = `default` }) => bgMap[$variant]} !important;

    .ant-modal-title {
      color: ${({ $variant = `default` }) => fontColorMap[$variant]} !important;
    }
  }

  .ant-modal-close {
    .ant-modal-close-icon {
      color: ${({ $variant = `default` }) => fontColorMap[$variant]} !important;
    }
  }

  .ant-modal-body {
    ${({ $isFullScreen, $isWithFooter }) =>
      $isFullScreen
        ? `
      height: calc(100vh - ${$isWithFooter ? `100` : `55`}px);
      overflow: hidden;
    `
        : ``}
  }
  ${({ $styles = `` }) => $styles}
`;

export const ModalLoadContainer = styled.div`
  width: 100%;
  left: 50%;
  top: 50%;

  .ant-modal-body {
    height: 250px !important;
  }
`;

export default StyledModal;
