import React from 'react';
import { Tag } from 'src/antd';
import {
  TicketTicketStatusChoices,
  ticketTicketStatusChoicesLabelMap,
  ticketTicketStatusChoicesTagColorMap,
} from '../utills';

export type TicketStatusProps = {
  ticketStatus: TicketTicketStatusChoices;
};

export const TicketTag = ({ ticketStatus }: TicketStatusProps) => {
  return (
    <Tag color={ticketTicketStatusChoicesTagColorMap[ticketStatus]}>
      {ticketTicketStatusChoicesLabelMap[ticketStatus]}
    </Tag>
  );
};
