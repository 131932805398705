import React from 'react';
import { Input } from 'antd';

import { CustomFieldInputProps } from '../types';
import BaseCustomField from './BaseCustomField';

const TextAreaCustomField = ({
  placeholder,
  ...config
}: CustomFieldInputProps) => (
  <BaseCustomField {...config}>
    <Input.TextArea placeholder={placeholder} />
  </BaseCustomField>
);

export default TextAreaCustomField;
