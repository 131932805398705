import React, { ReactNode } from 'react';
import { Col, Row } from 'src/antd';
import styled from 'styled-components';
import { createPrefixer } from 'src/utils';
import { WasteHeroLogo1 } from 'src/svgs';

const p = createPrefixer('wh-login-screen-');

const classes = {
  wrapper: p`wrapper`,
  logo: p`logo`,
  center: p`center`,
  text: p`text`,
};

export type LoginContainerProps = {
  descriptiveText?: JSX.Element | false;
  logo: string;
  children?: ReactNode;
};

const LoginContainerDiv = styled.div`
  height: auto;
  background: white;
  padding: 1rem;
  padding-bottom: 3rem;
  font-size: 0.75rem;
  line-height: 1rem;
  position: absolute;
  left: calc(50% - 200px);
  width: 400px;
  top: 10rem;
  box-shadow: 5px 5px 80px rgba(15, 63, 107, 0.3);
  border-radius: 8px;

  .${classes.logo} {
    font-size: 50px;
    margin-bottom: 1rem;
    max-height: 150px;
  }

  .${classes.center} {
    display: flex;
    justify-content: center;
    max-height: 100px;

    @media screen and (max-width: 480px) {
      max-height: 70px;
    }
  }

  .${classes.text} {
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
    color: ${(props) => props.theme.colors.dark100};
  }

  .ant-select-selector {
    height: 2.5rem !important;
    align-items: center;
    font-size: 1rem;
  }

  .ant-select-arrow {
    color: rgba(0, 0, 0, 0.85);
    font-size: 0.875rem;
  }

  .ant-btn {
    font-size: 1rem;
    line-height: 1.5rem;
    min-height: 2.938rem;
    padding: 0.4rem 0 0.4rem 0;
  }

  .ant-select {
    height: 2.5rem;
    display: block;
  }

  .ant-form-item {
    margin-right: 0px !important;
  }

  @media screen and (max-width: 480px) {
    left: calc(50% - 150px);
    width: 300px;
  }

  @media screen and (max-width: 400px) {
    top: 6rem;
  }

  @media screen and (max-width: 340px) {
    left: calc(50% - 125px);
    width: 250px;
  }

  @media screen and (min-width: 992px) {
    left: 40%;
    padding: 3rem;
    top: 20%;
    left: calc(50% - 295px);
    width: 590px;

    font-size: 1rem;
    line-height: 1.5rem;

    .${classes.logo} {
      font-size: 100px;
      margin-bottom: 2rem;
      max-height: 150px;
    }

    .ant-divider {
      margin: 2rem 0 1rem 0;
    }

    .ant-btn {
      padding: 0px;
      font-size: 1.25rem;
      line-height: 1.75rem;
      min-height: 2.75rem;
    }

    .ant-input-affix-wrapper {
      height: 2.5rem;
      padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    }
  }
`;

export const LoginContainer: React.FC<LoginContainerProps> = ({
  logo,
  children,
  descriptiveText,
}: LoginContainerProps) => {
  return (
    <LoginContainerDiv className={classes.wrapper}>
      <Row gutter={[16, 16]}>
        {!window.WH_SS_NO_LOGO && (
          <Col span={24}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div className={classes.center}>
              {logo ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <img
                  style={{ objectFit: 'contain', maxWidth: '100%' }}
                  src={logo}
                  alt="Login logo"
                />
              ) : (
                <div className={classes.logo}>
                  <WasteHeroLogo1 style={{ fontSize: 'inherit' }} />
                </div>
              )}
            </div>
          </Col>
        )}
        {descriptiveText && (
          <Col span={24} className={classes.text}>
            {descriptiveText}
          </Col>
        )}
        <Col span={24}>
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            {children}
          </div>
        </Col>
      </Row>
    </LoginContainerDiv>
  );
};
