import styled, { css } from 'styled-components';

export type TCssScrollerProps = {
  width?: string;
  bgTrack?: string;
  bgThumb?: string;
  offset?: number;
  defaultYScroller?: boolean;
};

type TCssScrollerDiv2Props = {
  overflowYAuto?: boolean;
  overflowXAuto?: boolean;
};

export const CssScroller = css<TCssScrollerProps>`
  ${({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    bgThumb = '#c2bdbd',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    bgTrack = '#f5f5f5',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    width = '7px',
    offset = 0,
  }) => `
  /* scrollbar offset */
  margin-right: -${offset}px;
  padding-right: ${offset}px;
  `}

  ${({ defaultYScroller }) =>
    defaultYScroller
      ? `
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  `
      : ``}
`;

export const CssScrollerDiv = styled.div<TCssScrollerProps>`
  ${CssScroller}
`;

export const CssScrollerDiv2 = styled.div<
  TCssScrollerProps & TCssScrollerDiv2Props
>`
  ${CssScroller}
  ${({ overflowXAuto, overflowYAuto }) => `
    ${overflowXAuto ? 'overflow-x: auto; width: 100%;' : ''}
    ${overflowYAuto ? 'overflow-y: auto; height: 100%;' : ''}
  `}
`;
