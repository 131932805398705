import axios from 'axios';

export type GetTicketsParams = {
  property_id: string;
  token: string;
  from_date?: string;
  to_date?: string;
  container_Id?: string;
  only_open?: boolean;
  property_level_only?: boolean;
  skip?: number;
  limit?: number;
};

export const GetTickets = (params: GetTicketsParams) => {
  return axios.get(`property/${params.property_id}/tickets`, {
    headers: {
      'X-API-Key': params.token,
    },
    params: {
      from_date: params.from_date,
      to_date: params.to_date,
      container_id: params.container_Id,
      only_open: params.only_open,
      property_level_only: params.property_level_only,
      skip: params.skip,
      limit: params.limit,
    },
  });
};

type GetTicketParams = {
  property_id: string;
  ticket_id: string;
  token: string;
};

export const GetTicket = (params: GetTicketParams) => {
  return axios.get(
    `property/${params.property_id}/tickets/${params.ticket_id}`,
    {
      headers: {
        'X-API-Key': params.token,
      },
    }
  );
};
