import React from 'react';
import { createAntdIcon } from '../createIcon';

export const WhIconAntdPaperForShreddingFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.86582 1.57289L3.57613 5.86801L7.86681 5.86796L7.86582 1.57289Z" />
      <path d="M10.531 8.79865V12.6588H11.4638V8.79865H11.7301V13.3243H12.6587L12.6588 1.34457H8.13281V6.13421H3.34119V13.3243H4.26996V8.79865H4.53616V14.6554H5.46895V8.79865H5.73514V12.6588H6.66793V8.79865H6.93413V13.9899H7.86692V8.79865H8.13312V13.3243H9.06586V8.79865H9.33211V14.6554H10.2649V8.79865H10.531Z" />
    </svg>
  );
});
