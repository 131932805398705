import { tx } from '@transifex/native';

export const formatPayment = (value: number, curr: string) => {
  try {
    if (curr.toLowerCase() === 'dkk') return value.toString().concat(' kr.');
    // Todo - get locale from centralized localization context.
    return new Intl.NumberFormat(tx.getCurrentLocale() || 'en-IN', {
      currency: curr,
      style: 'currency',
    }).format(value);
  } catch (e: $TSFixMe) {
    return e?.message;
  }
};
