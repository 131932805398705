import React, { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import moment, { Moment } from 'moment';
import { useTX } from '@transifex/react';
import { groupBy } from 'lodash';
import { useCompany } from 'src/app/Company.store';
import { createMarkUp } from 'src/utils/createMarkup';
import {
  getMonthIndex,
  getMonthRangeArray,
  getYear,
  monthSelectValueToMomentRange,
} from '../../utils';
import { GetCollections } from '../../components/main/routes/collections/exact/api';
import { BrowserlessCalendar } from './component';
import { callGetOneProperty } from '../../api/property';

const Browserless = () => {
  const { data: companyData } = useCompany();
  const tx = useTX();

  const urlSearchParams = new URLSearchParams(window.location.search);

  const params = Object.fromEntries(urlSearchParams.entries());
  const dateRange = `${params.from_date}, ${params.to_date}`;
  const key = params.api_key;
  const propertyId = params.property_id;

  const { language } = params;

  if (language) {
    moment.locale(language);
    tx.setCurrentLocale(language);
  }

  const dates = monthSelectValueToMomentRange(dateRange);

  const { data } = useQuery(
    [
      'get-property-collections',
      propertyId,
      moment(dates[0]).format(),
      moment(dates[1]).format(),
    ],
    () =>
      GetCollections({
        token: key,
        propertyId,
        fromDate: moment(dates[0]).format('YYYY-MM-DD'),
        toDate: moment(dates[1]).format('YYYY-MM-DD'),
      })
  );

  const { data: propertyData } = useQuery(
    ['get-property-browserless', propertyId, key],
    () => callGetOneProperty({ token: key, id: propertyId })
  );

  const collections = data?.data;

  const collectionsGroupedByDate = groupBy(collections, 'date');

  const monthsArray = useMemo(() => {
    const betweenMonths: Moment[] = [];

    const startDate = dates[0];
    const endDate = dates[1];

    const date = startDate.clone().startOf('month');

    while (date < endDate.endOf('month')) {
      betweenMonths.push(date.clone());
      date.add(1, 'month');
    }

    return betweenMonths;
  }, [dates]);

  return (
    <div style={{ height: '100%' }}>
      {monthsArray.map((e) => (
        <div style={{ pageBreakAfter: 'always' }}>
          <BrowserlessCalendar
            data={collectionsGroupedByDate}
            initialRange={getMonthRangeArray(getMonthIndex(e), getYear(e))}
            // @ts-expect-error WIP
            location={propertyData?.data?.location?.name}
          />
        </div>
      ))}
      {companyData.crm_pdf_footer && (
        <div
          style={{ marginBottom: '1rem' }}
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={createMarkUp(companyData.crm_pdf_footer)}
        />
      )}
    </div>
  );
};

export default () => {
  useEffect(() => {
    window.usingBrowerserLess = true;
  }, []);

  return <Browserless />;
};
