import React, { ReactNode } from 'react';
import { Button, Col, Row, Space } from 'src/antd';
import { CloseOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Typography } from 'src/ui/Typography';
import { T } from '@transifex/react';
import moment from 'moment';
import { useRouting } from 'src/routing/child-router-factory';
import { useHistory } from 'react-router';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
import { pickupOrderStatusColorMap } from 'src/api/consts/PickupOrderStatus';
import { CSSProperties } from 'styled-components';
import { TCollectionStatus } from 'src/api/consts/CollectionStatus';

type CollectionStatus = Exclude<
  TCollectionStatus,
  'SCHEDULED' | 'PENDING' | 'COMPLETE' | 'MISSED'
>;

type TicketsListProps = {
  tickets: $TSFixMe[];
  onClose?: () => void;
  hideHeader?: boolean;
  collectionStatus?: CollectionStatus;
};

const collectionStatusMap: Record<
  CollectionStatus,
  { title: ReactNode; description: ReactNode; color: CSSProperties['color'] }
> = {
  COMPLETE_WITH_TICKET: {
    color: pickupOrderStatusColorMap.completed_with_tickets,
    title: <T _str="We found some issues while emptying the container" />,
    description: (
      <T _str="Please see the following report from the waste collector." />
    ),
  },
  COMPLETE_NOT_EMPTIED: {
    color: pickupOrderStatusColorMap.not_completed_with_tickets,
    title: <T _str="We are not able to empty you container." />,
    description: (
      <T _str="Please see the following report from the waste collector." />
    ),
  },
};

const TicketsList = ({
  tickets,
  onClose,
  hideHeader = false,
  collectionStatus,
}: TicketsListProps) => {
  const { routingMap } = useRouting();
  const history = useHistory();

  const isMobile = useMediaQuery('(max-width: 996px)');

  const collectionStatusData = collectionStatus
    ? collectionStatusMap[collectionStatus]
    : undefined;

  return (
    <Row>
      {!hideHeader && (
        <>
          <Col xs={24}>
            <Row
              style={{ marginBottom: collectionStatus ? 8 : 16 }}
              justify="space-between"
              align="middle"
            >
              <Col>
                <Space>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ExclamationCircleFilled
                      style={{
                        fontSize: 20,
                        color: collectionStatusData?.color ?? '#FF4D4F',
                      }}
                    />
                  </div>
                  <Typography
                    style={{ color: collectionStatusData?.color ?? '#FF4D4F' }}
                    variant="subHeader-20"
                    weight="semi-bold"
                  >
                    {collectionStatusData?.title ?? (
                      <T _str="{num} tickets created" num={tickets?.length} />
                    )}
                  </Typography>
                </Space>
              </Col>
              <Col>
                <CloseOutlined onClick={onClose} />
              </Col>
            </Row>
          </Col>
          {collectionStatus && (
            <Col style={{ marginBottom: 16 }} xs={24}>
              <Typography
                style={{ color: 'rgba(38, 38, 38, 0.5)' }}
                variant="subHeader-16"
              >
                {collectionStatusMap[collectionStatus].description}
              </Typography>
            </Col>
          )}
        </>
      )}
      {tickets?.map((ticket, index) => {
        const createdAtCol = (
          <Col>
            <Typography style={{ color: 'rgba(38, 38, 38, 0.5)' }}>
              <T
                _str="Created at {createdAt}"
                createdAt={moment(ticket?.created_at).format('YYYY-MM-DD')}
              />
            </Typography>
          </Col>
        );

        return (
          <Col xs={24}>
            <Row
              style={{
                padding: 20,
                borderTop:
                  hideHeader && index === 0 ? 'none' : '1px solid #D9D9D9',
              }}
              align="middle"
              justify="space-between"
              wrap={false}
              gutter={4}
            >
              <Col flex="auto">
                <Row>
                  <Col xs={24}>
                    <Typography variant="subHeader-20">
                      {ticket?.ticket_type}
                    </Typography>
                  </Col>
                  {isMobile && createdAtCol}
                </Row>
              </Col>
              <Col>
                <Row gutter={20} align="middle">
                  {!isMobile && createdAtCol}
                  <Col>
                    <Button
                      onClick={() => {
                        history.push(
                          routingMap.app.containers[':ticketId'](ticket?.id)._
                        );
                      }}
                    >
                      <T _str="View ticket" />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default TicketsList;
