import React from 'react';
import { Row, Col, Button } from 'src/antd';
import { ExportOutlined, UserOutlined } from '@ant-design/icons';
import { T } from '@transifex/react';
import styled from 'styled-components';
import { createPrefixer } from '../../../../../utils';

const p = createPrefixer('wh-UI-Responsive-Sidebar-button-controls-');

const classes = {
  wrapper: p`wrapper`,
};

export type NavigationBarButtonControlsProps = {
  authenticated: boolean;
  settingsOnClick: () => void;
  logOutOnClick: () => void;
  logInOnClick: () => void;
};

const NavigationBarButtonControlsStyled = styled.div`
  padding-top: 1rem;

  @media screen and (min-width: 992px) {
    padding-top: 2rem;
  }
`;

const NavigationBarButtonControls: React.FC<
  NavigationBarButtonControlsProps
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
> = ({ authenticated, logInOnClick, logOutOnClick, settingsOnClick }) => {
  return (
    <NavigationBarButtonControlsStyled className={classes.wrapper}>
      <Row gutter={[8, 8]}>
        {!authenticated && (
          <Col span={24}>
            <Button
              icon={<UserOutlined style={{ marginRight: '0.5rem' }} />}
              onClick={logInOnClick}
              block
            >
              <span>
                <T _str="Log in with NemID" />
              </span>
            </Button>
          </Col>
        )}
        {authenticated && (
          <Col span={24}>
            <Button
              icon={<ExportOutlined style={{ marginRight: '0.5rem' }} />}
              onClick={logOutOnClick}
              type="default"
              block
            >
              <span>
                <T _str="Logout" />
              </span>
            </Button>
          </Col>
        )}
      </Row>
    </NavigationBarButtonControlsStyled>
  );
};

export default NavigationBarButtonControls;
