import axios from 'axios';

type GetCollectionsParams = {
  propertyId: string;
  token: string;
  fromDate?: string;
  toDate?: string;
  skip?: number;
  limit?: number;
};

export const GetCollections = (params: GetCollectionsParams) => {
  return axios.get(`property/${params.propertyId}/collection_log`, {
    headers: {
      'X-API-Key': params.token,
    },
    params: {
      from_date: params.fromDate,
      skip: params.skip,
      to_date: params.toDate,
      limit: params.limit,
    },
  });
};
