import { Alert, Modal } from 'antd';
import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import {
  TX_CONFIRM_DELETE_FILE,
  TX_DELETE_FILE,
} from 'src/storybook_transifex';

// @TODO - make this a reusable component
export const ConfirmDeleteFile = () => {
  return new Promise((res) => {
    Modal.confirm({
      content: (
        <div style={{ margin: '-8px -16px -14px 0px' }}>
          <div
            style={{
              padding: '16px 24px',
              background: '#111d2c',
              color: '#fff',
              fontSize: '16px',
              lineHeight: '22px',
              fontWeight: 600,
            }}
          >
            {TX_DELETE_FILE}
          </div>
          <div style={{ padding: '24px', borderBottom: '1px solid #f0f0f0' }}>
            <Alert
              message={TX_DELETE_FILE}
              description={TX_CONFIRM_DELETE_FILE}
              showIcon
              type="warning"
            />
          </div>
        </div>
      ),
      icon: <div />,
      closeIcon: <CloseOutlined style={{ color: '#fff' }} />,
      closable: true,
      maskClosable: true,
      maskStyle: { backgroundColor: 'rgba(0,0,0,0.45)' },
      bodyStyle: { padding: '0px 16px 8px 0px' },
      onOk: () => res(true),
      onCancel: () => res(false),
    });
  });
};
