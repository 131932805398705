import deepmerge from 'deepmerge';

import { array } from './array';

export const object = <TPair = Record<string, unknown>>(
  ...args: Array<
    Array<Partial<TPair>> | Partial<TPair> | undefined | false | null
  >
): TPair => {
  const all: Array<Partial<TPair>> = array<Partial<TPair>>(
    args as Partial<TPair>[]
  );
  return deepmerge.all(all) as unknown as TPair;
};
