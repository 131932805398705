import React from 'react';
import { Select, SelectProps } from 'antd';
import moment from 'moment';
import { DefaultOptionType } from 'antd/es/select';

export type TSelectMonthSimpleProps = {
  /** number of month starting from 0 as Jan. */
  value: number;
} & Omit<SelectProps, 'value' | 'options'>;

export const SelectMonthSimple = ({
  value,
  ...props
}: TSelectMonthSimpleProps) => {
  const monthsOptions: DefaultOptionType[] = moment
    .months()
    .map((month, i) => ({ label: month, value: i }));

  return (
    <Select
      {...props}
      style={{ minWidth: 100, ...props?.style }}
      options={monthsOptions}
      value={value}
    />
  );
};
