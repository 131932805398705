import { MutateFunction } from 'react-query';
import axios from 'axios';

type TVariables = {
  token: string;
  propertyId: string;
  verificationId: string;
  containers: Array<{ containerId: string; daysBefore: number }>;
};

export const mutateUpdateContainers: MutateFunction<
  unknown,
  unknown,
  TVariables
> = ({ token, propertyId, verificationId, containers }) => {
  return axios.post(
    `notifications/containers?verification_id=${verificationId}&property_id=${propertyId}`,
    containers.map(({ containerId, daysBefore }) => ({
      container_id: containerId,
      days_before: daysBefore,
    })),
    {
      headers: {
        'X-API-Key': token,
      },
    }
  );
};
