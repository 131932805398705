import React from 'react';
import { T } from '@transifex/react';
import { Typography } from 'src/ui/Typography';
import { Button, Col, Row, Select } from 'src/antd';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';

type TVal = {
  checked: boolean;
  daysBefore: number;
};

type ContainerListItemProps = {
  value?: TVal;
  onChange?: (v: TVal) => void;
  container: $TSFixMe;
};

const ContainerListItem = ({
  value,
  onChange,
  container,
}: ContainerListItemProps) => {
  const isMobile = useMediaQuery('(max-width: 800px)');
  const xsCol = isMobile ? 24 : undefined;

  return (
    <Row
      style={{
        minHeight: 96,
        background: value?.checked
          ? 'rgba(186, 231, 255, 0.3)'
          : 'rgba(15, 63, 107, 0.05)',
        padding: '28px 24px',
        borderRadius: 5,
      }}
      gutter={[8, 8]}
      justify="space-between"
      align="middle"
      wrap={isMobile}
    >
      <Col>
        <Row gutter={[0, 4]}>
          <Col xs={24}>
            <Typography
              style={{ color: '#0F3F6B' }}
              weight="semi-bold"
              variant="subHeader-20"
            >
              {container?.waste_fraction.name}
            </Typography>
          </Col>
          <Col xs={24}>
            <Row gutter={8}>
              <Col>
                <Typography variant="subHeader-16">
                  <T
                    _str="Pickup: {pickup}"
                    pickup={
                      <span style={{ fontWeight: 600 }}>
                        {container?.pickup_setting.name}
                      </span>
                    }
                  />
                </Typography>
              </Col>
              <Col>
                <Typography variant="subHeader-16">
                  <T
                    _str="Container type: {containerType}"
                    containerType={
                      <span style={{ fontWeight: 600 }}>
                        {container?.container_type.name}
                      </span>
                    }
                  />
                </Typography>
              </Col>
              <Col>
                <Typography variant="subHeader-16">
                  <T
                    _str="ID: {id}"
                    id={
                      <span style={{ fontWeight: 600 }}>
                        {container?.container_id}
                      </span>
                    }
                  />
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={xsCol}>
        {!value?.checked && (
          <Button
            style={{ width: '100%', fontSize: 16 }}
            onClick={() => {
              onChange?.({
                checked: true,
                daysBefore: 1,
              });
            }}
            type="primary"
          >
            <T _str="Subscribe" />
          </Button>
        )}
        {value?.checked && (
          <Row justify="center" wrap={isMobile} gutter={[8, 8]} align="middle">
            <Col
              style={{ textAlign: isMobile ? 'center' : 'unset' }}
              order={isMobile ? 2 : 1}
              xs={xsCol}
            >
              <Button
                style={{ fontSize: 16, padding: 0 }}
                onClick={() => {
                  onChange?.({
                    checked: false,
                    daysBefore: 1,
                  });
                }}
                type="link"
              >
                <T _str="Unsubscribe" />
              </Button>
            </Col>
            <Col order={isMobile ? 1 : 2} xs={xsCol}>
              <Select
                placeholder={<T _str="Days before" />}
                style={{
                  width: isMobile ? '100%' : '200px',
                  maxWidth: isMobile ? 'unset' : '200px',
                }}
                disabled={!value?.checked}
                value={value?.daysBefore}
                onChange={(v) => {
                  onChange?.({
                    checked: true,
                    daysBefore: v,
                  });
                }}
                options={Array.from({ length: 30 }).map((_, d) => ({
                  label: (
                    <>
                      {d + 1} {d > 0 ? <T _str="days" /> : <T _str="days" />}
                    </>
                  ),
                  value: d + 1,
                }))}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default ContainerListItem;
