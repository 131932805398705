import React from 'react';
import { createAntdIcon } from '../createIcon';

export const WhIconAntdRepairFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.79664 12.8694C2.48243 13.098 2.04234 13.0288 1.81363 12.7147C1.58487 12.4005 1.65416 11.9604 1.96831 11.7317C2.28252 11.5029 2.72261 11.5722 2.95132 11.8863C3.18008 12.2005 3.11079 12.6406 2.79664 12.8694ZM11.5268 5.6431L11.5409 3.80507L13.2155 2.58585C12.3424 2.22912 11.3103 2.3192 10.4918 2.91512C9.60028 3.56417 9.20195 4.63869 9.37229 5.65706C9.327 5.6821 9.28276 5.71012 9.23995 5.74134L1.55674 11.3349C0.99358 11.745 0.868305 12.5412 1.27828 13.1043C1.6883 13.6676 2.48453 13.7929 3.04774 13.3829L10.7309 7.78922C10.7737 7.75805 10.814 7.72456 10.8517 7.68921C11.7686 8.16405 12.9135 8.11506 13.805 7.46602C14.6235 6.87015 15.0262 5.91555 14.955 4.97507L13.2803 6.19429L11.5268 5.6431Z" />
    </svg>
  );
});
