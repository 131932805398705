import React from 'react';
import { T } from '@transifex/react';

const EMAIL = <T _str="Email only." />;
const REQUIRED = <T _str="Field required." />;
const MIN = (min: number) => <T _str="Must be higher than: {min}." min={min} />;
const ARRAY_ONE_ITEM_REQUIRED = <T _str="Select atleast one item" />;
const PHONE_NUMBER = <T _str="Please enter a valid phone number." />;
export const VALIDATION_MESSAGE = {
  EMAIL,
  REQUIRED,
  ARRAY_ONE_ITEM_REQUIRED,
  MIN,
  PHONE_NUMBER,
};
