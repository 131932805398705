import React from 'react';
import Notifications from './exact';
// eslint-disable-next-line import/no-cycle
import { Roles } from '../../../../routing/child-router-factory';
// eslint-disable-next-line import/no-cycle
import {
  CreateConfigFn,
  nonAuthenticatedRedirect,
  pathJoin,
} from '../../../../routing/utils';
// eslint-disable-next-line import/no-cycle
import NotificationFlow from './NotificationFlow';

export type Keys = `sms` | `email` | 'exact';

export const createRoutesMap = (prefix: string) => ({
  sms: pathJoin(prefix)('sms').chain,
  email: pathJoin(prefix)('email').chain,
});

const authenticatedUser: Roles = {
  and: ['authenticated'],
};

// create-config function.
export const createConfig: CreateConfigFn<Keys> = (map) => [
  {
    key: 'sms',
    fallback: (roles) => nonAuthenticatedRedirect(map)(roles),
    roles: authenticatedUser,
    // eslint-disable-next-line react/jsx-no-useless-fragment
    children: <NotificationFlow mode="sms" />,
    path: map.app.notifications.sms._,
  },
  {
    key: 'email',
    fallback: (roles) => nonAuthenticatedRedirect(map)(roles),
    roles: authenticatedUser,
    // eslint-disable-next-line react/jsx-no-useless-fragment
    children: <NotificationFlow mode="email" />,
    path: map.app.notifications.email._,
  },
  {
    key: 'exact',
    fallback: (roles) => nonAuthenticatedRedirect(map)(roles),
    roles: authenticatedUser,
    // eslint-disable-next-line react/jsx-no-useless-fragment
    children: <Notifications />,
    path: map.app.notifications._,
  },
];
