import styled from 'styled-components';

export const StyledCalendar = styled.div`
  .ant-picker-calendar-header {
    display: none;
  }

  .dot-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .dot {
    border: #d9d9d9 solid;
    border-radius: 50%;
    margin-right: 0.25rem;
    height: 6px;
    width: 6px;
    background: #d9d9d9;
  }

  .print-info {
    display: none;
  }

  .event-text {
    font-size: 14px;
  }

  @media print {
    height: 100%;
    width: 100%;

    .event-text {
      font-size: 10px;
    }

    .ant-badge-status-dot {
      display: inherit !important;
    }
    .anticon {
      display: none;
    }

    .ant-badge-status-text {
      font-size: 10px;
    }

    .ant-picker-calendar-full
      .ant-picker-panel
      .ant-picker-cell-selected
      .ant-picker-calendar-date
      .ant-picker-calendar-date-value,
    .ant-picker-calendar-full
      .ant-picker-panel
      .ant-picker-cell-selected:hover
      .ant-picker-calendar-date
      .ant-picker-calendar-date-value,
    .ant-picker-calendar-full
      .ant-picker-panel
      .ant-picker-cell-selected
      .ant-picker-calendar-date-today
      .ant-picker-calendar-date-value,
    .ant-picker-calendar-full
      .ant-picker-panel
      .ant-picker-cell-selected:hover
      .ant-picker-calendar-date-today
      .ant-picker-calendar-date-value {
      color: rgba(0, 0, 0, 0.85);
    }

    .ant-picker-calendar-full
      .ant-picker-panel
      .ant-picker-calendar-date-today {
      border-color: #f0f0f0;
    }

    .print-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;
