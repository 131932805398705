/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import { useTX } from '@transifex/react';
import { Dictionary, chunk, flatten, groupBy } from 'lodash';
import { useCompany } from 'src/app/Company.store';
import { monthSelectValueToMomentRange } from '../../utils';
import { GetCollections } from '../../components/main/routes/collections/exact/api';
import { callGetOneProperty } from '../../api/property';
import Page from './Page';
import {
  CALENDAR_LIST_HEIGHT,
  CALENDAR_ROW_GAP,
  CELL_HEIGHT,
  COLUMNS,
  WASTE_FRACTION_COL_HEIGHT,
} from './contants';

const Browserless = () => {
  const { data: companyData } = useCompany();
  const tx = useTX();

  const [height, setHeight] = useState<number | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const urlSearchParams = new URLSearchParams(window.location.search);

  const params = Object.fromEntries(urlSearchParams.entries());
  const dateRange = `${params.from_date}, ${params.to_date}`;
  const key = params.api_key;
  const propertyId = params.property_id;

  const { language } = params;

  // remember to set the footer container ref to ref
  const footer = companyData.crm_pdf_footer ? (
    <div
      ref={ref}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: companyData.crm_pdf_footer }}
    />
  ) : undefined;

  if (language) {
    moment.locale(language);
    tx.setCurrentLocale(language);
  }

  const dates = monthSelectValueToMomentRange(dateRange);

  const { data, isLoading: isLoadingCollections } = useQuery(
    [
      'get-property-collections',
      propertyId,
      moment(dates[0]).format(),
      moment(dates[1]).format(),
    ],
    () =>
      GetCollections({
        token: key,
        propertyId,
        fromDate: moment(dates[0]).format('YYYY-MM-DD'),
        toDate: moment(dates[1]).format('YYYY-MM-DD'),
      })
  );

  useEffect(() => {
    setHeight(ref.current?.clientHeight ?? null);
  }, [isLoadingCollections]);

  const { data: propertyData, isLoading: isLoadingProperty } = useQuery(
    ['get-property-browserless', propertyId, key],
    () => callGetOneProperty({ token: key, id: propertyId })
  );

  const collections = data?.data;

  const collectionsGroupedByDate = groupBy(collections, 'date');

  // Here we transform the object so we reduce lets say
  // 100 simillar collections on a date to 3 + 9 more
  const simillarCollectionsGroupedByDate = Object.entries(
    collectionsGroupedByDate
  ).reduce(
    (acc, [collectionsGroupedByDateKey, collectionsGroupedByDateValue]) => {
      const simillarCollections = groupBy(
        collectionsGroupedByDateValue,
        (c) => [c?.date, c?.container?.waste_fraction?.id]
      );

      return {
        ...acc,
        [collectionsGroupedByDateKey]: Object.values(
          simillarCollections
        ).reduce((prevCollections, collectionGrouped) => {
          return [
            ...prevCollections,
            {
              ...collectionGrouped[0],
              moreCount: collectionGrouped.length,
            },
          ];
        }, []),
      };
    },
    {}
  ) as Dictionary<$TSFixMe[]>;

  const monthsArray = useMemo(() => {
    const betweenMonths: $TSFixMe[] = [];

    const startDate = dates[0];
    const endDate = dates[1];

    const date = startDate.clone().startOf('month');

    while (date < endDate.endOf('month')) {
      betweenMonths.push({
        month: date.month(),
        dates: Array.from({ length: date.daysInMonth() }, () => {
          const currentDate = date.clone().format('YYYY-MM-DD');
          date.add(1, 'day');

          return {
            date: currentDate,
            collections: simillarCollectionsGroupedByDate[currentDate] || [],
          };
        }),
      });
    }

    return betweenMonths;
  }, [dates, simillarCollectionsGroupedByDate]);

  // This stores months array but splitted.
  // If any month will exceed the height, we will split it.
  const monthsArrayNormalized: $TSFixMe = [];

  const ADJUSTED_CALENDAR_LIST_HEIGHT_WITH_FOOTER =
    CALENDAR_LIST_HEIGHT - (footer ? (height ?? 0) + CALENDAR_ROW_GAP * 2 : 0);

  for (const month of monthsArray) {
    const splitIndexes: number[] = [];
    const splitArray = [];
    let previousLength = 0;
    let startIdx = 0;
    // if we split, then the new col will have some offset
    // we need to subtract that next time.
    let offsetNewSplit = 0;

    for (let i = 0; i < month.dates.length; i++) {
      const date = month.dates[i];

      const colCount = date.collections.length || 1;
      // 3 waste fractions fit one row that's why we subtract 3 from total count
      const celLHeight =
        CELL_HEIGHT +
        Math.floor((colCount - 1) / 3) * WASTE_FRACTION_COL_HEIGHT;
      const cumalativeColLength = celLHeight + previousLength;

      if (
        cumalativeColLength >
        ADJUSTED_CALENDAR_LIST_HEIGHT_WITH_FOOTER * (splitIndexes.length + 1) -
          offsetNewSplit
      ) {
        splitIndexes.push(i);
        offsetNewSplit =
          cumalativeColLength - ADJUSTED_CALENDAR_LIST_HEIGHT_WITH_FOOTER;
      }

      previousLength = cumalativeColLength;
    }

    for (const indx of splitIndexes) {
      splitArray.push({
        month: month.month,
        dates: month.dates.slice(startIdx, indx),
      });
      startIdx = indx;
    }

    splitArray.push({ month: month.month, dates: month.dates.slice(startIdx) });

    monthsArrayNormalized.push(...splitArray);
  }

  // Now we are going to split by year.
  // we want to make seperate arrays of seperate years
  const splitIndexesMonths: number[] = [];
  const splitMonthArrayNormalized = [];
  let startIdx = 0;

  for (let i = 1; i < monthsArrayNormalized.length; i++) {
    if (monthsArrayNormalized[i].month < monthsArrayNormalized[i - 1].month) {
      splitIndexesMonths.push(i);
    }
  }

  for (const indx of splitIndexesMonths) {
    splitMonthArrayNormalized.push(monthsArrayNormalized.slice(startIdx, indx));
    startIdx = indx;
  }

  splitMonthArrayNormalized.push(monthsArrayNormalized.slice(startIdx));

  const chunkedMonthsArray = flatten(
    splitMonthArrayNormalized.map((monthArrayNormalized) =>
      chunk(monthArrayNormalized, COLUMNS)
    )
  );

  if (
    isLoadingProperty ||
    isLoadingCollections ||
    (footer && height === null)
  ) {
    return (
      <>
        <p>Loading...</p>
        <p>Using Footer:</p>
        {footer}
      </>
    );
  }

  return (
    <>
      {chunkedMonthsArray.map((c, i) => (
        <Page
          calendarListHeight={ADJUSTED_CALENDAR_LIST_HEIGHT_WITH_FOOTER}
          footer={footer}
          propertyAddress={(propertyData as $TSFixMe)?.data?.location?.name}
          data={c}
          total={chunkedMonthsArray.length}
          current={i + 1}
        />
      ))}
    </>
  );
};

export default () => {
  useEffect(() => {
    window.usingBrowerserLess = true;
  }, []);

  return <Browserless />;
};
