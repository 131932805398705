import React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import { Sidebar } from '../layout/sidebar';
// eslint-disable-next-line import/no-cycle
import MainRouter from './mainRouter';
import { Content } from '../layout';

const PageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 100% !important;
  max-height: 100% !important;
  position: relative;
  flex-direction: column;
  zindex: 1;

  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

// eslint-disable-next-line import/no-cycle
export { createRouteMap } from './route-config';

const Main = () => {
  return (
    <PageContainer>
      <div>
        <Sidebar />
      </div>
      <Content>
        <MainRouter />
      </Content>
    </PageContainer>
  );
};

export default Main;
