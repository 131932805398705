import {
  collectionStatusApiConstMap,
  TCollectionStatus,
} from 'src/api/consts/CollectionStatus';

const hasTickets = (collection: $TSFixMe) => {
  return (collection?.pickup_order?.created_tickets ?? []).length !== 0;
};

const getStatus = (collection: $TSFixMe): TCollectionStatus => {
  const collHasTickets = hasTickets(collection);
  const collectionStatus = collectionStatusApiConstMap[collection.status];

  if (collHasTickets && collectionStatus === 'COMPLETE') {
    return 'COMPLETE_WITH_TICKET';
  }

  return collectionStatus;
};

export const apiHelperCollections = { hasTickets, getStatus };
