import { useQuery, MutateFunction } from 'react-query';
import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { useGetToken } from '../../config';

type TCallVariables = {
  token: string;
  propertyId: string;
  ticketTypeId: string;
};

export const call: MutateFunction<unknown, unknown, TCallVariables> = ({
  token,
  propertyId,
  ticketTypeId,
}) =>
  axios.get(
    `property/${propertyId}/ticket-type/${ticketTypeId}/available-dates`,
    {
      headers: {
        'X-API-Key': token,
      },
      params: {
        limit: 100,
      },
    }
  );

const K_GET_PROPERTY_CONTAINERS = 'K_GET_PROPERTY_CONTAINERS';

export const useGetPropertyTicketTypeAvailableDates = (
  vars: Omit<TCallVariables, 'token'>
) => {
  const token = useGetToken();
  return useQuery<$TSFixMe>([K_GET_PROPERTY_CONTAINERS, vars], () =>
    call({ token, ...vars })
  );
};
