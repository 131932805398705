import React, { CSSProperties, useEffect, useState } from 'react';
import { Select } from 'src/antd';
import { useLanguages, useLocale, useTX } from '@transifex/react';
import moment from 'moment';
import { useCompany } from 'src/app/Company.store';
import { useUserMaybe, useUserStore } from 'src/app/User.store';
import { handleApiChange } from './shared/FormChangeApiUrl';

export function LanguagePicker({ style }: { style?: CSSProperties }) {
  const { data: company } = useCompany();
  const userMaybe = useUserMaybe();
  const language = company?.default_language?.toLowerCase();
  const languages = useLanguages();
  const tx = useTX();
  const locale = useLocale();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const savedLocale = userMaybe?.language?.toLowerCase() || language || 'en';
    tx.setCurrentLocale(savedLocale);
    moment.locale(savedLocale);
  }, [language, tx, userMaybe?.language]);

  const handleChange = async (v: string) => {
    setLoading(true);
    if (userMaybe) {
      // if there is a user then change the user language there.
      await useUserStore.getState().update({ language: v });
    }
    tx.setCurrentLocale(v);
    setLoading(false);
  };

  return (
    <Select
      onClick={handleApiChange}
      style={style}
      className="language-picker"
      onChange={handleChange}
      options={languages.map(({ code, localized_name }: $TSFixMe) => ({
        value: code,
        label: localized_name,
      }))}
      value={locale}
      loading={loading}
    />
  );
}
