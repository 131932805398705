import Color from 'color';

import {
  blue100,
  blue1000,
  blue200,
  blue300,
  blue500,
  blue700,
  cyan100,
  cyan200,
  cyan400,
  cyan600,
  cyan700,
  cyan800,
  cyan900,
  geekBlue100,
  geekBlue1000,
  geekBlue200,
  geekBlue300,
  geekBlue500,
  geekBlue700,
  gold1000,
  gold200,
  gold400,
  gold500,
  gold600,
  gold900,
  gray100,
  gray300,
  gray500,
  gray600,
  gray800,
  gray900,
  green100,
  green200,
  green300,
  green600,
  green700,
  green800,
  green900,
  lime100,
  lime200,
  lime300,
  lime600,
  lime700,
  lime800,
  lime900,
  magenta100,
  magenta200,
  magenta400,
  magenta600,
  magenta700,
  magenta800,
  magenta900,
  orange1000,
  orange200,
  orange400,
  orange600,
  orange800,
  purple100,
  purple200,
  purple300,
  purple600,
  purple700,
  purple800,
  red100,
  red200,
  red500,
  red600,
  red700,
  red800,
  volcano1000,
  volcano200,
  volcano400,
  volcano600,
  volcano800,
  yellow1000,
  yellow200,
  yellow400,
  yellow500,
  yellow600,
  yellow900,
} from 'src/theme/colors';
import { TagColorType } from './types';

function checkIsPresetColor(color?: TagColorType) {
  const isPreset = [
    'red',
    'orange',
    'volcano',
    'yellow',
    'gold',
    'gray',
    'blue',
    'green',
    'darkGray',
    'purple',
    'cyan',
    'pink',
    'lime',
    'geekblue',
  ].includes(color || '');
  if (!color || isPreset) {
    return true;
  }

  try {
    const col = Color(color);
    return !col;
  } catch (e) {
    return true;
  }
}

export const generateBoldHoverStyles = (color?: TagColorType) => {
  const isPreset = checkIsPresetColor(color);

  if (!isPreset) {
    const darkenColor = Color(color).darken(0.3).hex();
    return `
      &:hover {
        background-color: ${darkenColor};
        border-color: ${darkenColor};
        color: ${gray100};
      }
    `;
  }

  switch (color) {
    case 'red':
      return `
        &:hover {
          background-color: ${red700};
          border-color: ${red700};
          color: ${red100};
        }
      `;
    case 'orange':
      return `
        &:hover {
          background-color: ${orange600};
          border-color: ${orange600};
          color: ${orange1000};
        }
      `;
    case 'volcano':
      return `
        &:hover {
          background-color: ${volcano600};
          border-color: ${volcano600};
          color: ${volcano1000};
        }
      `;
    case 'yellow':
      return `
        &:hover {
          background-color: ${yellow600};
          border-color: ${yellow600};
          color: ${yellow1000};
        }
      `;
    case 'gold':
      return `
        &:hover {
          background-color: ${gold600};
          border-color: ${gold600};
          color: ${gold1000};
        }
      `;
    case 'blue':
      return `
        &:hover {
          background-color: ${blue700};
          border-color: ${blue700};
          color: ${blue100};
        }
      `;
    case 'geekblue':
      return `
        &:hover {
          background-color: ${geekBlue700};
          border-color: ${geekBlue700};
          color: ${geekBlue100};
        }
      `;
    case 'green':
      return `
        &:hover {
          background-color: ${green700};
          border-color: ${green700};
          color: ${green100};
        }
      `;
    case 'lime':
      return `
        &:hover {
          background-color: ${lime700};
          border-color: ${lime700};
          color: ${lime100};
        }
      `;
    case 'darkGray':
      return `
        &:hover {
          background-color: ${gray900};
          border-color: ${gray900};
          color: ${gray100};
        }
      `;
    case 'purple':
      return `
        &:hover {
          background-color: ${purple700};
          border-color: ${purple700};
          color: ${purple100};
        }
      `;
    case 'cyan':
      return `
        &:hover {
          background-color: ${cyan900};
          border-color: ${cyan900};
          color: ${cyan100};
        }
      `;
    case 'pink':
      return `
        &:hover {
          background-color: ${magenta900};
          border-color: ${magenta900};
          color: ${magenta100};
        }
      `;
    case 'gray':
    default:
      return `
        &:hover {
          background-color: ${gray500};
          border-color: ${gray500};
          color: ${gray900};
        }
      `;
  }
};

export const generateBoldStyles = (color?: TagColorType) => {
  const isPreset = checkIsPresetColor(color);

  if (!isPreset) {
    return `
      background-color: ${color};
      border-color: ${color};
      color: ${gray100};
    `;
  }

  switch (color) {
    case 'red':
      return `
        background-color: ${red500};
        border-color: ${red500};
        color: ${gray100};
      `;
    case 'orange':
      return `
        background-color: ${orange400};
        border-color: ${orange400};
        color: ${orange1000};
      `;
    case 'volcano':
      return `
        background-color: ${volcano400};
        border-color: ${volcano400};
        color: ${volcano1000};
      `;
    case 'yellow':
      return `
        background-color: ${yellow500};
        border-color: ${yellow500};
        color: ${yellow1000};
      `;
    case 'gold':
      return `
        background-color: ${gold500};
        border-color: ${gold500};
        color: ${gold1000};
      `;
    case 'blue':
      return `
        background-color: ${blue500};
        border-color: ${blue500};
        color: ${blue100};
      `;
    case 'geekblue':
      return `
        background-color: ${geekBlue500};
        border-color: ${geekBlue500};
        color: ${geekBlue100};
      `;
    case 'green':
      return `
        background-color: ${green600};
        border-color: ${green600};
        color: ${green100};
      `;
    case 'line':
      return `
        background-color: ${lime600};
        border-color: ${lime600};
        color: ${lime100};
      `;
    case 'darkGray':
      return `
        background-color: ${gray800};
        border-color: ${gray800};
        color: ${gray100};
      `;
    case 'purple':
      return `
        background-color: ${purple600};
        border-color: ${purple600};
        color: ${purple100};
      `;
    case 'cyan':
      return `
        background-color: ${cyan700};
        border-color: ${cyan700};
        color: ${cyan100};
      `;
    case 'pink':
      return `
        background-color: ${magenta700};
        border-color: ${magenta700};
        color: ${magenta100};
      `;
    case 'gray':
    default:
      return `
        background-color: ${gray300};
        border-color: ${gray300};
        color: ${gray900};
      `;
  }
};

export const generateSubtleHoverStyles = (color?: TagColorType) => {
  const isPreset = checkIsPresetColor(color);

  if (!isPreset) {
    const lightenColor = Color(color).lighten(0.6).hex();
    const darkenColor = Color(color).darken(0.6).hex();
    return `
      &:hover {
        background-color: ${lightenColor};
        border-color: ${lightenColor};
        color: ${darkenColor};
      }
    `;
  }

  switch (color) {
    case 'red':
      return `
        &:hover {
          background-color: ${red200};
          border-color: ${red200};
          color: ${red700};
        }
      `;
    case 'orange':
      return `
        &:hover {
          background-color: ${orange400};
          border-color: ${orange400};
          color: ${orange1000};
        }
      `;
    case 'volcano':
      return `
        &:hover {
          background-color: ${volcano400};
          border-color: ${volcano400};
          color: ${volcano1000};
        }
      `;
    case 'yellow':
      return `
        &:hover {
          background-color: ${yellow400};
          border-color: ${yellow400};
          color: ${yellow1000};
        }
      `;
    case 'gold':
      return `
        &:hover {
          background-color: ${gold400};
          border-color: ${gold400};
          color: ${gold1000};
        }
      `;
    case 'blue':
      return `
        &:hover {
          background-color: ${blue300};
          border-color: ${blue300};
          color: ${blue1000};
        }
      `;
    case 'geekblue':
      return `
        &:hover {
          background-color: ${geekBlue300};
          border-color: ${geekBlue300};
          color: ${geekBlue1000};
        }
      `;
    case 'green':
      return `
        &:hover {
          background-color: ${green300};
          border-color: ${green300};
          color: ${green900};
        }
      `;
    case 'lime':
      return `
        &:hover {
          background-color: ${lime300};
          border-color: ${lime300};
          color: ${lime900};
        }
      `;
    case 'purple':
      return `
        &:hover {
          background-color: ${purple300};
          border-color: ${purple300};
          color: ${purple800};
        }
      `;
    case 'darkGray':
      return `
        &:hover {
          background-color: ${gray600};
          border-color: ${gray600};
          color: ${gray900};
        }
      `;
    case 'cyan':
      return `
        &:hover {
          background-color: ${cyan400};
          border-color: ${cyan400};
          color: ${cyan900};
        }
      `;
    case 'pink':
      return `
        &:hover {
          background-color: ${magenta400};
          border-color: ${magenta400};
          color: ${magenta900};
        }
      `;
    case 'gray':
    default:
      return `
        &:hover {
          background-color: ${gray500};
          border-color: ${gray500};
          color: ${gray900};
        }
      `;
  }
};

export const generateSubtleStyles = (color?: TagColorType) => {
  const isPreset = checkIsPresetColor(color);

  if (!isPreset) {
    const lightenColor = Color(color).lighten(0.7).hex();
    const darkenColor = Color(color).darken(0.6).hex();
    return `
      background-color: ${lightenColor};
      border-color: ${lightenColor};
      color: ${darkenColor};
    `;
  }

  switch (color) {
    case 'red':
      return `
        background-color: ${red100};
        border-color: ${red100};
        color: ${red800};
      `;
    case 'orange':
      return `
        background-color: ${orange200};
        border-color: ${orange200};
        color: ${orange1000};
      `;
    case 'volcano':
      return `
        background-color: ${volcano200};
        border-color: ${volcano200};
        color: ${volcano1000};
      `;
    case 'yellow':
      return `
        background-color: ${yellow200};
        border-color: ${yellow200};
        color: ${yellow1000};
      `;
    case 'gold':
      return `
        background-color: ${gold200};
        border-color: ${gold200};
        color: ${gold1000};
      `;
    case 'blue':
      return `
        background-color: ${blue200};
        border-color: ${blue200};
        color: ${blue1000};
      `;
    case 'geekblue':
      return `
        background-color: ${geekBlue200};
        border-color: ${geekBlue200};
        color: ${geekBlue1000};
      `;
    case 'green':
      return `
        background-color: ${green200};
        border-color: ${green200};
        color: ${green900};
      `;
    case 'lime':
      return `
        background-color: ${lime200};
        border-color: ${lime200};
        color: ${lime900};
      `;
    case 'purple':
      return `
        background-color: ${purple200};
        border-color: ${purple200};
        color: ${purple800};
      `;
    case 'darkGray':
      return `
        background-color: ${gray500};
        border-color: ${gray500};
        color: ${gray900};
      `;
    case 'cyan':
      return `
        background-color: ${cyan200};
        border-color: ${cyan200};
        color: ${cyan900};
      `;
    case 'pink':
      return `
        background-color: ${magenta200};
        border-color: ${magenta200};
        color: ${magenta900};
      `;
    case 'gray':
    default:
      return `
        background-color: ${gray300};
        border-color: ${gray300};
        color: ${gray900};
      `;
  }
};

export const generateMinimalHoverStyles = (color?: TagColorType) => {
  const isPreset = checkIsPresetColor(color);

  if (!isPreset) {
    const lightenColor = Color(color).lighten(0.9).hex();
    return `
      &:hover {
        background-color: ${lightenColor};
        border-color: ${color};
        color: ${color};
      }
    `;
  }

  switch (color) {
    case 'red':
      return `
        &:hover {
          background-color: ${red100};
          border-color: ${red600};
          color: ${red600};
        }
      `;
    case 'orange':
      return `
        &:hover {
          background-color: ${orange200};
          border-color: ${orange600};
          color: ${orange800};
        }
      `;
    case 'volcano':
      return `
        &:hover {
          background-color: ${volcano200};
          border-color: ${volcano600};
          color: ${volcano800};
        }
      `;
    case 'yellow':
      return `
        &:hover {
          background-color: ${yellow200};
          border-color: ${yellow600};
          color: ${yellow900};
        }
      `;
    case 'gold':
      return `
        &:hover {
          background-color: ${gold200};
          border-color: ${gold600};
          color: ${gold900};
        }
      `;
    case 'blue':
      return `
        &:hover {
          background-color: ${blue100};
          border-color: ${blue300};
          color: ${blue700};
        }
      `;
    case 'geekblue':
      return `
        &:hover {
          background-color: ${geekBlue100};
          border-color: ${geekBlue300};
          color: ${geekBlue700};
        }
      `;
    case 'green':
      return `
        &:hover {
          background-color: ${green200};
          border-color: ${green300};
          color: ${green800};
        }
      `;
    case 'lime':
      return `
        &:hover {
          background-color: ${lime200};
          border-color: ${lime300};
          color: ${lime800};
        }
      `;
    case 'purple':
      return `
        &:hover {
          background-color: ${purple100};
          border-color: ${purple300};
          color: ${purple700};
        }
      `;
    case 'darkGray':
      return `
        &:hover {
          background-color: ${gray300};
          border-color: ${gray900};
          color: ${gray900};
        }
      `;
    case 'cyan':
      return `
        &:hover {
          background-color: ${cyan100};
          border-color: ${cyan600};
          color: ${cyan800};
        }
      `;
    case 'pink':
      return `
        &:hover {
          background-color: ${magenta100};
          border-color: ${magenta600};
          color: ${magenta800};
        }
      `;
    case 'gray':
    default:
      return `
        &:hover {
          background-color: ${gray300};
          border-color: ${gray500};
          color: ${gray900};
        }
      `;
  }
};

export const generateMinimalStyles = (color?: TagColorType) => {
  const isPreset = checkIsPresetColor(color);

  if (!isPreset) {
    return `
      background-color: transparent;
      border-color: ${color};
      color: ${color};
    `;
  }

  switch (color) {
    case 'red':
      return `
        background-color: ${gray100};
        border-color: ${red600};
        color: ${red600};
      `;
    case 'orange':
      return `
        background-color: ${gray100};
        border-color: ${orange600};
        color: ${orange800};
      `;
    case 'volcano':
      return `
        background-color: ${gray100};
        border-color: ${volcano600};
        color: ${volcano800};
      `;
    case 'yellow':
      return `
        background-color: ${gray100};
        border-color: ${yellow600};
        color: ${yellow900};
      `;
    case 'gold':
      return `
        background-color: ${gray100};
        border-color: ${gold600};
        color: ${gold900};
      `;
    case 'blue':
      return `
        background-color: ${gray100};
        border-color: ${blue300};
        color: ${blue700};
      `;
    case 'geekblue':
      return `
        background-color: ${gray100};
        border-color: ${geekBlue300};
        color: ${geekBlue700};
      `;
    case 'green':
      return `
        background-color: ${gray100};
        border-color: ${green300};
        color: ${green800};
      `;
    case 'lime':
      return `
        background-color: ${gray100};
        border-color: ${lime300};
        color: ${lime800};
      `;
    case 'purple':
      return `
        background-color: ${gray100};
        border-color: ${purple300};
        color: ${purple700};
      `;
    case 'darkGray':
      return `
        background-color: ${gray100};
        border-color: ${gray900};
        color: ${gray900};
      `;
    case 'cyan':
      return `
        background-color: ${cyan600};
        border-color: ${cyan800};
        color: ${cyan800};
      `;
    case 'pink':
      return `
        background-color: ${magenta600};
        border-color: ${magenta800};
        color: ${magenta800};
      `;
    case 'gray':
    default:
      return `
        background-color: ${gray100};
        border-color: ${gray500};
        color: ${gray900};
      `;
  }
};
