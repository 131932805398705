import { useQuery, MutateFunction } from 'react-query';
import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { useGetToken } from '../../config';

type TCallVariables = {
  token: string;
  id: string;
};

type TVariables = {
  includePrices?: boolean;
  includePreferredDates?: boolean;
  includeActiveAgreement?: boolean;
  includeNextCollectionDate?: boolean;
  pricesForTicketType?: string;
  includeTicketTypes?: boolean;
  limit?: number;
};

export const call: MutateFunction<
  unknown,
  unknown,
  TCallVariables & TVariables
> = ({
  id,
  token,
  includePrices,
  includePreferredDates,
  includeActiveAgreement,
  includeNextCollectionDate,
  pricesForTicketType,
  includeTicketTypes,
  limit,
}) =>
  axios.get(`property/${id}/property-containers`, {
    headers: {
      'X-API-Key': token,
    },
    params: {
      include_prices: includePrices,
      include_preferred_dates: includePreferredDates,
      include_active_agreement: includeActiveAgreement,
      include_next_collection_date: includeNextCollectionDate,
      prices_for_ticket_type_id: pricesForTicketType,
      include_ticket_types: includeTicketTypes,
      limit: limit || 100,
    },
  });

const K_GET_PROPERTY_CONTAINERS = 'K_GET_PROPERTY_CONTAINERS';

export const useGetPropertyContainers = (
  propertyId: string,
  params?: TVariables
) => {
  const token = useGetToken();
  return useQuery<$TSFixMe>(
    [K_GET_PROPERTY_CONTAINERS, propertyId, { ...(params || {}) }],
    () => call({ token, id: propertyId, ...params })
  );
};

type TCallAgreementsVariables = {
  token: string;
  id: string;
};

export const callAgreements: MutateFunction<
  unknown,
  unknown,
  TCallAgreementsVariables & { limit?: number }
> = ({ id, token, limit }) =>
  axios.get(`property/${id}/property-agreements`, {
    headers: {
      'X-API-Key': token,
    },
    params: {
      limit: limit || 100,
    },
  });

const K_GET_PROPERTY_AGREEMENT = 'K_GET_PROPERTY_AGREEMENTS';

export const useGetPropertyAgreements = (
  propertyId: string,
  params?: { limit?: number }
) => {
  const token = useGetToken();
  return useQuery<$TSFixMe>(
    [K_GET_PROPERTY_AGREEMENT, propertyId, { ...(params || {}) }],
    () => callAgreements({ token, id: propertyId, ...params })
  );
};
