import React, { ReactNode } from 'react';
import { ModularSelect } from '../../../../../../shared';

export type TSelectableItemProps = {
  addonPre?: ReactNode;
  title?: ReactNode;
  addonCenter?: ReactNode;
  addonEnd?: ReactNode;
  addonContent?: ReactNode;
  checked: boolean;
};

export const SelectableItem = ({
  addonCenter,
  addonContent,
  addonEnd,
  addonPre,
  title,
  checked,
}: TSelectableItemProps) => {
  return (
    <ModularSelect
      title={{
        type: title,
      }}
      checkbox={addonPre}
      checked={checked}
      endContent={addonEnd}
      centerContent={addonCenter}
      collapseContent={addonContent}
    />
  );
};
