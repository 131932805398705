export const CALENDAR_LIST_HEIGHT = 900;
export const CALEANDAR_LIST_WIDTH = 750;
export const COLUMNS = 6;
export const HEADER_DATE_FORMAT = 'MMMM';
export const CELL_HEIGHT = 25;
export const WASTE_FRACTION_COL_HEIGHT = 20;
export const CALENDAR_ROW_GAP = 16;

// derived
export const COLUMN_WIDTH = CALEANDAR_LIST_WIDTH / COLUMNS;
export const LAST_COLUMN_INDEX = COLUMNS - 1;
