import React from 'react';
import { createPortal } from 'react-dom';
import createStore from 'zustand';
import create from 'zustand/vanilla';
import { produce } from 'immer';
import { uniqueId } from 'lodash';
import { createRoot } from 'react-dom/client';
import Modal, { IModal } from './Modal';

type Store = {
  jsxMap: Record<string, JSX.Element>;
};

const store = create<Store>(() => ({
  jsxMap: {},
}));

type ModalProps = Omit<IModal, 'onClose'> & { onClose?: () => void };

export type ResolveReject = {
  resolve: (val: unknown) => void;
  reject: (reason?: $TSFixMe) => void;
};

type BaseModalProps = Omit<ModalProps, 'visible' | 'footer'>;

type ChildModalProps = BaseModalProps & {
  children: (args: ResolveReject) => JSX.Element;
  _internal: ResolveReject;
  modalPropsWithActions?: (args: ResolveReject) => BaseModalProps;
};

const CANCEL = `CANCEL`;

const ChildModal = ({
  children,
  _internal,
  modalPropsWithActions,
  ...rest
}: ChildModalProps) => {
  return (
    <Modal
      {...rest}
      onClose={() => _internal.reject(CANCEL)}
      {...modalPropsWithActions?.(_internal)}
      visible
      footer={null}
    >
      {children(_internal)}
    </Modal>
  );
};

export type TAsyncModalArgs = Omit<ChildModalProps, '_internal'>;

const asyncModal = (props: TAsyncModalArgs) => {
  const { setState } = store;
  const myId = uniqueId();
  const div = document.createElement('div');
  document.body.appendChild(div);
  const root = createRoot(div);

  function destory() {
    setState(
      produce((draft) => {
        // eslint-disable-next-line no-param-reassign, react/jsx-no-useless-fragment
        draft.jsxMap[myId] = <></>;
      })
    );
    root.unmount();
    if (div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }

  return new Promise((res, rej) => {
    setState(
      produce((draft) => {
        // eslint-disable-next-line no-param-reassign
        draft.jsxMap[myId] = createPortal(
          <ChildModal
            {...props}
            _internal={{
              resolve: (val) => {
                destory();
                res(val);
              },
              reject: (err) => {
                destory();
                rej(err);
              },
            }}
          />,
          div
        );
      })
    );
  });
};

asyncModal.CANCEL = CANCEL;

export { asyncModal };

const useStore = createStore(store);

export const AsyncModalPortal = () => {
  const jsxMap = useStore((state) => state.jsxMap);

  return <>{Object.values(jsxMap)}</>;
};
