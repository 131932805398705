import React from 'react';
import { createAntdIcon } from '../createIcon';

export const WhIconAntdHardMetalFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.58188 4.24881C10.5144 3.44642 11.8205 2.35255 11.499 1.8056C11.1775 1.25865 9.35021 1.46573 7.41765 2.26812C5.48508 3.07052 4.17905 4.16438 4.50055 4.71133C4.82204 5.25829 6.64932 5.05121 8.58188 4.24881Z" />
      <path d="M11.5481 10.7276C11.5481 10.6723 11.5128 10.6276 11.4692 10.6276H4.53137C4.48782 10.6276 4.45252 10.6723 4.45252 10.7276V11.3451C4.45252 11.4003 4.48782 11.4451 4.53137 11.4451H11.4692C11.5128 11.4451 11.5481 11.4003 11.5481 11.3451V10.7276Z" />
      <path d="M11.5481 11.8508C11.5481 11.7956 11.5128 11.7508 11.4692 11.7508H4.53137C4.48782 11.7508 4.45252 11.7956 4.45252 11.8508V14.4C4.45252 14.4552 4.48782 14.5 4.53137 14.5H11.4692C11.5128 14.5 11.5481 14.4552 11.5481 14.4V11.8508Z" />
      <path d="M11.5481 9.60433C11.5481 9.5491 11.5128 9.50433 11.4692 9.50433H4.53137C4.48782 9.50433 4.45252 9.5491 4.45252 9.60433V10.2218C4.45252 10.2771 4.48782 10.3218 4.53137 10.3218H11.4692C11.5128 10.3218 11.5481 10.2771 11.5481 10.2218V9.60433Z" />
      <path d="M11.5481 5.42585C11.5481 5.37063 11.5128 5.32586 11.4692 5.32586H4.53136C4.48781 5.32586 4.45251 5.37063 4.45251 5.42585V7.97507C4.45251 8.0303 4.48781 8.07507 4.53136 8.07507H11.4692C11.5128 8.07507 11.5481 8.0303 11.5481 7.97507V5.42585Z" />
      <path d="M11.5481 8.48058C11.5481 8.42535 11.5128 8.38058 11.4692 8.38058H4.53137C4.48782 8.38058 4.45252 8.42535 4.45252 8.48058V9.09809C4.45252 9.15332 4.48782 9.19809 4.53137 9.19809H11.4692C11.5128 9.19809 11.5481 9.15332 11.5481 9.09809V8.48058Z" />
    </svg>
  );
});
