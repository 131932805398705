// eslint-disable-next-line import/no-cycle
import { routingMap } from './routing-map';
// eslint-disable-next-line import/no-cycle
import { RouteConfig } from './child-router-factory';

export type Role = `authenticated` | `non-authenticated`;

export type Middleware = (roles: Role[], opt?: string) => string;
export type WithRoutingMap = (map: typeof routingMap) => Middleware;

export type RoutingMap = typeof routingMap;

export type RoutingState = {
  roles: Role[];
  routingMap: typeof routingMap;
  init: boolean;
};

type ANY = Record<string, unknown>;

export type CreateConfigFn<Keys extends string, Conds extends ANY = ANY> = (
  map: typeof routingMap,
  utils?: {
    conds: Conds;
  }
) => RouteConfig<Keys>[];

export type CreateConfigFnExtend<
  T extends ANY,
  Keys extends string,
  Conds extends ANY = ANY
> = (props: T) => CreateConfigFn<Keys, Conds>;

export const nonAuthenticatedRedirect: WithRoutingMap =
  (map) =>
  (rolesC, opt = ``) => {
    if (rolesC.indexOf('authenticated') === -1) return map.login._;
    return opt;
  };

export const authenticatedRedirect: WithRoutingMap =
  (map) =>
  (rolesC, opt = ``) => {
    if (rolesC.indexOf('non-authenticated') === -1)
      return map.app.selfService._;
    return opt;
  };

export function setRoles(user: $TSFixMe): Role[] {
  let givenRoles: Role[] = [];
  if (user && user.id) {
    givenRoles = ['authenticated'];
    if (user.isStaff) {
      givenRoles = [...givenRoles];
    }
  } else givenRoles = ['non-authenticated'];

  return givenRoles;
}

export const slashJoin = (...args: string[]): string =>
  '/'.concat(args.join('/'));

export const dot = (path: string): { _: string } => {
  return {
    _: path,
  };
};

export const pathJoin = (...args: string[]) => {
  const resolve = (...args1: string[]) => {
    return pathJoin(...args, ...args1);
  };
  resolve.value = '/'.concat(args.filter((v) => !!v).join('/'));
  resolve.chain = { _: resolve.value };
  resolve.chainMap = <M = ANY>(map: M) => ({ ...resolve.chain, ...map });
  resolve.chainMapAuto = <M = ANY>(createMap: (prefix: string) => M) => ({
    ...resolve.chain,
    ...createMap(resolve.value.slice(1)),
  });
  return resolve;
};
