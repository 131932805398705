import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Alert, Button, Col, Divider, Row } from 'src/antd';
import { T } from '@transifex/react';
import createStore from 'zustand';
import { PropertyProvider } from 'src/context/property-context';
import { LayoutLogin } from 'src/layouts/Public.Auht';
import { SelectProperty } from 'src/components/SelectProperty';
import { Typography } from 'src/ui/Typography';
import { UndoOutlined } from '@ant-design/icons';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
import { apiClient } from 'src/api/client';
import { useQuery } from 'react-query';
import { CriptioLogin } from 'src/components/CriptioLogin';
import { useCompany } from './Company.store';
import { LoadingPage } from '../components/shared';
import { useUser } from './User.store';
// import { useMitId } from './useMitId';

export type TValue = {
  id: string;
  type: {
    id: string;
    name: string;
  };
  location: {
    latitude: number;
    longitude: number;
    name: string;
  };
  ticket_types: [
    {
      id: string;
      name: string;
      service: {
        id: string;
        name: string;
        service_type: string;
      };
      is_container_level: true;
      is_property_level: true;
      portal_require_contact_information: true;
      portal_description: string;
      portal_date_label: string;
      portal_date_help_text: string;
      portal_date_required: true;
      portal_date_hidden: true;
      portal_note_label: string;
      portal_note_help_text: string;
      portal_note_required: true;
      portal_note_hidden: true;
      portal_price_show_zero: true;
      portal_contact_information_name_required: true;
      portal_contact_information_phone_number_required: true;
      portal_contact_information_email_required: true;
      portal_contact_information_next_button_label: string;
      portal_confirm_title: string;
      portal_confirm_subtitle: string;
      portal_service_type_bulk_waste_limit: number;
      portal_service_type_bulk_waste_limit_label: string;
      portal_service_type_bulk_waste_limit_help_text: string;
      portal_dropdown_hidden: true;
      portal_dropdown_required: true;
      portal_dropdown_label: string;
      portal_dropdown_help_text: string;
      portal_dropdown_options: [string];
    }
  ];
};

type TStore = {
  data: TValue;
  computed: {
    ticketTypesMap: Record<string, TValue['ticket_types'][0]>;
  };
};

const usePropertyStore = createStore<TStore>(() => ({} as TStore));

export const useProperty = () => {
  return usePropertyStore();
};

/*
1. Get propertyId

- get property id from the localStorage. if not set then go to the property selection page.
- if we get the id from selection page then set it on the localStorage.

2. Once we have the property id we need to check if anything else is required

- If LOGIN_METHOD_NONE:
  - set property and OK.
- If LOGIN_METHOD_MIDID_POST_SEARCH:
  - set property and OK.
- If LOGIN_METHOD_MIDID_PRE_SEARCH:
  - If CheckIfPropertyPropertyIsAnanymous is true then redirect to mid id.
  - else set proerty and OK.

*/

export const PropertyStoreProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [selectPropertyJsx, setSelectPropertyJsx] =
    useState<JSX.Element | null>(null);
  const [notAllowed, setNotAllowed] = useState(false);
  const { data: company } = useCompany();
  const isMobile = useMediaQuery('(max-width: 996px)');
  const {
    jsx: jsxCriptioLogin,
    redirectToMitId,
    cancelMitIdSelection,
  } = CriptioLogin.useCriptioLogin();
  const { data: user, logout } = useUser();
  const property = localStorage.getItem('AUTH_PROPERTY_NAME');

  useEffect(() => {
    if (
      company.login_method === 'user' &&
      company.login_method_user === 'pre_auth_search' &&
      user.anonymous
    ) {
      // if the user is pre_auth_search and still ananoyms then reset the token.
      localStorage.setItem('AUTH_PROPERTY_ID', '');
    }
  }, [company.login_method, company.login_method_user, user.anonymous]);

  const { isLoading, refetch, isRefetching } = useQuery(
    ['PropertyStoreProvider', 'Get'],
    async () => {
      setNotAllowed(false); // reseting the state when get's refetch again
      let propertyId = localStorage.getItem('AUTH_PROPERTY_ID');

      if (!propertyId) {
        propertyId = await new Promise<string>((res) => {
          const jsx = (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ marginBottom: '1.5rem', marginTop: '1rem' }}>
                <Typography
                  style={{ color: '#0F3F6B' }}
                  weight="semi-bold"
                  variant="subHeader-16"
                >
                  <T _str="Select the address" />
                </Typography>
              </div>
              <SelectProperty
                shouldShowOptions={
                  company.login_method_user === 'post_auth_search'
                }
                onChange={(v, option: $TSFixMe) => {
                  localStorage.setItem('AUTH_PROPERTY_NAME', option.label);
                  if (
                    company.login_method === 'user' &&
                    company.login_method_user === 'pre_auth_search' &&
                    user.anonymous
                  ) {
                    localStorage.setItem('AUTH_PROPERTY_ID', v as string);
                    localStorage.setItem(
                      'AUTH_TOKEN_EXPIRY',
                      moment().add(60, 'minutes').toISOString()
                    );
                    redirectToMitId();
                  } else {
                    res(v as string);
                  }
                }}
              />
              {company.login_method_user === 'post_auth_search' && (
                <Button
                  icon={<UndoOutlined />}
                  type="text"
                  style={{
                    padding: '4px 15px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    fontSize: 16,
                    marginTop: '1rem',
                    color: '#0F3F6B',
                  }}
                  onClick={() => {
                    // trigger the selection process again
                    logout();
                  }}
                >
                  <span>
                    <T _str="Login with another account" />
                  </span>
                </Button>
              )}
            </div>
          );
          setSelectPropertyJsx(jsx);
        }).then((v) => {
          localStorage.setItem('AUTH_PROPERTY_ID', v as string);
          localStorage.setItem(
            'AUTH_TOKEN_EXPIRY',
            moment().add(60, 'minutes').toISOString()
          );
          setSelectPropertyJsx(null);
          return v;
        });
      }

      // if there is no token then fetch it using company login method.
      return apiClient
        .get(`property/${propertyId}`)
        .then((d) => {
          if (d.data.id) {
            const p = d.data as TValue;
            usePropertyStore.setState({
              data: d.data,
              computed: {
                ticketTypesMap: p.ticket_types.reduce(
                  (acc, t) => ({ ...acc, [t.id]: t }),
                  {}
                ),
              },
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 404) {
            // wrong property selected. Not authorized.
            setNotAllowed(true);
          }
        });
    }
  );

  if (jsxCriptioLogin)
    return (
      <LayoutLogin>
        <Row gutter={[0, 16]}>
          <Col xs={24}>
            {property && (
              <Col xs={24}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    style={{ textAlign: 'center' }}
                    variant="subHeader-16"
                    weight="semi-bold"
                  >
                    {property}
                  </Typography>
                </div>
              </Col>
            )}
          </Col>
          <Col xs={24}>{jsxCriptioLogin}</Col>
          <Col xs={24}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                style={{ color: '#0F3F6B' }}
                type="link"
                onClick={cancelMitIdSelection}
              >
                <Typography variant="subHeader-16">
                  <T _str="Change to another address" />
                </Typography>
              </Button>
            </div>
          </Col>
        </Row>
      </LayoutLogin>
    );
  if (selectPropertyJsx) return <LayoutLogin>{selectPropertyJsx}</LayoutLogin>;

  if (isLoading || isRefetching) {
    return <LoadingPage />;
  }

  if (notAllowed) {
    return (
      <LayoutLogin>
        <Row gutter={[0, 16]}>
          <Col xs={24}>
            <Col xs={24}>
              <Alert
                message={
                  <Typography
                    style={{ color: '#F5222D' }}
                    variant="subHeader-16"
                    weight="semi-bold"
                  >
                    <T _str="You can not visit this property" />
                  </Typography>
                }
                description={
                  <Typography style={{ color: '#F5222D' }}>
                    <T _str="We detected that you do not have access to visit this property. Please try another address or login again." />
                  </Typography>
                }
                type="error"
                showIcon
              />
            </Col>
            {!isMobile && (
              <Col xs={24}>
                <Divider style={{ margin: '1rem 0rem' }} />
              </Col>
            )}
          </Col>
          <Col xs={24}>
            <CriptioLogin
              style={{ margin: 'auto' }}
              onClick={() => {
                localStorage.setItem('AUTH_TOKEN', '');
                localStorage.setItem('AUTH_PROPERTY_ID', '');
              }}
            />
          </Col>
          <Col xs={24}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                style={{ color: '#0F3F6B' }}
                type="link"
                onClick={() => {
                  localStorage.setItem('AUTH_PROPERTY_ID', '');
                  refetch();
                }}
              >
                <Typography variant="subHeader-16">
                  <T _str="Change to another address" />
                </Typography>
              </Button>
            </div>
          </Col>
        </Row>
      </LayoutLogin>
    );
  }

  return <PropertyProvider>{children}</PropertyProvider>;
};
