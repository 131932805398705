import axios from 'axios';

export const GetCalendar = (
  token: string,
  filename: string,
  urlPath: string,
  queryParameters: string,
  landscape?: boolean
) => {
  return axios.get(`utils/pdf`, {
    responseType: 'blob',
    params: {
      api_key: token,
      filename,
      url_path: urlPath,
      query_parameters: queryParameters,
      landscape,
    },
  });
};
