import React from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const StyledLoadingPageDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLoadingDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingPage: React.FC = () => {
  return (
    <StyledLoadingPageDiv>
      <LoadingOutlined style={{ fontSize: 48 }} spin />
    </StyledLoadingPageDiv>
  );
};

export const LoadingDiv = () => {
  return (
    <StyledLoadingDiv>
      <LoadingOutlined style={{ fontSize: 48 }} spin />
    </StyledLoadingDiv>
  );
};
