import { CustomFieldAdditionalProps, TTicketCustomFieldType } from './types';

export const customFieldsNamesMap: Record<
  TTicketCustomFieldType,
  keyof CustomFieldAdditionalProps
> = {
  NUMBER: 'number_value',
  TEXT_AREA: 'text_value',
  SELECT: 'select_value',
  MULTI_SELECT: 'multi_select_value',
};
