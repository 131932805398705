import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { CalendarOutlined } from '@ant-design/icons';
import { T } from '@transifex/react';
import { routingMap } from 'src/routing/routing-map';
import { Layout1 } from '../../../../layout';
import { BreadCrumb } from '../../../../layout/breadcrumb';
// eslint-disable-next-line import/no-cycle
import { OpenTicketsOnPropertyAlert } from '../../../../shared/OpenTicketsOnPropertyAlert';
import { Header } from '../../../../layout/header';
// eslint-disable-next-line import/no-cycle
import { ApiWrapper } from './api-wrapper';
// eslint-disable-next-line import/no-cycle
import { useGetToken } from '../../../../../api/config';
import { PropertyContext } from '../../../../../context/property-context';
import { GetTickets } from '../../../../shared/api/ticket';

const OrderHistory = () => {
  const token = useGetToken();
  const [tickets, setTickets] = useState([]);
  const { id: propertyId } = useContext(PropertyContext);

  useQuery(
    ['get-container-tickets', propertyId],
    () =>
      GetTickets({
        property_id: propertyId,
        token,
        only_open: true,
        property_level_only: true,
      }),
    {
      onSuccess: (data) => {
        setTickets(data.data);
      },
    }
  );
  return (
    <Layout1
      breadcrumb={<BreadCrumb title={<T _str="Collections" />} />}
      header={
        <Header icon={<CalendarOutlined />} title={<T _str="Collections" />} />
      }
      mainContent={
        <div>
          {tickets.length > 0 && (
            <OpenTicketsOnPropertyAlert
              style={{ marginBottom: '1rem' }}
              linkTo={routingMap.app.containers._}
              tickets={tickets}
            />
          )}
          <ApiWrapper />
        </div>
      }
    />
  );
};

export default OrderHistory;
