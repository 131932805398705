import React from 'react';
import { InputNumber } from 'antd';

import { CustomFieldInputProps } from '../types';
import BaseCustomField from './BaseCustomField';

const NumberCustomField = ({
  unit,
  interval,
  display_unit,
  ...config
}: CustomFieldInputProps) => (
  <BaseCustomField {...config}>
    <InputNumber
      width="100%"
      step={interval}
      addonAfter={display_unit && unit}
    />
  </BaseCustomField>
);

export default NumberCustomField;
