import React, { useRef } from 'react';
import { RightOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import styled from 'styled-components';
import { T } from '@transifex/react';
import { Col, Grid, Image, Row, Button } from 'src/antd';
import { Typography, useModalWithButton } from 'src/ui';
import { useCompany } from 'src/app/Company.store';
import refaBanner from './refa_banner_home_screen.png';

const content = {
  bannerImages: [{ url: refaBanner }],
  news: [
    {
      id: '1',
      title: 'Bemærk',
      link: '',
      description:
        'Fra ultimo januar 2023 kan du se her på Selvbetjeningen hvornår den nye indsamlingsordning bliver aktuel for din husstand samt tage stilling til, hvad der skal ske med din gamle affaldsbeholder.',
      theme: {
        type: 'primary',
      },
      isHardcoded: true,
    },
    {
      id: '2',
      title: 'Ny indsamlingsordning af Enfamilieboliger',
      link: 'https://www.refa.dk/indsamlingsordning-2023/enfamilieboliger/',
      description:
        '(villaer, rækkehuse, o.l.) med egne beholdere i dag, får de nye indsamlingsordninger fra midten af april og månederne herefter. Du kan læse om ordningen her.',
      theme: {
        type: 'primary',
      },
      isHardcoded: true,
    },
    {
      id: '3',
      title: 'Ny indsamlingsordning af Etageejendomme',
      description:
        '(ejer- og lejelejligheder og nogle rækkehuse) med fælles affaldsløsning i dag, fortsætter med fællesløsning og får de nye indsamlingsordninger fra midten af april og månederne herefter. Du kan læse om ordningen her.',
      link: 'https://www.refa.dk/indsamlingsordning-2023/etageejendomme/',
      theme: {
        type: 'primary',
      },
      isHardcoded: true,
    },
    {
      id: '4',
      title: 'Ny indsamlingsordning af Sommerhuse',
      description:
        'i sommerhusområderne får de nye indsamlingsordninger til oktober. Du kan læse om ordningen her.',
      link: 'https://www.refa.dk/indsamlingsordning-2023/sommerhuse/',
      theme: {
        type: 'primary',
      },
      isHardcoded: true,
    },
    {
      id: '5',
      title: 'Hvordan kan jeg se min affaldsordning?',
      description:
        'Klik på punktet ”Beholdere” i menuen. Her ser du en oversigt over hvilke beholdere din husstand er tilmeldt med, samt hvor ofte de tømmes. Derudover er det muligt, at ændre på husstandens affaldsordning ved at klikke på de tre prikker.',
      theme: {
        type: 'secondary',
      },
    },
    {
      id: '6',
      title: 'Hvordan kan jeg se hvornår jeg får tømt mine beholdere?',
      description:
        'Klik på punktet ”Tømningsoversigt” i menuen. Her ser du en oversigt over husstandens kommende samt tidligere tømninger.',
      theme: {
        type: 'secondary',
      },
    },
  ],
};

const Wrapper = styled.div``;

const HARDCODED_COMPANY_ID = '997';

const HomeContent = () => {
  const { xl, xs } = Grid.useBreakpoint();
  const elipsesRef = useRef<Record<string, boolean>>({});
  const { data: company } = useCompany();
  const companyId = atob(company?.id)?.match(/:(.+)/)?.[1];
  const isHardcodedVisible = companyId === HARDCODED_COMPANY_ID;

  const {
    jsx: { modal: jsxModal },
    utils: { openModal, setState: setModalState },
  } = useModalWithButton<Record<string, $TSFixMe>>({
    // eslint-disable-next-line react/no-unstable-nested-components
    children: ({ state }: $TSFixMe) => {
      const { link, theme, title, description } = state?.content || {};
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div
          style={{
            background: theme?.type === 'primary' ? '#034F77' : '#E5E7E8',
            color: theme?.type === 'primary' ? 'white' : 'black',
            borderRadius: 2,
            padding: 20,
            minHeight: 'calc(100vh - 4rem - 4rem)',
            maxHeight: 'calc(100vh - 4rem - 4rem)',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
          onClick={() => {
            if (xs) {
              return null;
            }
            if (link) {
              window.open(link);
            }

            return null;
          }}
        >
          <div
            style={{
              marginBottom: 20,
              maxHeight: 'calc(100vh - 4rem - 4rem - 20px - 2rem)',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                columnGap: 8,
                flexGrow: 1,
                marginBottom: '1rem',
              }}
            >
              <Typography
                variant="subHeader-20"
                weight="semi-bold"
                style={{ color: 'inherit', width: '90%' }}
              >
                {title}
              </Typography>
            </div>
            <div
              style={{
                color: 'inherit',
                maxHeight: 'calc(100vh - 4rem - 4rem - 20px - 2rem - 7rem)',
                overflow: 'scroll',
              }}
            >
              <Typography variant="subHeader-16" style={{ color: 'inherit' }}>
                {description}
              </Typography>
            </div>
          </div>
          {link && xs && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <Button
                onClick={() => {
                  window.open(link);
                }}
                type="default"
              >
                <T _str="Read more" />
              </Button>
            </div>
          )}
        </div>
      );
    },
    modelProps: {
      title: '',
      footer: null,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      width: 'calc(100vw - 3rem)',
      centered: true,
      closable: true,
      bodyStyle: {
        padding: 0,
        borderRadius: 2,
      },
    },
  });

  return (
    <Wrapper className="wh-vertical-scroller-container" style={{ rowGap: 8 }}>
      {isHardcodedVisible && !xl && content.bannerImages.at(0)?.url && (
        <Image
          src={content.bannerImages.at(0)?.url}
          width="100%"
          preview={false}
        />
      )}
      <div className="wh-vertical-scroller-container-scroll">
        {content.news.length && (
          <Row
            gutter={[8, 8]}
            style={{
              overflowY: xl ? 'auto' : 'hidden',
              overflowX: xl ? 'hidden' : 'auto',
              maxHeight: '100%',
            }}
            wrap={xl}
          >
            {isHardcodedVisible && xl && content.bannerImages.at(0)?.url && (
              <Col flex={xl ? 'auto' : 'none'}>
                <Image
                  src={content.bannerImages.at(0)?.url}
                  width="100%"
                  preview={false}
                />
              </Col>
            )}
            {content.news.map(
              ({ description, theme, title, link, id, isHardcoded }) =>
                isHardcoded && !isHardcodedVisible ? null : (
                  <Col key={id} flex={xl ? 'auto' : 'none'}>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    <div
                      className={classnames({
                        'dark-10-hover': !!link,
                      })}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: xl ? 'unset' : '80vw',
                        height: xl ? 'unset' : '100%',
                        maxHeight: xs ? '200px' : 'unset',
                        background:
                          theme.type === 'primary' ? '#034F77' : '#E5E7E8',
                        color: theme.type === 'primary' ? 'white' : 'black',
                        padding: '1rem',
                        borderRadius: 8,
                      }}
                      onClick={() => {
                        if (xs) {
                          setModalState({
                            content: { description, theme, title, link, id },
                          });
                          openModal();
                        }
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexGrow: 0,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'top',
                          marginBottom: '0.5rem',
                        }}
                      >
                        <Typography
                          variant="subHeader-20"
                          weight="semi-bold"
                          style={{
                            color: 'inherit',
                            marginBottom: '0rem',
                          }}
                          component="Paragraph"
                          ellipsis={{ rows: 2 }}
                        >
                          {title}
                        </Typography>
                        {link && (
                          <Col flex="none">
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                minHeight: 28,
                              }}
                            >
                              <RightOutlined
                                style={{ fontSize: '20px', color: '#fff' }}
                              />
                            </div>
                          </Col>
                        )}
                      </div>
                      <div>
                        <Typography
                          variant="subHeader-16"
                          style={{ color: 'inherit', flexGrow: 0 }}
                          component="Paragraph"
                          ellipsis={{
                            // eslint-disable-next-line no-nested-ternary
                            rows: xs ? (link ? 2 : 4) : 100,
                            onEllipsis: (elipses) => {
                              if (elipsesRef.current) {
                                elipsesRef.current[id] = elipses;
                              }
                            },
                          }}
                        >
                          {description}
                        </Typography>
                      </div>
                      {link && xl && (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                        <div
                          className="read-more hoverable"
                          onClick={() => {
                            window.open(link);
                          }}
                          style={{ fontWeight: 600 }}
                        >
                          <T _str="Read more" />
                        </div>
                      )}
                      {link && xs && (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          style={{
                            display: 'flex',
                            flexGrow: 1,
                            alignItems: 'end',
                          }}
                        >
                          <Button
                            onClick={() => {
                              window.open(link);
                            }}
                            type="default"
                          >
                            <T _str="Read more" />
                          </Button>
                        </div>
                      )}
                    </div>
                  </Col>
                )
            )}
          </Row>
        )}
      </div>
      {jsxModal}
    </Wrapper>
  );
};

export default HomeContent;
