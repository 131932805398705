import React from 'react';
import { Tooltip, TooltipProps } from 'antd';

export type WHTooltipProps = { title: string | React.ReactNode } & TooltipProps;

export const WHTooltip = ({
  title,
  children,
  ...restProps
}: WHTooltipProps) => {
  return (
    <Tooltip
      title={title}
      autoAdjustOverflow
      mouseEnterDelay={0.3}
      mouseLeaveDelay={0.5}
      placement="bottom"
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};
