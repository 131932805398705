import { Form, Select } from 'src/antd';
import React from 'react';
import { VALIDATION_MESSAGE } from '../../../../../../../utils/validation';

type TFormItemTicketCustomFormDropdownProps = {
  ticketType: $TSFixMe;
  hiddenOverwrite?: boolean;
};

export const FormItemTicketCustomFormDropdown = ({
  ticketType: {
    portalDropdownHidden,
    portalDropdownLabel,
    portalDropdownHelpText,
    portalDropdownRequired,
    portalDropdownOptions,
    hiddenOverwrite,
  },
}: TFormItemTicketCustomFormDropdownProps) => {
  return (
    <Form.Item
      tooltip={portalDropdownHelpText}
      label={portalDropdownLabel}
      hidden={portalDropdownHidden || hiddenOverwrite}
      name="dropdownValue"
      rules={[
        {
          required: portalDropdownRequired,
          message: VALIDATION_MESSAGE.REQUIRED,
        },
      ]}
    >
      <Select
        options={portalDropdownOptions.map((v: string) => ({
          value: v,
          label: v,
        }))}
      />
    </Form.Item>
  );
};
