import React, { ReactNode } from 'react';
import { T } from '@transifex/react';

export type TicketTicketStatusChoices = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const ticketTicketStatusChoicesLabelMap: Record<
  TicketTicketStatusChoices,
  ReactNode
> = {
  1: <T _str="Created" />,
  2: <T _str="Open" />,
  3: <T _str="Pending" />,
  4: <T _str="On hold" />,
  5: <T _str="In Progress" />,
  6: <T _str="Completed" />,
  7: <T _str="Rejected" />,
};

export const ticketTicketStatusChoicesTagColorMap: Record<
  TicketTicketStatusChoices,
  string
> = {
  1: 'blue',
  2: 'red',
  3: 'orange',
  4: 'yellow',
  5: 'blue',
  6: 'green',
  7: 'red',
};
