import React, { useContext } from 'react';
import {
  Switch,
  Route,
  RouteProps,
  Redirect,
  RouteChildrenProps,
  useHistory,
} from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { Role } from './utils';
// eslint-disable-next-line import/no-cycle
import { routingMap } from './routing-map';
// eslint-disable-next-line import/no-cycle
import { RouterContext } from '../context/routing';
import { ErrorBoundary } from '../sentry';

export type RolesFlag = `*`;
export type Fallback = (roles: Role[]) => string;

export type Roles = {
  or?: Role[];
  and?: Role[];
  flag?: RolesFlag;
};

export type RouteConfig<T extends string> = {
  key: T;
  roles: Roles;
  fallback: Fallback;
  conds?: CondSet[];
  path: string;
} & Omit<RouteProps, `render` | `component` | `key` | `path`>;

export type RouterFactoryProps<T extends string> = {
  config: RouteConfig<T>[];
  defaultRedirect?: string;
  notFound?: React.FC;
};

export type Routing = {
  roles: Role[];
  routingMap: typeof routingMap;
  init: boolean;
};

type CondSet = [boolean, () => void];

export type RoleCheckerProps = {
  allowedRoles: Roles;
  fallback: Fallback;
  conds?: CondSet[];
  children: ((props: RouteChildrenProps) => React.ReactNode) | React.ReactNode;
};

export type RoutingUtilCheckRoleT = (args: {
  allowedRoles: Roles;
  givenRoles: Role[];
}) => boolean;

export const routingUtilCheckRole: RoutingUtilCheckRoleT = ({
  allowedRoles,
  givenRoles,
}) => {
  const { and, flag, or } = allowedRoles;

  if (flag === '*') return true;

  if (
    (and?.length
      ? and?.every((role) => givenRoles.indexOf(role) !== -1)
      : true) &&
    (or?.length ? or?.some((role) => givenRoles.indexOf(role) !== -1) : true)
  ) {
    return true;
  }

  return false;
};

export type HookUseRoleCheckerT = () => [
  (allowedRoles: Roles) => boolean,
  Routing
];

export const useRoleChecker: HookUseRoleCheckerT = () => {
  const routeConfig = useContext(RouterContext);
  const { roles: givenRoles } = routeConfig;

  return [
    (allowedRoles) => routingUtilCheckRole({ givenRoles, allowedRoles }),
    routeConfig,
  ];
};

export const RoleChecker: React.FC<RoleCheckerProps> = ({
  allowedRoles,
  fallback,
  conds,
  children,
}) => {
  conds?.forEach((condSet) => condSet[0] && condSet[1]());
  const [checkRoleFunc, { roles }] = useRoleChecker();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (checkRoleFunc(allowedRoles)) return <>{children}</>;

  return <Redirect to={fallback(roles)} />;
};

const RouterFactory = <T extends string>({
  config,
  defaultRedirect = ``,
  notFound,
}: RouterFactoryProps<T>): JSX.Element => {
  return (
    <Switch>
      {config.map(({ children, roles, fallback, conds, ...rest }) => (
        <Route {...rest}>
          {(props) => (
            <RoleChecker allowedRoles={roles} fallback={fallback} conds={conds}>
              <ErrorBoundary>
                {typeof children === 'function' ? children?.(props) : children}
              </ErrorBoundary>
            </RoleChecker>
          )}
        </Route>
      ))}
      {notFound ? (
        <Route component={notFound} />
      ) : (
        <Redirect to={defaultRedirect} />
      )}
    </Switch>
  );
};

const useRouting = () => {
  const routing: Routing = useContext(RouterContext);
  return routing;
};

useRouting.useHistory = useHistory;

export { useRouting };

export default RouterFactory;
