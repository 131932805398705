import axios from 'axios';
import { MutateFunction } from 'react-query';

type TVariables = {
  mode: 'containers' | 'property' | 'manage-containers' | 'bulk-waste-pickup';
  payload: Record<string, unknown>;
  token: string;
};

export const mutate: MutateFunction<unknown, unknown, TVariables> = ({
  mode,
  payload,
  token,
}) =>
  axios.post(`order/${mode}`, payload, {
    headers: { 'X-API-Key': token },
  });

export const PatchPropertyUser = (
  name: string,
  phoneNumber: string,
  email: string,
  language: string,
  token: string
) => {
  return axios.post(
    `property_user`,
    {
      name,
      phone_number: phoneNumber,
      email,
      language,
    },
    {
      headers: { 'X-API-Key': token },
    }
  );
};
