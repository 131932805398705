import React from 'react';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { CSSProperties } from 'styled-components';
import { T } from '@transifex/react';

import { alert } from 'src/theme/colors';

export type PickupOrderStatus =
  | 'completed_without_tickets'
  | 'completed_with_tickets'
  | 'not_completed_with_tickets';

export const pickupOrderStatusColorMap: Record<
  PickupOrderStatus,
  CSSProperties['color']
> = {
  completed_without_tickets: '#73D13D',
  completed_with_tickets: alert,
  not_completed_with_tickets: '#F5222D',
};

export const pickupOrderStatusIconMap: Record<
  PickupOrderStatus,
  React.ReactNode
> = {
  completed_without_tickets: (
    <CheckCircleFilled
      style={{
        color: pickupOrderStatusColorMap.completed_without_tickets,
        fontSize: 21,
      }}
    />
  ),
  completed_with_tickets: (
    <ExclamationCircleFilled
      style={{
        color: pickupOrderStatusColorMap.completed_with_tickets,
        fontSize: 21,
      }}
    />
  ),
  not_completed_with_tickets: (
    <ExclamationCircleFilled
      style={{
        color: pickupOrderStatusColorMap.not_completed_with_tickets,
        fontSize: 21,
      }}
    />
  ),
};

export const pickupOrderStatusEmptyLabelMap: Record<
  PickupOrderStatus,
  React.ReactNode
> = {
  completed_without_tickets: <T _str="Emptied" />,
  completed_with_tickets: <T _str="Emptied" />,
  not_completed_with_tickets: <T _str="Not emptied" />,
};
