import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GlobalStyles } from 'src/theme';
import { StorybookLegacyGlobalStyles } from 'src/ui/storybook-legacy-styles';
import { AsyncModalPortal } from 'src/ui/asyncModalAction';
import { GlobalStyles as GlobalStylesLocal } from './global.styles';
import RouterFactory, { useRouting } from '../routing/child-router-factory';
import { createConfig } from './route-config';

import { routingMap } from '../routing/routing-map';
import AntdI18n from './AntdI18n';
import { CompanyStoreProvider } from './Company.store';
import { ThemeStoreProvider } from './Theme.store';
import Router from './router';
import { UserStoreProvider } from './User.store';
import { PropertyStoreProvider } from './Property.store';
import Browserlessv1 from './browerless-v1';
import Browserlessv2 from './browerless-v2';

export const App = () => {
  const { routingMap: map } = useRouting();

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <GlobalStyles />
      <GlobalStylesLocal />
      <StorybookLegacyGlobalStyles />
      <BrowserRouter basename={window.WH_SS_INIT_ROUTE || ''}>
        <Switch>
          {/* Reversed version to support new calendar */}
          <Route path="/calendar/v2">
            <CompanyStoreProvider>
              <Browserlessv1 />
            </CompanyStoreProvider>
          </Route>
          <Route path="/calendar">
            <CompanyStoreProvider>
              <Browserlessv2 />
            </CompanyStoreProvider>
          </Route>
          <Route path="*">
            <AsyncModalPortal />
            <CompanyStoreProvider>
              <ThemeStoreProvider>
                <UserStoreProvider>
                  <PropertyStoreProvider>
                    <AntdI18n>
                      <Router>
                        <RouterFactory
                          config={createConfig(map)}
                          defaultRedirect={routingMap.app.selfService._}
                        />
                      </Router>
                    </AntdI18n>
                  </PropertyStoreProvider>
                </UserStoreProvider>
              </ThemeStoreProvider>
            </CompanyStoreProvider>
          </Route>
        </Switch>
      </BrowserRouter>
    </QueryClientProvider>
  );
};
