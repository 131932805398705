import React from 'react';
// eslint-disable-next-line import/no-cycle
import Exact from './exact';
// eslint-disable-next-line import/no-cycle
import {
  CreateConfigFn,
  nonAuthenticatedRedirect,
  pathJoin,
} from '../../../../routing/utils';
// eslint-disable-next-line import/no-cycle
import { Roles } from '../../../../routing/child-router-factory';
// eslint-disable-next-line import/no-cycle
import DownloadCalendar from './download';
// eslint-disable-next-line import/no-cycle
import { TicketView } from '../../../shared/views/ticket/Ticket';

export type Keys = `exact` | ':ticketId' | 'download';

export const createRoutesMap = (prefix: string) => ({
  download: pathJoin(prefix)(`download`).chain,
  ':ticketId': (containerId: string) => pathJoin(prefix)(containerId).chain,
});

const authenticatedUser: Roles = {
  and: ['authenticated'],
};

export const createConfig: CreateConfigFn<Keys> = (map) => [
  {
    key: 'download',
    fallback: (roles) => nonAuthenticatedRedirect(map)(roles),
    roles: authenticatedUser,
    children: <DownloadCalendar />,
    path: map.app.collections.download._,
  },
  {
    key: ':ticketId',
    fallback: (roles) => nonAuthenticatedRedirect(map)(roles),
    roles: authenticatedUser,
    children: <TicketView />,
    path: map.app.collections[':ticketId'](':ticketId')._,
  },
  {
    key: 'exact',
    fallback: (roles) => nonAuthenticatedRedirect(map)(roles),
    roles: authenticatedUser,
    children: <Exact />,
    path: map.app.collections._,
    exact: true,
  },
];
