import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  .whf-ant-popover {
    &&& {
      &.no-arrow {
        padding: 0 !important;
      }
      &.no-content-padding {
      .ant-popover-inner-content {
        padding: 0px
      }
    }
    }
  }
`;
