import React from 'react';
import styled from 'styled-components';
import { T } from '@transifex/react';

const ContainerProductDetailWrapper = styled.div``;

export type BulkWasteDetailsProps = {
  bulkWasteItems: {
    wasteFraction: { name: string };
    quantity: number;
    note: string;
  }[];
};

export const BulkWasteDetails = ({ bulkWasteItems }: BulkWasteDetailsProps) => {
  return (
    <ContainerProductDetailWrapper>
      {bulkWasteItems && (
        <div>
          {bulkWasteItems?.map((e) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '1rem',
              }}
            >
              <div>{e?.wasteFraction?.name}</div>
              <div style={{ fontSize: '1rem', fontWeight: 400 }}>
                <T _str="Quantity: " /> {e?.quantity}
              </div>
              {e?.note && (
                <div style={{ fontSize: '1rem', fontWeight: 400 }}>
                  <T _str="Note: " /> {e?.note}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </ContainerProductDetailWrapper>
  );
};
