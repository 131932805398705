import React, { useContext, useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useUser } from 'src/app/User.store';
import ResponsiveSidebar from './ui';
// eslint-disable-next-line import/no-cycle
import {
  useRoleChecker,
  useRouting,
} from '../../../routing/child-router-factory';
// eslint-disable-next-line import/no-cycle
import { createNavData } from './navData';
import { CompanyContext } from '../../../context/company-context';
import { PropertyContext } from '../../../context/property-context';
// eslint-disable-next-line import/no-cycle

export const Sidebar = () => {
  const [checkRole, { routingMap }] = useRoleChecker();
  const history = useRouting.useHistory();
  const [selectedKeys, setSelectedKeys] = useState<$TSFixMe>([]);
  const navData = useMemo(() => createNavData(routingMap), [routingMap]);
  const property = useContext(PropertyContext);
  const { logout } = useUser();

  const location = useLocation();

  const {
    primary_color: Primary,
    secondary_text_color: Secondary,
    logo,
  } = useContext(CompanyContext);
  const { data: user } = useUser();

  const { pathname } = location;

  useEffect(() => {
    let mainSelectedKeyPair = ['', ''];
    let subSelectedKeyPair = ['', ''];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navData.forEach(({ link = ``, key, childs }) => {
      const matchingPatch = matchPath(pathname, {
        path: link,
      });
      if (matchingPatch) {
        const [, aLink] = mainSelectedKeyPair;
        if (aLink.length < (link as $TSFixMe).length)
          mainSelectedKeyPair = [key, link];
      }
      if (childs && (childs as $TSFixMe).length) {
        // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'childLink' implicitly has an 'any... Remove this comment to see the full error message
        (childs as $TSFixMe).forEach(({ link: childLink, key: childKey }) => {
          const matchingPatchChild = matchPath(pathname, {
            path: childLink,
          });
          if (matchingPatchChild) {
            const [, bLink] = subSelectedKeyPair;
            if (bLink.length < childLink.length)
              subSelectedKeyPair = [childKey, childLink];
            // parent case;
            mainSelectedKeyPair = [key, link];
          }
        });
      }
    });
    setSelectedKeys([mainSelectedKeyPair[0], subSelectedKeyPair[0]]);
  }, [navData, pathname]);

  const config = useMemo(() => {
    return navData.reduce(
      (
        accParent: $TSFixMe,
        { title, link, href, icon, childs, key: parentKey, roles }: $TSFixMe
      ) => {
        if (roles && !checkRole(roles)) return accParent;
        return [
          ...accParent,
          {
            title,
            link,
            icon,
            key: parentKey,
            onClick: () => {
              if (link) {
                history.push(link);
              } else if (href) {
                window.location.href = href;
              }
            },
            childs: childs?.reduce(
              (
                acc: $TSFixMe,
                {
                  title: t,
                  link: l,
                  icon: i,
                  key: childKey,
                  roles: childRoles,
                }: $TSFixMe
              ) => {
                if (childRoles && !checkRole(childRoles)) return acc;
                return [
                  ...acc,
                  {
                    t,
                    l,
                    i,
                    key: childKey,
                    onClick: () => {
                      if (l) {
                        history.push(l);
                      } else if (href) {
                        window.location.href = href;
                      }
                    },
                  },
                ];
              },
              []
            ),
          },
        ];
      },
      []
    );
  }, [checkRole, history, navData]);

  const userInformationProps = {
    authenticated: !!user?.username,
    information: {
      name: user?.name,
      addresses: property?.location?.name,
    },
    onClick: logout,
  };

  const menuTreeProps = {
    config,
    selectedKeys,
    collapsed: false,
    colors: {
      primary: Primary,
      secondary: Secondary,
      background: '#001529',
    },
  };

  const controlButtonProps = {
    authenticated: true,
    settingsOnClick: () => {},
    logOutOnClick: logout,
    logInOnClick: logout,
  };

  return (
    <ResponsiveSidebar
      userInformationProps={userInformationProps}
      controlButtonProps={controlButtonProps}
      menuTreeProps={menuTreeProps}
      /* eslint-disable-next-line jsx-a11y/alt-text */
      logo={logo ? <img src={logo} width="100%" /> : undefined}
    />
  );
};
