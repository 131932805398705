import React, { ReactNode, useMemo } from 'react';
import { ThemeProvider, DefaultTheme } from 'styled-components';

import { generateBorderSizeValues } from 'src/theme/borders';
import {
  alert,
  black,
  dark,
  light,
  link,
  success,
  warning,
  white,
  getColorShades,
} from 'src/theme/colors';
import { useCompany } from './Company.store';

export const ThemeStoreProvider = ({ children }: { children: ReactNode }) => {
  const { data: company } = useCompany();

  const theme = useMemo<DefaultTheme>(
    () => ({
      colors: {
        primaryColor: company.primary_color || '#1890FF',
        darkColor: company.primary_text_color || '#00417e',
        lightColor: company.secondary_text_color || '#fff',
        ...getColorShades('dark', dark),
        ...getColorShades('light', light),
        ...getColorShades('white', white),
        ...getColorShades('black', black),
        ...getColorShades('alert', alert),
        ...getColorShades('success', success),
        ...getColorShades('warning', warning),
        ...getColorShades('link', link),
      },

      // TODO: get size value from company settings when BE is ready
      borders: generateBorderSizeValues('medium'),
    }),
    [company]
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
