import React, { useContext, useState } from 'react';
import { groupBy } from 'lodash';
import { useQuery } from 'react-query';
import moment, { Moment } from 'moment';
// eslint-disable-next-line import/no-cycle
import { UI } from './ui';
import { PropertyContext } from '../../../../../context/property-context';
import { GetCollections } from './api';
import { getCurrentMonthRange } from '../../../../../utils';
// eslint-disable-next-line import/no-cycle
import { useGetToken } from '../../../../../api/config';

export const ApiWrapper = () => {
  const { id: propertyId } = useContext(PropertyContext);
  const token = useGetToken();

  const [dates, setDates] = useState<[Moment, Moment]>(getCurrentMonthRange());

  const { data, isLoading } = useQuery(
    [
      'get-property-collections',
      propertyId,
      moment(dates[0]).format(),
      moment(dates[1]).format(),
    ],
    () =>
      GetCollections({
        token,
        propertyId,
        fromDate: moment(dates[0]).format('YYYY-MM-DD'),
        toDate: moment(dates[1]).format('YYYY-MM-DD'),
      })
  );

  const collections = data?.data;

  const collectionsGroupedByDate = groupBy(collections, 'date');

  return (
    <UI
      rangePickerProps={{ initialRange: dates, onChange: setDates }}
      collections={collectionsGroupedByDate}
      loading={isLoading}
    />
  );
};
