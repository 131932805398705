import { T } from '@transifex/react';
import React, { useMemo, useState } from 'react';
import { Alert, Button, Col, Divider, Row } from 'src/antd';
import { pick } from 'lodash';
import createStore from 'zustand';
import moment from 'moment';
import { LayoutLogin } from 'src/layouts/Public.Auht';
import { Typography } from 'src/ui/Typography';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
import { CitizenProvider } from 'src/context/citizen-context';
import { apiClient, apiClientMethods } from 'src/api/client';
import { CriptioLogin } from 'src/components/CriptioLogin';
import { useQuery } from 'react-query';
import { LoadingPage } from '../components/shared';
import { useCompany } from './Company.store';

type TValue = {
  id: string;
  username: string;
  token: string;
  language: string;
  phone_number: string;
  email: string;
  name: string;
  anonymous: boolean;
};

type TStore = {
  data: TValue;
  logout: () => void;
  update: (v: Pick<TValue, 'language'>) => Promise<void>;
};

export const useUserStore = createStore<TStore>(() => ({} as TStore));

export const useUser = () => {
  return useUserStore();
};

export const useUserMaybe = (): TValue | undefined => {
  return useUserStore().data;
};

/*
- getToken: from AUTH_TOKEN or from search param or from the api.
- getUser: using the token

- If LOGIN_METHOD_NONE:
  - set property user and OK.
- If LOGIN_METHOD_MIDID_POST_SEARCH:
  - If CheckIfPropertyUserIsAnanymous is true then redirect to mit id.
  - else set property user and OK.
- If LOGIN_METHOD_MIDID_PRE_SEARCH
  - set property user and OK.

*/

export const UserStoreProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { data: company, hostname } = useCompany();
  const { data: user } = useUser();
  const isMobile = useMediaQuery('(max-width: 996px)');
  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const [error, setError] = useState<{
    errorCode?: string;
    errorMessage?: string;
  }>({});

  const logout = () => {
    localStorage.setItem('AUTH_TOKEN', '');
    localStorage.setItem('AUTH_PROPERTY_ID', '');
    window.history.replaceState(
      {},
      document.title,
      window.WH_SS_INIT_ROUTE || '/'
    );
    window.location.reload();
  };

  const update: TStore['update'] = async (values) => {
    await apiClient
      .post(`property_user`, {
        ...pick(user, ['name', 'phone_number', 'email', 'language']),
        ...values,
      })
      .then((d) => {
        useUserStore.setState({ data: d.data });
      });
  };

  const { isLoading } = useQuery(['UserStoreProvider', 'Get'], async () => {
    // if there is error then show error and return
    const errorCode = searchParams.get('error_code') || undefined;
    const errorMessage = searchParams.get('error') || undefined;
    if (errorCode || errorMessage) {
      setError({
        errorCode,
        errorMessage,
      });
      return null;
    }

    // get the token
    const token =
      searchParams.get('api_key') || localStorage.getItem('AUTH_TOKEN');

    if (token) {
      // need to remove the api_key from the url once we used it.
      if (searchParams.get('api_key')) {
        const propertyId = searchParams.get('property_key');
        // also checking for property key here
        if (propertyId) {
          localStorage.setItem('AUTH_PROPERTY_ID', propertyId as string);
          localStorage.setItem(
            'AUTH_TOKEN_EXPIRY',
            moment().add(60, 'minutes').toISOString()
          );
        }
        window.history.replaceState({}, document.title, '/');
      }

      apiClientMethods.setToken(token);
      return apiClient
        .get('property_user')
        .then((d) => {
          if (d.data.id) {
            useUserStore.setState({ data: d.data, logout, update });
            localStorage.setItem('AUTH_TOKEN', token);
          }
        })
        .catch((e) => {
          setError({
            errorMessage: e?.message || <T _str="Something went wrong" />,
          });
        });
    }
    // if there is no token then fetch it using company login method.
    return apiClient
      .post(`company/${hostname}/login`, { username: '', password: '' })
      .then((d) => {
        if (d.data.id) {
          apiClientMethods.setToken((d.data as TValue).token);
          useUserStore.setState({ data: d.data, logout, update });
          localStorage.setItem('AUTH_TOKEN', (d.data as TValue).token);
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          // wrong property selected. Not authorized. invalid token
          logout();
        }
      });
  });

  if (isLoading) {
    return <LoadingPage />;
  }

  if (user) {
    if (
      company.login_method === 'user' &&
      company.login_method_user === 'post_auth_search' &&
      user.anonymous
    ) {
      // show mit id login here.
      return (
        <LayoutLogin>
          <CriptioLogin />
        </LayoutLogin>
      );
    }
    return <CitizenProvider>{children}</CitizenProvider>;
  }

  return (
    <LayoutLogin>
      <Row style={{ width: '100%' }} gutter={[0, 16]}>
        <Col xs={24}>
          <Col xs={24}>
            <Alert
              message={
                <Typography
                  style={{ color: '#F5222D' }}
                  variant="subHeader-16"
                  weight="semi-bold"
                >
                  {error.errorCode || <T _str="Error" />}
                </Typography>
              }
              description={
                <Typography style={{ color: '#F5222D' }}>
                  {error?.errorMessage || <T _str="An unknown error occured" />}
                </Typography>
              }
              type="error"
              showIcon
            />
          </Col>
          {!isMobile && (
            <Col xs={24}>
              <Divider style={{ margin: '1rem 0rem', marginTop: '2rem' }} />
            </Col>
          )}
        </Col>
        <Col xs={24}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button style={{ color: '#0F3F6B' }} type="link" onClick={logout}>
              <Typography variant="subHeader-16">
                <T _str="Back to Login" />
              </Typography>
            </Button>
          </div>
        </Col>
      </Row>
    </LayoutLogin>
  );
};
