import React, { useCallback, useState } from 'react';
import { Button, Popover, Checkbox, Row } from 'antd';
import { InsertRowRightOutlined } from '@ant-design/icons';
import { TX_DISPLAY_COLUMNS } from 'src/storybook_transifex';

import {
  StyledTree,
  UseTreeFilterAndOrderBaseArgs as UseTreeFilterAndOrderSimpleArgs,
  arraymove,
  UseTreeFilterAndOrderReturn,
} from './shared';

export type { UseTreeFilterAndOrderSimpleArgs };

/**
 * Hook: useTreeFilterAndOrderSimple
 * Note:
 * 1. Init.order should include all the columns that you want to show on the table.
 * If any column is not in this list then it will not be rendered.
 */
export const useTreeFilterAndOrderSimple = <T extends string>({
  init,
  columns,
  popover,
  hideColumns = [],
}: UseTreeFilterAndOrderSimpleArgs<T>): UseTreeFilterAndOrderReturn<T> => {
  /* Transifex */
  const [checkedCols, setCheckedCols] = useState(init.checked);
  const [orderCols, setOrderCols] = useState(init.order);

  const handleChangeTableColumnsOrder = useCallback(
    // eslint-disable-next-line consistent-return
    (colkey: string, position: number) => {
      let newDropPosition = position;
      if (position < 0) {
        newDropPosition = 0;
      }
      const cList = [...orderCols];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const currentPosition = cList.findIndex((s) => s === colkey)!;
      if (newDropPosition > currentPosition) {
        newDropPosition -= 1;
      }

      if (currentPosition === -1) {
        return null;
      }

      arraymove<string>(cList, currentPosition, newDropPosition);
      setOrderCols(cList.filter(Boolean));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderCols]
  );

  const handleAllColsSelected = (checked: boolean) => {
    if (checked) {
      setCheckedCols(init.order);
    } else {
      setCheckedCols([]);
    }
  };

  return {
    state: orderCols.map((c) => c).filter((k) => checkedCols.includes(k)),
    jsx: {
      toggler: (
        <Popover
          {...(popover || {})}
          placement={popover?.placement || 'bottomRight'}
          title={
            <Checkbox
              onChange={(e) => handleAllColsSelected(e.target.checked)}
              indeterminate={
                !!checkedCols.length && checkedCols.length !== init.order.length
              }
              checked={checkedCols.length === init.order.length}
            >
              {TX_DISPLAY_COLUMNS}
            </Checkbox>
          }
          content={
            <Row>
              <StyledTree
                draggable
                checkable
                checkedKeys={checkedCols}
                onCheck={(keys) => setCheckedCols(keys as [])}
                treeData={orderCols
                  ?.filter((k) => !hideColumns.includes(k))
                  .map((k) => columns[k])}
                onDrop={({ dropPosition, dragNode: { key: dropNodeKey } }) => {
                  handleChangeTableColumnsOrder(
                    dropNodeKey as string,
                    dropPosition
                  );
                }}
              />
            </Row>
          }
        >
          <Button
            type="text"
            shape="circle"
            icon={<InsertRowRightOutlined />}
          />
        </Popover>
      ),
    },
  };
};
