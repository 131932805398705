import { useMemo } from 'react';
// eslint-disable-next-line import/no-cycle
import { useGetPropertyTicketTypeAvailableDates } from '../../../../../../../api/property/ticket-type/available-dates';

export const useTicketAvailableDates = ({
  propertyId,
  ticketTypeId,
}: {
  propertyId: string;
  ticketTypeId: string;
  singleKey?: string;
}) => {
  const { data: dataGetPropertyTicketTypeAvailableDates } =
    useGetPropertyTicketTypeAvailableDates({
      propertyId,
      ticketTypeId,
    });

  const availableDatesMap = useMemo<Map<string, string[]>>(() => {
    const r = new Map();
    dataGetPropertyTicketTypeAvailableDates?.data?.dates?.forEach(
      ({ dates, container_id }: $TSFixMe) => {
        if (container_id) r.set(container_id, dates);
        r.set('default', dates);
      }
    );
    return r;
  }, [dataGetPropertyTicketTypeAvailableDates?.data?.dates]);

  return availableDatesMap;
};
