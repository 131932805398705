import { message } from 'src/antd';
import { T } from '@transifex/react';
import createStore from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import React from 'react';
import { apiNotification } from '../../../../api/notifications';
import { APIError } from '../../../../api/consts/error';
import { getCookie } from '../../../../utils/cookies';
import {
  emailCodeCookie,
  smsCodeCookie,
  smsServiceMode,
  smsServicePhoneNumber,
} from '../../../../consts/cookie-consts';

export enum TViewStep {
  GET_VERIFICATION_CODE,
  SELECT_CONTAINERS,
  SUMMARY,
}

export type TContainer = {
  id: string;
  daysBefore: number;
};

export type TNotificationMode = 'sms' | 'email';

type TSummaryData = {
  phoneNumber?: string;
  email?: string;
  mode: TNotificationMode;
};

type TViewSmsServiceStore = {
  mode: TNotificationMode;
  init: boolean;
  context: { propertyId: string; token: string };
  step: TViewStep;
  data: {
    verificationCode: string;
    newSelectedContainers: TContainer[];
    other: TSummaryData;
  };
  /** moving the steps. */
  setVerificationCode: (code: string, d: TSummaryData) => void;
  updateContainers: (containers: TContainer[], nextStep?: boolean) => void;
  initStore: (
    d: Pick<TViewSmsServiceStore, 'context'>,
    mode: TNotificationMode
  ) => void;
  nextStep: () => void;
  prevStep: () => void;
  reset: () => void;
  done: boolean;
  handleConfirm: () => Promise<void>;
};

export const useSmsServiceStore = createStore<TViewSmsServiceStore>(
  (set, get) => ({
    mode: 'sms',
    data: {
      verificationCode: getCookie(smsCodeCookie) || '',
      newSelectedContainers: [],
      other: {
        mode: getCookie(smsServiceMode) as TNotificationMode,
        phoneNumber: getCookie(smsServicePhoneNumber) || '',
      },
    },
    step: getCookie(smsCodeCookie)
      ? TViewStep.SELECT_CONTAINERS
      : TViewStep.GET_VERIFICATION_CODE,
    setVerificationCode: async (c, summaryData) => {
      const { context } = get();
      // get the containers.
      try {
        const { data }: $TSFixMe =
          await apiNotification.callGetAllNotificationContainers({
            propertyId: context.propertyId,
            token: context.token,
            verificationId: c,
          });
        set({
          step: TViewStep.SELECT_CONTAINERS,
          data: {
            verificationCode: c,
            newSelectedContainers: data?.map(
              ({ container, days_before }: $TSFixMe): TContainer => ({
                id: container.id,
                daysBefore: days_before,
              })
            ),
            other: summaryData,
          },
        });
      } catch (e: unknown) {
        const error: APIError = e as APIError;
        if (!error.opts?.ERROR_MESSAGE_HANDLED)
          message.error(<T _str="Error occured" />);
      }
    },
    updateContainers: (c, nextStep = false) => {
      set({
        data: {
          verificationCode: get().data.verificationCode,
          newSelectedContainers: c,
          other: get().data.other,
        },
      });
      if (nextStep) {
        set({
          step: TViewStep.SUMMARY,
        });
      }
    },
    context: { propertyId: '', token: '' },
    init: false,
    initStore: (v, mode) => {
      set({
        init: true,
        context: v.context,
        mode,
        step:
          (mode === 'sms' && getCookie(smsCodeCookie)) ||
          (mode === 'email' && getCookie(emailCodeCookie))
            ? TViewStep.SELECT_CONTAINERS
            : TViewStep.GET_VERIFICATION_CODE,
      });
    },
    nextStep: () => {
      const currentStep = get().step;
      if (currentStep < TViewStep.SUMMARY) set({ step: get().step + 1 });
    },
    prevStep: () => {
      const currentStep = get().step;
      if (currentStep > 0) set({ step: get().step - 1 });
    },
    reset: () => {
      set({
        init: false,
        done: false,
        step: getCookie(smsCodeCookie)
          ? TViewStep.SELECT_CONTAINERS
          : TViewStep.GET_VERIFICATION_CODE,
      });
    },
    done: false,
    handleConfirm: async () => {
      const {
        context: { propertyId, token },
        data: { newSelectedContainers, verificationCode },
      } = get();
      try {
        await apiNotification.mutateUpdateContainers({
          propertyId,
          token,
          verificationId: verificationCode,
          containers: newSelectedContainers.map(({ id, daysBefore }) => ({
            containerId: id,
            daysBefore,
          })),
        });
        set({ done: true });
      } catch (e: $TSFixMe) {
        message.error(e?.message || <T _str="Error occured" />);
      }
    },
  })
);

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Store', useSmsServiceStore);
}
