import React from 'react';
import { Typography } from 'src/ui/Typography';
import { Col, Row } from 'antd';
import moment from 'moment';
import { WasteFractionTag } from 'src/ui';
import { CELL_HEIGHT, COLUMN_WIDTH } from './contants';

type CollectionCellProps = {
  date: string;
  collections: $TSFixMe[];
};

const CollectionCell = ({ date, collections = [] }: CollectionCellProps) => {
  const momentDate = moment(date);
  const day = momentDate.day();
  const isSaturdayOrSunday = day === 6 || day === 0;

  return (
    <Row
      wrap={false}
      gutter={6}
      align={collections?.length > 1 ? 'top' : 'middle'}
      style={{
        borderBottom: '1px solid rgba(38, 38, 38, 0.15)',
        minHeight: CELL_HEIGHT,
        width: COLUMN_WIDTH - 1,
        padding: '2px 4px',
        marginRight: '0 !important',
        marginLeft: '0 !important',
        backgroundColor: isSaturdayOrSunday ? '#ECEFF3' : 'unset',
      }}
    >
      <Col style={{ minWidth: 16, display: 'flex' }}>
        <Typography style={{ fontSize: 10 }} type="secondary">
          {momentDate.format('dddd')[0].toUpperCase()}
        </Typography>
      </Col>
      <Col style={{ minWidth: 22, display: 'flex' }}>
        <Typography style={{ fontSize: 10 }} weight="semi-bold">
          {momentDate.format('DD')}
        </Typography>
      </Col>
      <Col flex="auto">
        <Row>
          {collections?.map(({ isMore, length, ...collection }) => {
            const { container } = collection;

            return (
              <WasteFractionTag
                wasteFraction={container?.waste_fraction}
                iconOnly
                style={{ margin: 0 }}
              />
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default CollectionCell;
