import React from 'react';
import { T } from '@transifex/react';

const description = (
  <T _str="Use this platform to can see your containers, and collections. It also provides self-service where you can create tickets." />
);

export const CONTENT_AUTH_SCREEN = {
  description,
  faqs: [
    {
      title: <T _str="How to see my current containers?" />,
      description: (
        <T _str="You can visit the containers menu on the right sidebar which will open containers view. You can see your containers along with it's container type, and pickup setting." />
      ),
    },
    {
      title: <T _str="How to see my collections?" />,
      description: (
        <T _str="You can visit the collections menu on the right sidebar which will open collections view. You can see your collections there." />
      ),
    },
    {
      title: <T _str="How to create a ticket?" />,
      description: (
        <T _str="You can visit the containers menu on the right sidebar which will open containers view. There you will see the all your containers and actions dropdown on the right side. Click on the three-dots icon and you can see all the available actions including creation of tickets." />
      ),
    },
  ],
};
