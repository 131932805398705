import React from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const StyledLoadingDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CenteredLoading: React.FC = () => {
  return (
    <StyledLoadingDiv>
      <LoadingOutlined style={{ fontSize: 48 }} spin />
    </StyledLoadingDiv>
  );
};
