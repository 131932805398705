import React, { ReactNode } from 'react';
import { T } from '@transifex/react';
import { TNotificationMode } from '../store';

export const notificationModeLabelMap: Record<TNotificationMode, ReactNode> = {
  email: <T _str="Email" />,
  sms: <T _str="SMS" />,
};
export const notificationModeContactMap: Record<TNotificationMode, ReactNode> =
  {
    email: <T _str="Email adress" />,
    sms: <T _str="Phone number" />,
  };
export const notificationModeContact2Map: Record<TNotificationMode, ReactNode> =
  {
    email: <T _str="email" />,
    sms: <T _str="phone" />,
  };
