// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { createContext, FC, ReactNode, useState } from 'react';
// eslint-disable-next-line import/no-cycle
import { routingMap as map } from '../routing/routing-map';

export const RouterContext = createContext({
  roles: [],
  routingMap: map,
  init: true,
});
export const RouterDispatchContext = createContext(undefined);

export type RouterProviderProps = {
  children: ReactNode;
};

export const RouterProvider: FC<RouterProviderProps> = ({ children }) => {
  const [routerDetails, setRouterDetails] = useState({
    roles: ['non-authenticated'],
    routingMap: map,
    init: false,
  });

  return (
    <RouterContext.Provider value={routerDetails}>
      <RouterDispatchContext.Provider value={setRouterDetails}>
        {children}
      </RouterDispatchContext.Provider>
    </RouterContext.Provider>
  );
};
