import React from 'react';

const stopPropagation = (e: $TSFixMe) => {
  e?.stopPropagation();
  e?.preventDefault();
};

type StopPropagationDivProps = {
  children: React.ReactNode;
};

export const StopPropagationDiv = ({ children }: StopPropagationDivProps) => {
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  return <div onClick={(e) => stopPropagation(e)}>{children}</div>;
};
