import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
import { WhDatePicker } from 'src/ui/DatePicker/WhDatePicker';

/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useMemo } from 'react';
import { Space, Form, Select } from 'src/antd';
import { DeleteOutlined } from '@ant-design/icons';
import { T } from '@transifex/react';
import { useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { WasteFractionTag } from 'src/ui';
import { VALIDATION_MESSAGE } from '../../../../../../../../utils/validation';
// eslint-disable-next-line import/no-cycle
import SelectableContainersList from '../../../../../../../../new-components/SelectableContainersList';
import OrderOverview from '../../../../../../../shared/OrderOverview';
import { PropertyContext } from '../../../../../../../../context/property-context';
// eslint-disable-next-line import/no-cycle
import { useGetPropertyContainers } from '../../../../../../../../api/property/property-containers';
import { ChangeFromTo, PrefferedDate } from '../../elements/Summary';
import { PreferredDateStyles } from '../../styles';
import { StopPropagationDiv } from '../../../../../../../shared/StopPropagationDiv';
import { createMarkUp } from '../../../../../../../../utils/createMarkup';
// eslint-disable-next-line import/no-cycle
import { useTicketAvailableDates } from '../useTicketAvailableDates';
import { DATE_FORMAT } from '../consts';
import CustomFieldsFormSection from '../CustomFieldsFormSection';

const Content = () => {
  const { ticketTypes, id: propertyId } = useContext(PropertyContext);
  const { ticketTypeId } = useParams<{ ticketTypeId: string }>();

  const ticketType: $TSFixMe = ticketTypes?.filter(
    (e: { id: string }) => e.id === ticketTypeId
  );

  const availableDates = useTicketAvailableDates({
    propertyId,
    ticketTypeId,
  });

  const {
    portalDateHidden,
    portalDateLabel,
    portalDateHelpText,
    portalDateRequired,
    portalDescription,
    portalNoteHidden,
    portalNoteLabel,
    portalNoteHelpText,
    portalNoteRequired,
    // eslint-disable-next-line no-unsafe-optional-chaining
  } = ticketType?.[0];

  const isMobile = useMediaQuery('(max-width: 996px)');

  return (
    <>
      {portalDescription && (
        <div
          style={{ marginBottom: '1rem' }}
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={createMarkUp(portalDescription)}
        />
      )}
      <Form.Item
        label={portalNoteLabel || <T _str="Note" />}
        name={['manageContainers', 'note']}
        tooltip={portalNoteHelpText || undefined}
        rules={[
          {
            required: portalNoteRequired,
            message: VALIDATION_MESSAGE.REQUIRED,
          },
        ]}
        hidden={portalNoteHidden}
      >
        <TextArea style={{ marginBottom: '1rem' }} />
      </Form.Item>
      <SelectableContainersList
        isChangeContainerType
        preName={['manageContainers', 'items']}
        renderItem={({ id, data }, checked) => {
          const {
            container: {
              waste_fraction: wasteFraction,
              container_type: {
                name: containerType,
                id: currentContainerTypeId,
              },
            },
            prices,
          } = data;
          return {
            title: (
              <>
                <WasteFractionTag
                  wasteFraction={wasteFraction}
                  iconOnly={isMobile}
                />
                {wasteFraction.name}
              </>
            ),
            addonCenter: <T _str="Current: {value}" value={containerType} />,
            addonEnd: (
              <StopPropagationDiv>
                <Form.Item
                  name={['manageContainers', 'items', id, 'containerType']}
                  dependencies={[['manageContainers', 'items', id, 'checked']]}
                  rules={[
                    {
                      required: checked,
                      message: VALIDATION_MESSAGE.REQUIRED,
                    },
                  ]}
                >
                  <Select
                    placeholder={<T _str="Container type" />}
                    style={{ width: '200px' }}
                    disabled={!checked}
                    options={prices.change_container_type
                      .map((node: $TSFixMe) => ({
                        label: node.container_type.name,
                        value: node.container_type.id,
                      }))
                      .filter(
                        ({ value }: $TSFixMe) =>
                          value !== currentContainerTypeId
                      )}
                  />
                </Form.Item>
              </StopPropagationDiv>
            ),
            addonContent: !portalDateHidden ? (
              <StopPropagationDiv>
                <PreferredDateStyles>
                  <Form.Item
                    label={portalDateLabel || <T _str="Preferred date" />}
                    name={['manageContainers', 'items', id, 'preferredDate']}
                    tooltip={portalDateHelpText || undefined}
                    rules={[
                      {
                        required: portalDateRequired && checked,
                        message: VALIDATION_MESSAGE.REQUIRED,
                      },
                    ]}
                    hidden={portalDateHidden}
                  >
                    <WhDatePicker
                      format={DATE_FORMAT}
                      inputReadOnly
                      disabled={!checked}
                      disabledDate={(date) =>
                        !(availableDates.get(id) || [])?.includes(
                          date.format('YYYY-MM-DD')
                        )
                      }
                    />
                  </Form.Item>
                </PreferredDateStyles>
              </StopPropagationDiv>
            ) : undefined,
          };
        }}
      />
      <CustomFieldsFormSection />
    </>
  );
};

const BreadCrumbTitle = <T _str="Select containers" />;

const Summary = () => {
  const { id: propertyId, ticketTypesMap } = useContext(PropertyContext);
  const { data } = useGetPropertyContainers(propertyId, {
    includePrices: true,
  });
  const { ticketTypeId } = useParams<{
    ticketTypeId: string;
  }>();
  const ticketType = useMemo(
    () => ticketTypesMap?.[ticketTypeId],
    [ticketTypeId, ticketTypesMap]
  );

  const returnContainerById = (id: string) => {
    return data?.data?.filter(
      (container: $TSFixMe) => container?.container?.id === id
    )?.[0];
  };

  const values = Form.useWatch(['manageContainers']);

  const items = Object.keys(values?.items || {})
    .map((containerId) => ({
      ...values?.items?.[containerId],
      id: containerId,
    }))
    .filter((n) => n.checked)
    .map(({ id, pickupSetting, containerType, preferredDate }) => ({
      containerId: id,
      pickupSettingId: pickupSetting,
      containerTypeId: containerType,
      preferredDate,
    }));

  const { portalDateLabel, portalDateHidden } = ticketType;

  // #TODO Get container waste fraction - get previous container type
  const summaryItems = items?.map((item: $TSFixMe) => {
    const container = returnContainerById(item?.containerId);

    const newContainerType = container?.prices?.change_container_type?.filter(
      (price: { container_type: { id: $TSFixMe } }) =>
        price.container_type.id === item.containerTypeId
    )?.[0];

    const oldContainerType = container?.prices?.change_container_type?.filter(
      (price: { container_type: { id: $TSFixMe } }) =>
        price.container_type.id === container?.container?.container_type?.id
    )?.[0];

    return {
      icon: <DeleteOutlined />,
      header: container?.container?.waste_fraction?.name,
      headerSuffix: container?.container?.container_id,
      description: (
        <Space direction="vertical">
          <ChangeFromTo
            from={container?.container?.container_type?.name}
            to={newContainerType?.container_type?.name}
          />
          <PrefferedDate
            value={item.preferredDate}
            dateText={portalDateLabel}
            dateHidden={portalDateHidden}
          />
        </Space>
      ),
      price:
        newContainerType?.price?.container_price > 0
          ? // eslint-disable-next-line no-unsafe-optional-chaining
            newContainerType?.price?.container_price / 100
          : 0,
      previousPrice:
        oldContainerType?.price?.container_price > 0
          ? // eslint-disable-next-line no-unsafe-optional-chaining
            oldContainerType?.price?.container_price / 100
          : 0,
      recurringPrice: true,
    };
  });

  return <OrderOverview header="" items={summaryItems} />;
};

export const FormChangeContainerType = {
  Form: { BreadCrumbTitle, Content },
  Summary,
};
