// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCompany } from 'src/app/Company.store';
import React, { createContext, FC, ReactNode } from 'react';

export const CompanyContext = createContext<$TSFixMe>();

export type CompanyContextProps = {
  children: ReactNode;
};

export const CompanyProvider: FC<CompanyContextProps> = ({ children }) => {
  const { data } = useCompany();

  return (
    <CompanyContext.Provider value={data}>{children}</CompanyContext.Provider>
  );
};
