import DOMPurify from 'dompurify';
import linkifyHtml from 'linkify-html';

export const createMarkUp = (htmlString: string) => {
  const linkedString = linkifyHtml(DOMPurify.sanitize(htmlString), {
    defaultProtocol: 'https',
    target: '_blank', // add target="_blank" to make links open in new tab
  });

  return { __html: linkedString };
};
