import { useQuery, MutateFunction } from 'react-query';
import axios from 'axios';
import { useGetToken } from '../config';

type TVariables = {
  token: string;
  id: string;
};

export const callGetOneProperty: MutateFunction<
  unknown,
  unknown,
  TVariables
> = ({ id, token }) =>
  axios.get(`property/${id}`, {
    headers: {
      'X-API-Key': token,
    },
  });

const K_GET_ONE_PROPERTY = 'K_GET_ONE_PROPERTY';

export const useGetOneProperty = (propertyId: string) => {
  const token = useGetToken();
  return useQuery<$TSFixMe>([K_GET_ONE_PROPERTY, propertyId], () =>
    callGetOneProperty({ token, id: propertyId })
  );
};
