import Color from 'color';

export const gray100 = '#FFFFFF';
export const gray200 = '#FAFAFA';
export const gray300 = '#F5F5F5';
export const gray400 = '#F0F0F0';
export const gray500 = '#D9D9D9';
export const gray600 = '#BFBFBF';
export const gray700 = '#8C8C8C';
export const gray800 = '#595959';
export const gray900 = '#262626';
export const gray1000 = '#000000';

export const red100 = '#FFF1F0';
export const red200 = '#FFCCC7';
export const red300 = '#FFA39E';
export const red400 = '#FF7875';
export const red500 = '#FF4D4F';
export const red600 = '#F5222D';
export const red700 = '#CF1322';
export const red800 = '#A8071A';
export const red900 = '#820014';
export const red1000 = '#5C0011';

export const orange100 = '#FFF7E6';
export const orange200 = '#FFE7BA';
export const orange300 = '#FFD591';
export const orange400 = '#FFC069';
export const orange500 = '#FFA940';
export const orange600 = '#FA8C16';
export const orange700 = '#D46B08';
export const orange800 = '#AD4E00';
export const orange900 = '#873800';
export const orange1000 = '#612500';

export const yellow100 = '#FEFFE6';
export const yellow200 = '#FFFFB8';
export const yellow300 = '#FFFB8F';
export const yellow400 = '#FFF566';
export const yellow500 = '#FFEC3D';
export const yellow600 = '#FADB14';
export const yellow700 = '#D4B106';
export const yellow800 = '#AD8B00';
export const yellow900 = '#876800';
export const yellow1000 = '#614700';

export const green100 = '#F6FFED';
export const green200 = '#D9F7BE';
export const green300 = '#B7EB8F';
export const green400 = '#95DE64';
export const green500 = '#73D13D';
export const green600 = '#52C41A';
export const green700 = '#389E0D';
export const green800 = '#237804';
export const green900 = '#135200';
export const green1000 = '#092B00';

export const geekBlue100 = '#F0F5FF';
export const geekBlue200 = '#D6E4FF';
export const geekBlue300 = '#ADC6FF';
export const geekBlue400 = '#85A5FF';
export const geekBlue500 = '#597EF7';
export const geekBlue600 = '#2F54EB';
export const geekBlue700 = '#1D39C4';
export const geekBlue800 = '#10239E';
export const geekBlue900 = '#061178';
export const geekBlue1000 = '#030852';

export const magenta100 = '#FFF0F6';
export const magenta200 = '#FFD6E7';
export const magenta300 = '#FFADD2';
export const magenta400 = '#FF85C0';
export const magenta500 = '#F759AB';
export const magenta600 = '#EB2F96';
export const magenta700 = '#C41D7F';
export const magenta800 = '#9E1068';
export const magenta900 = '#780650';
export const magenta1000 = '#520339';

export const blue100 = '#E6F7FF';
export const blue200 = '#BAE7FF';
export const blue300 = '#91D5FF';
export const blue400 = '#69C0FF';
export const blue500 = '#40A9FF';
export const blue600 = '#1890FF';
export const blue700 = '#096DD9';
export const blue800 = '#0050B3';
export const blue900 = '#003A8C';
export const blue1000 = '#002766';

export const volcano100 = '#FFF2E8';
export const volcano200 = '#FFD8BF';
export const volcano300 = '#FFBB96';
export const volcano400 = '#FF9C6E';
export const volcano500 = '#FF7A45';
export const volcano600 = '#FA541C';
export const volcano700 = '#D4380D';
export const volcano800 = '#AD2102';
export const volcano900 = '#871400';
export const volcano1000 = '#610B00';

export const gold100 = '#FFFBE6';
export const gold200 = '#FFF1B8';
export const gold300 = '#FFE58F';
export const gold400 = '#FFD666';
export const gold500 = '#FFC53D';
export const gold600 = '#FAAD14';
export const gold700 = '#D48806';
export const gold800 = '#AD6800';
export const gold900 = '#874D00';
export const gold1000 = '#613400';

export const lime100 = '#FCFFE6';
export const lime200 = '#F4FFBF';
export const lime300 = '#EAFF8F';
export const lime400 = '#D3F261';
export const lime500 = '#BAE637';
export const lime600 = '#A0D911';
export const lime700 = '#7CB305';
export const lime800 = '#5B8C00';
export const lime900 = '#3F6600';
export const lime1000 = '#254000';

export const cyan100 = '#E6FFFB';
export const cyan200 = '#B5F5EC';
export const cyan300 = '#87E8DE';
export const cyan400 = '#5CDBD3';
export const cyan500 = '#36CFC9';
export const cyan600 = '#13C2C2';
export const cyan700 = '#08979C';
export const cyan800 = '#006D75';
export const cyan900 = '#00474F';
export const cyan1000 = '#002329';

export const purple100 = '#F9F0FF';
export const purple200 = '#EFDBFF';
export const purple300 = '#D3ADF7';
export const purple400 = '#B37FEB';
export const purple500 = '#9254DE';
export const purple600 = '#722ED1';
export const purple700 = '#531DAB';
export const purple800 = '#391085';
export const purple900 = '#22075E';
export const purple1000 = '#120338';

// Customizable colors
export const dark = '#111D2C';
export const light = blue200;

// System colors
export const white = gray100;
export const black = gray1000;
export const warning = volcano700;
export const alert = gold600;
export const success = green600;
export const link = blue600;

type ColorShades<N extends string> = {
  [key in `${N}${100 | 80 | 60 | 40 | 20 | 10 | 5}`]: string;
};

export function getColorShades<N extends string>(
  name: N,
  color: string
): ColorShades<N> {
  return {
    [`${name}100`]: color,
    [`${name}80`]: Color(color).lighten(0.2).hex(),
    [`${name}60`]: Color(color).lighten(0.4).hex(),
    [`${name}40`]: Color(color).lighten(0.6).hex(),
    [`${name}20`]: Color(color).lighten(0.8).hex(),
    [`${name}10`]: Color(color).lighten(0.9).hex(),
    [`${name}5`]: Color(color).lighten(0.95).hex(),
  } as ColorShades<N>;
}
