import {
  CustomFieldTotalProps,
  TTicketCustomField,
  TTicketCustomFieldConnection,
} from './components/CustomFieldsFormSection/types';
import { customFieldsNamesMap } from './components/CustomFieldsFormSection/mappers';

function prefillFieldValue(field: TTicketCustomField) {
  switch (field.input_type) {
    case 'NUMBER':
      return field.prefilled_value;
    case 'SELECT':
      return field.options?.find((opt) => opt.pre_selected)?.id;
    case 'MULTI_SELECT':
      return (field.options || [])
        .filter(({ pre_selected }) => pre_selected)
        .map(({ id }) => id);
    default:
      return undefined;
  }
}

export function prefillWithValues(
  customFieldConnections?: TTicketCustomFieldConnection[]
): CustomFieldTotalProps[] {
  return (customFieldConnections || []).map((connection) => ({
    connection_id: connection.id,
    ...connection.ticket_custom_field,
    [customFieldsNamesMap[connection.ticket_custom_field.input_type]]:
      prefillFieldValue(connection.ticket_custom_field),
  }));
}

export function prepareCustomValues(fields?: CustomFieldTotalProps[]) {
  return (fields || []).map((field) => ({
    connection_id: field.connection_id,
    [customFieldsNamesMap[field.input_type]]:
      field[customFieldsNamesMap[field.input_type]],
  }));
}
