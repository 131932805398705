import React from 'react';
import { createAntdIcon } from '../createIcon';

export const WhIconAntdBookFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.8367 3.61596C10.9779 3.61646 11.1191 3.61597 11.2604 3.61537C11.9658 3.61486 12.6542 3.66464 13.3486 3.79369C13.3821 3.79953 13.4157 3.80537 13.4502 3.81139C13.6881 3.8543 13.6881 3.8543 13.7408 3.90699C13.7453 4.00171 13.7465 4.09659 13.7464 4.19141C13.7465 4.22144 13.7465 4.25148 13.7465 4.28242C13.7466 4.38377 13.7464 4.48513 13.7461 4.58648C13.7461 4.65885 13.7461 4.73121 13.7461 4.80358C13.7461 5.00065 13.7459 5.19772 13.7455 5.39479C13.7453 5.60052 13.7452 5.80624 13.7452 6.01196C13.745 6.40181 13.7446 6.79165 13.7442 7.18149C13.7437 7.6252 13.7434 8.0689 13.7432 8.51261C13.7427 9.4256 13.7419 10.3386 13.7408 11.2516C13.6663 11.2442 13.5918 11.2368 13.5172 11.2294C13.4757 11.2253 13.4342 11.2212 13.3915 11.217C13.2666 11.2041 13.1423 11.1888 13.018 11.1717C11.8879 11.0185 10.7876 10.9698 9.65018 11.0802C9.5932 11.0856 9.53623 11.0911 9.47752 11.0967C9.0183 11.1446 8.57384 11.2218 8.11619 11.2981C8.11619 8.85901 8.11619 6.41996 8.11619 3.90699C9.05518 3.70931 9.89051 3.612 10.8367 3.61596Z" />
      <path d="M7.93025 3.90699C7.93025 6.34605 7.93025 8.7851 7.93025 11.2981C7.67714 11.252 7.67714 11.252 7.41892 11.2051C7.23627 11.1776 7.05313 11.155 6.86981 11.1325C6.82436 11.1266 6.77891 11.1208 6.73209 11.1148C5.34223 10.9416 4.01226 10.9972 2.63765 11.2302C2.49154 11.2516 2.49154 11.2516 2.3056 11.2516C2.3056 8.82787 2.3056 6.40415 2.3056 3.90699C2.89722 3.70979 3.51963 3.66399 4.13884 3.61937C4.2197 3.61353 4.2197 3.61353 4.30219 3.60757C5.45923 3.5309 6.82079 3.53717 7.93025 3.90699Z" />
      <path d="M1.46887 4.13942C1.68363 4.13942 1.89839 4.13942 2.11966 4.13942C2.11966 6.57847 2.11966 9.01752 2.11966 11.5305C2.2884 11.4845 2.45714 11.4384 2.63099 11.391C2.79311 11.3628 2.95329 11.3371 3.11617 11.3155C3.15575 11.3099 3.19533 11.3042 3.23611 11.2984C3.82773 11.2168 4.41406 11.194 5.01043 11.1935C5.04925 11.1934 5.08806 11.1933 5.12806 11.1933C5.87045 11.1935 6.59269 11.2508 7.3234 11.3865C7.35901 11.3931 7.39461 11.3997 7.4313 11.4065C7.52321 11.4242 7.61481 11.4435 7.70636 11.4629C8.15913 11.5167 8.62576 11.3952 9.0713 11.3195C9.66672 11.221 10.2498 11.1975 10.852 11.199C10.9717 11.1993 11.0914 11.199 11.2111 11.1987C11.8369 11.1984 12.4434 11.235 13.061 11.3416C13.1088 11.3495 13.1565 11.3574 13.2057 11.3655C13.4544 11.4076 13.6823 11.4606 13.9268 11.5305C13.9268 9.09143 13.9268 6.65238 13.9268 4.13942C14.1262 4.13942 14.3256 4.13942 14.5311 4.13942C14.5311 6.71653 14.5311 9.29364 14.5311 11.9488C11.6665 11.9518 11.6665 11.9518 10.7623 11.9501C10.4946 11.9512 10.4946 11.9512 10.2269 11.9527C10.0764 11.9533 9.92604 11.9527 9.77559 11.9517C9.62631 11.9512 9.47704 11.9528 9.32776 11.9541C9.24827 11.953 9.24827 11.953 9.16717 11.9519C8.9025 11.9513 8.9025 11.9513 8.67462 12.0682C8.59914 12.154 8.59914 12.154 8.54087 12.2446C8.46387 12.3556 8.38474 12.383 8.25564 12.4137C8.01003 12.4444 7.80707 12.4456 7.58452 12.3323C7.36168 12.1367 7.36168 12.1367 7.27946 12.0418C7.27946 12.0111 7.27946 11.9805 7.27946 11.9488C5.36197 11.9488 3.44447 11.9488 1.46887 11.9488C1.46887 9.37174 1.46887 6.79462 1.46887 4.13942Z" />
    </svg>
  );
});
