import React from 'react';
import TicketsList from 'src/components/shared/views/ticket/TicketList';
import { useModalWithButton } from 'src/ui/Modal/ModalWithButton';

const useViewTicketsModal = () => {
  return useModalWithButton<Record<string, $TSFixMe>>({
    // eslint-disable-next-line react/no-unstable-nested-components
    children: ({ state, closeModal }: $TSFixMe) => {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return (
        <TicketsList
          collectionStatus={state?.collectionStatus}
          onClose={closeModal}
          tickets={state?.tickets || []}
        />
      );
    },
    modelProps: {
      title: '',
      footer: null,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      width: '80%',
      style: {
        maxWidth: 920,
      },
      centered: true,
      closable: false,
      bodyStyle: {
        padding: 24,
        paddingBottom: 8,
        borderRadius: 2,
      },
    },
  });
};

export default useViewTicketsModal;
