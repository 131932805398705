export const LS_API_URL = 'apiUrl';

export const overrideApiUrl = (apiUrl: string) => {
  localStorage.setItem(LS_API_URL, apiUrl);
  window.location.reload();
};

export const getOverrideApiUrl = () => {
  return localStorage.getItem(LS_API_URL);
};
