/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import { CaretUpFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { T } from '@transifex/react';
import { Col, Collapse, Row } from 'src/antd';
import { Typography } from 'src/ui/Typography';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
import TicketsList from './views/ticket/TicketList';

const StyledPanel = styled(Collapse.Panel)`
  background-color: #fff1f0;
`;

export type OpenTicketsOnPropertyAlertProps = {
  linkTo?: string;
  tickets: $TSFixMe[];
  style?: CSSProperties;
};

export const OpenTicketsOnPropertyAlert = ({
  linkTo,
  tickets,
  style,
}: OpenTicketsOnPropertyAlertProps) => {
  const isMobile = useMediaQuery('(max-width: 996px)');

  return (
    <Collapse
      // eslint-disable-next-line react/jsx-no-useless-fragment, react/no-unstable-nested-components
      expandIcon={({ isActive }) => (
        <CaretUpFilled
          rotate={isActive ? 180 : 0}
          style={{ fontSize: 16, color: '#820014' }}
        />
      )}
      style={style}
      bordered={false}
      expandIconPosition="end"
    >
      <StyledPanel
        header={
          <Row wrap={false} gutter={isMobile ? 8 : 16} align="middle">
            <Col>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ExclamationCircleFilled
                  style={{ fontSize: isMobile ? 16 : 20, color: '#F5222D' }}
                />
              </div>
            </Col>
            <Col>
              <Typography
                style={{ color: '#820014', userSelect: 'none' }}
                variant={isMobile ? 'subHeader-16' : 'subHeader-20'}
                weight="semi-bold"
              >
                <T
                  _str="{num} unresolved tickets on this property"
                  num={tickets.length}
                />
              </Typography>
            </Col>
          </Row>
        }
        key="tickets"
      >
        <div style={{ backgroundColor: 'white', borderRadius: 5 }}>
          <TicketsList hideHeader tickets={tickets} />
        </div>
      </StyledPanel>
    </Collapse>
  );
};
