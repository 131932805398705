import React from 'react';
import { Typography } from 'src/ui/Typography';
import { Col, Row } from 'src/antd';
import styled from 'styled-components';
import { WasteFractionTag } from 'src/ui';
import { apiHelperCollections } from 'src/api/helpers/collection';
import { collectionStatusIconMap } from 'src/api/consts/CollectionStatus';

const Styled = styled.div<{ $pointer?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  border-radius: 4px;
  column-gap: 4px;

  & .wf-title {
    display: flex;
    flex-wrap: nowrap;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }

  & .tickets-mark {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    background-color: #ff4d4f;
    gap: 2px;
    border-radius: 10px;
    padding: 0 8px;
    color: white;
    align-items: center;
    font-weight: 600;
  }

  ${({ $pointer }) => ($pointer ? `cursor: pointer;` : `cursor: default;`)}
`;

type CalendarCellProps = {
  item: $TSFixMe;
  onClick?: () => void;
};

const CalendarCell = ({ item, onClick }: CalendarCellProps) => {
  const wasteFraction = item?.container?.waste_fraction;

  const status = apiHelperCollections.getStatus(item);

  const tickets = item?.pickup_order?.created_tickets || [];

  return (
    <Styled onClick={onClick} $pointer={tickets?.length}>
      <Row gutter={8} wrap={false}>
        <Col>
          <WasteFractionTag
            wasteFraction={wasteFraction}
            iconOnly
            style={{ margin: 0 }}
          />
        </Col>
        <Col flex="auto">
          <Typography
            delete={status === 'MISSED'}
            type={status === 'MISSED' ? 'secondary' : undefined}
            weight="semi-bold"
            ellipsis={{
              tooltip: wasteFraction?.name,
            }}
          >
            {wasteFraction?.name}
          </Typography>
        </Col>
      </Row>
      {status && collectionStatusIconMap[status]}
    </Styled>
  );
};

export default CalendarCell;
