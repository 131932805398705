import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Space } from 'antd';
import { T } from '@transifex/react';
import { createPrefixer } from '../../../utils';
import { LanguagePicker } from '../../I18n';
import ApiUrl from './ApiUrl';

const p = createPrefixer('wh-UI-header-');

const classes = {
  wrapper: p`wrapper`,
  icon: p`icon`,
  title: p`title`,
};

export type HeaderProps = {
  title: string | ReactNode;
  icon?: ReactNode;
};

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #0f3f6b;
  align-items: center;

  .${classes.title} {
    font-weight: 600;
    line-height: 1.375rem;
    font-size: 0.875rem;
  }

  .${classes.icon} {
    font-size: 1rem;
    margin-right: 0.5rem;
  }

  .icon-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @media screen and (min-width: 992px) {
    align-items: center;
    justify-content: space-between;

    .${classes.title} {
      line-height: 1.5rem;
      font-size: 1rem;
    }

    .${classes.icon} {
      font-size: 1.25rem;
      margin-right: 1rem;
    }
  }
`;

export const Header: FC<HeaderProps> = ({ title, icon }) => {
  return (
    <HeaderDiv className={classes.wrapper}>
      <div className="icon-title">
        {icon && <div className={classes.icon}>{icon}</div>}
        <div className={classes.title}>
          {typeof title === 'string' ? (
            <T _str="{title}" title={title} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>{title}</>
          )}
        </div>
      </div>
      <Space>
        <ApiUrl />
        <LanguagePicker />
      </Space>
    </HeaderDiv>
  );
};
